import * as React from "react";
import "./ChangeLog.scss";

interface IChangeLogProps {}

export class ChangeLog extends React.Component<IChangeLogProps, {}> {
  constructor(props: IChangeLogProps) {
    super(props);
  }

  public render() {
    return (
      <div className="commonComponent ">
        <div className="mainHeader">Változásnapló</div>
        <table style={{ width: "100%", textAlign: "left" }}>
          <tbody>
          <tr>
              <td>
                <div>
                  <b>2022.08.22.</b>
                </div>
                <ol>
                  <li>
                    <b>Mobiltámogatás</b>
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <b>2022.02.09.</b>
                </div>
                <ol>
                  <li>
                    <b>Menü</b>
                  </li>

                  <ul>
                    <li>Oldal betöltésekor a "404" helyett Foglaló címet kap az oldal</li>
                  </ul>
                </ol>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <b>2022.01.25.</b>
                </div>
                <ol>
                  <li>
                    <b>Parkoló</b>
                  </li>

                  <ul>
                    <li>
                      Amennyiben egy parkolóhelynek van tulajdonosa és a parkolóhelyen változás történik, de a tulajdonos ugyanaz marad, akkor ne
                      vesszenek el a tulajdonos által beállított felszabadított napok.
                    </li>
                  </ul>
                </ol>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <b>2022.01.24.</b>
                </div>
                <ol>
                  <li>
                    <b>Appsettings</b>
                  </li>

                  <ul>
                    <li>E-mail titkos másolat címe az appsettingsbe került (BCC)</li>
                  </ul>

                  <li>
                    <b>E-mailek</b>
                  </li>

                  <ul>
                    <li>E-mail szövegének megváltoztatása a kapott adatok szerint.</li>
                  </ul>

                  <li>
                    <b>Parkoló admin felület</b>
                  </li>

                  <ul>
                    <li>Parkolótörzs napló módosítása. A parkolók módosításánál a régi parkoló adatok is exportálásra kerülnek nem csak az újak.</li>
                  </ul>
                </ol>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <b>2022.01.19.</b>
                </div>
                <ol>
                  <li>
                    <b>Appsettings</b>
                  </li>

                  <ul>
                    <li>A parkolófoglaló konfigurálható (appsettings), hogy a parkolókat hány napra előre lehet foglalni</li>
                  </ul>

                  <li>
                    <b>Admin szerepkör kezelése</b>
                  </li>

                  <ul>
                    <li>Új szerepkör: szuperadmin (jogosult parkoló esetén egy napra több foglalást is leadni)</li>
                  </ul>

                  <li>
                    <b>Parkoló admin felület</b>
                  </li>

                  <ul>
                    <li>Lehetőség van a parkolóhoz rendszámot is rögzíteni (max 12 karakter)</li>
                    <li>Excel export készítés az összes parkoló nevéről, rendszámáról, tulajdonosáról</li>
                    <li>Parkolótörzs módosításainak naplózása: név, rendszám, tulajdonos - Új parkoló felvétel / Módosítás meglévő parkolón</li>
                    <li>Parkolók főoldali és admin oldali rendezése betűrendbe</li>
                    <li>Excel: új foglalás, foglalás módosításának, foglalás törlésének naplózása</li>
                    <li>Parkolóhely tulajdonosa cserélhető. Ha van aktív foglalás a parkolóhelyen, akkor az megmarad.</li>
                  </ul>

                  <li>
                    <b>Parkoló felhasználói felület</b>
                  </li>

                  <ul>
                    <li>Tulajdonos neve, rendszám kiírása a főoldalra</li>
                    <li>E-mail küldése foglalásról</li>
                    <li>
                      Átlagfelhasználó és sima admin egy napra legfeljebb egy parkolóhelyet foglalhat (szuperadminokra ez a korlátozás ne vonatkozzon)
                    </li>
                  </ul>
                </ol>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

import { observer } from "mobx-react";
import * as React from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";

export interface INumberInputProps {
  value?: number | string;
  onValueChange?: (values: NumberFormatValues) => void;
  allowNegative?: boolean;
  label?: string;
  disabled?: boolean;
  maxLength?: number;
  allowLeadingZeros?: boolean;
}

@observer
export default class NumberInput extends React.Component<INumberInputProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    return (
      <div>
        <div style={{ border: "1px solid #a6a6a6", backgroundColor: this.props.disabled ? "#f4f4f4" : "#ffffff", height: "30px" }}>
          <NumberFormat
            label=""
            displayType="input"
            thousandSeparator={" "}
            decimalSeparator={","}
            value={this.props.value ? this.props.value : null}
            onValueChange={(text) => this.onChangeInner(text)}
            allowNegative={this.props.allowNegative}
            inputMode="numeric"
            disabled={this.props.disabled}
            maxLength={this.props.maxLength}
            allowLeadingZeros={this.props.allowLeadingZeros}
            style={{ border: "none", height: "28px", width: "98%" }}
          />
        </div>
      </div>
    );
  }

  private onChangeInner(text) {
    if (typeof text != "string") {
      text = +text.value;
    }

    return this.props.onValueChange(text);
  }
}

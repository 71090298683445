import { observable } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import React = require("react");
import { RouteComponentProps } from "react-router-dom";
import { RootStore } from "../../../core/stores/RootStore";
import { ChangePassword } from "./components/ChangePassword";
import { Users } from "./components/Users";
import { UsersStore } from "./core/stores/UsersStore";

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  RootStore?: RootStore;
}

interface Stores {
  UsersStore: UsersStore;
}

@inject("RootStore")
@observer
export class UsersPage extends React.Component<IProps> {
  private stores: Stores;

  constructor(props: IProps) {
    super(props);
    console.log("this.props.match.params.id", this.props.match.params);

    this.stores = {
      UsersStore: new UsersStore(props.RootStore),
    };
  }

  async componentDidMount() {
    this.stores.UsersStore.Init();
  }
  public getQueryStringParam(field: string, url?: string): string {
    const href = url ? url : window.location.href;
    const reg = new RegExp('[?&#]' + field + '=([^&#]*)', 'i');
    const qs = reg.exec(href);
    return qs ? qs[1] : null;
  }
  public render() {
    let changePAssword = this.getQueryStringParam('changePassword');
    let debug = 1;
    return (
      <Provider {...this.stores}>
        {
          changePAssword == 'true' ?
            <ChangePassword /> :
            <Users />
        }
      </Provider>
    );
  }
}

import { observable, action, flow, toJS, computed } from "mobx";
import "isomorphic-fetch";
import "url-search-params-polyfill";
import "url-polyfill";

import { DepartmentService } from "../services/DepartmentService";
import { IDepartment } from "../../../../../core/models/IDepartment";
import { UrlHelper } from "../../../../../core/Utils/UrlHelper";
import { RootStore } from "../../../../../core/stores/RootStore";
import { IDepartmentUser } from "../../../../../core/models/IDepartmentUser";

export class DepartmentStore {
  public DepartmentService: DepartmentService = null;

  @observable isLoading: boolean = false;
  @observable Department: IDepartment = {
    Id: null,
    Name: null,
    Image: null,
    Tables: [],
    Leaders: [],
    Users: [],
    TempImage: null,
    ReservableByUsers: null,
    ImageHeight: null,
    ImageWidth: null,
    IsCurrentUserDepartmentLeader: null,
    IsCurrentUserDepartmentUser: null,
    Selected: null,
  };

  @observable mode: "admin" | "leader" = "leader";

  @observable Departments: IDepartment[] = [];
  @observable SelectedDepartmentId: number = null;
  @observable isAdminMode: boolean = false;
  @observable errorMessage: string = null;

  //Messages
  @observable openDepartmentDialog: boolean = false;
  @observable openLeaderDialog: boolean = false;
  @observable showImage: boolean = true;

  Rootstore = new RootStore();
  constructor(rootStore: RootStore) {
    this.DepartmentService = new DepartmentService();
    this.Rootstore = rootStore;
  }

  Init = flow(function* (this: DepartmentStore) {
    yield this.GetDepartments();

    const isAdmin = UrlHelper.getQueryStringParam("isAdmin");
    if (isAdmin) {
      this.isAdminMode = true;
    }
  });

  @action SelectFile() {
    const item = document.getElementById("docPanelInput2") as HTMLInputElement;
    this.showImage = false;
    let files = item.files;

    var reader = new FileReader();
    reader.onloadend = () => {
      this.Department.TempImage = reader.result;
      this.showImage = true;
    };
    reader.readAsDataURL(files[0]);
  }

  @action LoadDataForPeoplePicker(data: any[]) {
    if (data) {
      const newData = data.map((user) => {
        return {
          ...user,
          text: user.ADUserName,
        };
      });

      return newData;
    }
  }

  public SaveDepartment = flow(function* (this: DepartmentStore) {
    this.isLoading = true;
    console.log("SaveDepartment", toJS(this.Department));

    const id = yield this.DepartmentService.SaveDepartment(this.Department);

    if (this.isAdminMode && this.openDepartmentDialog) {
      const item = document.getElementById("docPanelInput2") as HTMLInputElement;

      let files = item.files;

      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let file: any = files[i];
          yield this.DepartmentService.UploadAttachment(id, file);
        }
      }
    }
    if (id) {
      this.openLeaderDialog = false;
      this.openDepartmentDialog = false;
    }

    yield this.GetDepartments();

    this.isLoading = false;
  });

  public GetDepartments = flow(function* (this: DepartmentStore) {
    this.isLoading = true;

    const request = yield this.DepartmentService.GetDepartments();

    if (request) {
      this.Departments = request;
      console.log("GetDepartments", toJS(this.Departments));
    }

    this.isLoading = false;
  });

  public GetDepartmentById = flow(function* (this: DepartmentStore, selectedValue, isTablesNecessary: boolean, isUsersNecessary: boolean) {
    this.isLoading = true;

    const request = yield this.DepartmentService.GetDepartmentById(selectedValue, new Date(), new Date(), isTablesNecessary, isUsersNecessary, false);

    if (request) {
      this.Department = request;
      console.log("GetDepartmentById", toJS(this.Department));
    }

    this.isLoading = false;
  });

  @computed get GetDepartmentsDropDownOptions() {
    return this.Departments.map((u) => {
      return { key: u.Id, text: u.Name };
    });
  }

  public OpenDepartmentDialog = flow(function* (this: DepartmentStore, departmentId: number) {
    // this.openDepartmentDialog = !this.openDepartmentDialog;

    if (departmentId) {
      this.openDepartmentDialog = true;
      yield this.GetDepartmentById(departmentId, false, false);
    } else {
      this.openDepartmentDialog = false;
      this.ResetDepartment();
    }
  });

  public OpenLeaderDialog = flow(function* (this: DepartmentStore, departmentId: number) {
    if (departmentId) {
      this.openLeaderDialog = true;

      yield this.GetDepartmentById(departmentId, false, true);
    } else {
      this.openLeaderDialog = false;
      this.ResetDepartment();
    }
  });

  @action AddNewDepartmentUser() {
    let newUser: IDepartmentUser;
    this.Department.Users = [...this.Department.Users, newUser];
  }

  @action ResetDepartment() {
    this.Department = {
      Id: null,
      Name: null,
      Image: null,
      Tables: [],
      Leaders: [],
      Users: [],
      TempImage: null,
      ReservableByUsers: null,
      ImageHeight: null,
      ImageWidth: null,
      IsCurrentUserDepartmentLeader: null,
      IsCurrentUserDepartmentUser: null,
      Selected: null,
    };
  }

  @action SetReservedByUsers(checked: boolean) {
    this.Department.ReservableByUsers = checked;
    console.log("this.Department.ReservableByUsers", this.Department.ReservableByUsers);
  }

  @action OnChangeFiled(key: string, newValue: any) {
    this.Department = {
      ...this.Department,
      [key]: newValue,
    };
  }

  @action OnChnageUserField(key: string, newValue: any, index: number) {
    this.Department.Users[index][key] = +newValue;
    console.log(Number.isInteger(newValue));
  }

  @action OnChangePeoplePicker(key: string, items, index?: number) {
    this.errorMessage = "";
    if (key === "Users") {
      if (this.Department.Users.find((u) => u && items[0] && u.ADUserId === items[0].ADUserId)) {
        this.errorMessage = "Ezt a felhasználót már megadtad további kitöltőnek";
        return;
      } else if (this.Department.Leaders.find((u) => u && items[0] && u.ADUserId === items[0].ADUserId)) {
        this.errorMessage = "Ezt a felhasználót már megadtad vezetőnek";
        return;
      } else {
        this.Department.Users[index] = items[0];
      }
    } else {
      this.Department = {
        ...this.Department,
        [key]: items,
      };
    }

    console.log(" this.Department[key]", toJS(this.Department[key]));
  }
}

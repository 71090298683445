import * as React from "react";
import "./ParkingReservation.scss";
import { observer, inject } from "mobx-react";
import "./ParkingReservation.scss";
import {
  Checkbox,
  DatePicker,
  DayOfWeek,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  FontWeights,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import Utils from "../../../core/Utils/Utils";
import { ReservationTypes } from "../core/enums/ReservationTypes";
import { IParkingReservation } from "../core/models/IParkingReservation";
import { ParkingReservationStore } from "../core/stores/ParkingReservationStore";
import { toJS } from "mobx";
import LoaderOverlay from "../../../components/controls/LoaderOverlay/LoaderOverlay";
import { style } from "d3";
import styles from "../../../components/controls/LoaderOverlay/LoaderOverlay.module.scss";

interface IParkingReservationProps {
  ParkingReservationStore?: ParkingReservationStore;
}

@inject("ParkingReservationStore")
@observer
export class ParkingReservation extends React.Component<IParkingReservationProps, {}> {
  private iconNext: string = String(require("../assets/images/next.svg"));
  private iconPrev: string = String(require("../assets/images/back.svg"));

  constructor(props: IParkingReservationProps) {
    super(props);
  }

  public render() {
    if (this.props.ParkingReservationStore.isLoadingOfGetMonth) {
      return <Spinner size={SpinnerSize.large} label="Hónap betöltése" />;
    }
    const style2 = { height: "70vh", overflow: "auto" }
    return (
      <div className="commonComponent sl-shadow">
        <div className="mainHeader">Parkolóhely foglalása</div>
        {this.props.ParkingReservationStore.isLoading ? <LoaderOverlay /> : null}
        <div className="darkerBcground">
          {/* Foglalás dialógus */}
          {this.renderReservationDialog()}
          {/* Felhasználó */}
          {this.renderHeader()}
          {/* Hónap választó */}
          <div className="CalendarDivMobile" style={{ width: "650px", marginLeft: "auto", marginRight: "auto" }}>{this.renderCalendar()}</div>
          <div style={this.isMobile() ? null : style2}>
            <div className="CalendarDivMobile" style={{ width: "650px", marginLeft: "auto", marginRight: "auto" }}>
              {/* Dátumok kirajzolása */}
              {/* {this.renderMonthWithDates()} */}
              {/* Összer parkolóhely kirajzolása */}
              {this.props.ParkingReservationStore.ParkingReservations && this.renderReservationCalendar()}
            </div>
          </div>

          {/* Jelmagyarázat */}
          <div>{this.renderColorExplanation()}</div>
        </div>
      </div>
    );
  }

  public GetDate(date: Date) {
    return date.toLocaleString("hu-HU", { weekday: "short" });
  }

  public GetUserSign(ownerGuid: string) {
    if (ownerGuid && this.props.ParkingReservationStore.RootStore.CurrentUser.Id === ownerGuid) {
      return "underline";
    } else {
      return "none";
    }
  }

  public getCarColor(reservation: IParkingReservation) {
    if (reservation && reservation.ADUserId === this.props.ParkingReservationStore.RootStore.CurrentUser.Id) {
      return "#f5f900";
    } else {
      return "black";
    }
  }

  public renderReservationCalendar() {
    return <div>

      <div style={{
        display: "inline-block", verticalAlign: 'top', width: this.isMobile() ? '23%' : '28%', position: 'relative', top: '37px',
        'paddingTop': this.isMobile() ? '3px' : '0px'
      }}>
        {this.props.ParkingReservationStore.GetFilteredReservations.map((parking, index) => {
          return (
            <div>


              <div key={`${index}`} style={{ display: "inline-block", verticalAlign: 'top', width: '100%' }}>
                <div >
                  <div
                    className="parkingString"
                    style={{ textDecoration: this.GetUserSign(parking.OwnerADUserId), cursor: "pointer", width: '100%', height: this.isMobile() ? '35px' : '72px' }}
                    title={parking.OwnerName ? `Tulajdonos: ${parking.OwnerName}` : "szabad"}
                  >

                    {this.isMobile() && <span onClick={() => this.props.ParkingReservationStore.OpenParkingInfoDialog(parking)}>{parking.Name}</span>}
                    {!this.isMobile() && parking.Name}
                    {!this.isMobile() ? <br /> : null}

                    {parking.OwnerName && !this.isMobile() ? parking.OwnerName : ""}
                    {!this.isMobile() ? <br /> : null}
                    {parking.LicensePlateNumber && !this.isMobile() ? parking.LicensePlateNumber : ""}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div style={{ display: "inline-block", verticalAlign: 'top', width: this.isMobile() ? '76%' : '72%', textAlign: 'left', overflowX: 'auto' }}>
        {this.renderMonthWithDates()}
        {this.props.ParkingReservationStore.GetFilteredReservations.map((parking, index) => {

          return (
            <div style={{ 'height': this.isMobile() ? '35px' : '72px' }}>
              <div style={{ display: "inline-block", verticalAlign: 'top' }}>
                <div style={{ display: "inline-block", width: '464px' }}>
                  {parking.Reservations.map((reservation, index) => {
                    if (reservation.ReservationTypeId === 1) {
                      return (
                        <div
                          key={`${index}`}
                          className="reservationRed"
                          style={{
                            background: this.GetColor(reservation),
                            border: this.GetBorderColor(reservation),
                            minWidth: this.getminWidth(reservation),
                            width: this.getWidth(reservation),
                            height: this.getHeight(reservation)
                          }}
                          onClick={() => this.props.ParkingReservationStore.OpenReservationDialog(reservation, parking)}
                        >
                          {this.isMobile() ?
                            <FontAwesomeIcon
                              style={{
                                height: "15px",
                                width: "22px",
                                cursor: "pointer",
                                marginLeft: "auto",
                                marginRight: "auto",
                                position: "relative",
                                top: "0px",
                                color: this.getCarColor(reservation),
                              }}
                              icon={faCar}
                            />
                            :
                            <FontAwesomeIcon
                              style={{
                                height: "45px",
                                width: "50px",
                                cursor: "pointer",
                                marginLeft: "auto",
                                marginRight: "auto",
                                position: "relative",
                                top: "3px",
                                color: this.getCarColor(reservation),
                              }}
                              icon={faCar}
                            />
                          }

                        </div>
                      );
                    } else if (reservation.ReservationTypeId === 3) {
                      return (
                        <div
                          key={`${index}`}
                          className="reservationOrange"
                          style={{
                            border: this.GetBorderColor(reservation),
                            background: this.GetColor(reservation),
                            minWidth: this.getminWidth(reservation),
                            width: this.getWidth(reservation),
                            height: this.getHeight(reservation)
                          }}
                          onClick={() => this.props.ParkingReservationStore.OpenReservationDialog(reservation, parking)}
                        ></div>
                      );
                    } else if (reservation.ReservationTypeId === 2) {
                      return (
                        <div
                          key={`${index}`}
                          className="reservationGreen"
                          style={{
                            border: this.GetBorderColor(reservation),
                            background: this.GetColor(reservation),
                            minWidth: this.getminWidth(reservation),
                            width: this.getWidth(reservation),
                            height: this.getHeight(reservation)
                          }}
                          onClick={() => this.props.ParkingReservationStore.OpenReservationDialog(reservation, parking)}
                        ></div>
                      );
                    } else {
                      return (
                        <div
                          key={`${index}`}
                          className="reservationLightGreen"
                          style={{
                            border: this.GetBorderColor(reservation),
                            background: this.GetColor(reservation),
                            minWidth: this.getminWidth(reservation),
                            width: this.getWidth(reservation),
                            height: this.getHeight(reservation)
                          }}
                          onClick={() => this.props.ParkingReservationStore.OpenReservationDialog(reservation, parking)}
                        ></div>
                      );
                    }
                  })}
                </div>


              </div>
            </div>
          );
        })}
      </div>

    </div>




    //return 
  }
  public isMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  public renderRadioButtons() {
    let msg = [];
    if (this.isMobile()) {
      msg = ["Saját és szabad", "Saját", "Mind"];

    } else {
      msg = ["Csak saját, illetve szabad parkolók mutatása", "Csak saját foglalások mutatása", "Minden mutatása"];

    }

    return msg.map((msg, index) => {
      return (
        <div style={{ display: "flex" }} key={index}>
          <div style={{ padding: "6px" }}>
            <input
              type="radio"
              value={index}
              name="mode"
              id={`${index}`}
              checked={this.props.ParkingReservationStore.showOnlyMyParkingPlacesOrFreeParkingPlaces === index}
              onChange={(e) => {
                this.props.ParkingReservationStore.OnChangeSelectedKey(e.target);
              }}
            />
          </div>
          <div style={{ textAlign: 'left' }}>
            <Label htmlFor={`${index}`}>
              <span style={{ color: "black" }}>{msg}</span>{" "}
            </Label>
          </div>
        </div>
      );
    });
  }

  public renderHeader() {
    return (
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td className="mobileRadio" style={{ display: "flex", paddingTop: "6px", paddingLeft: "8px" }}>{this.renderRadioButtons()}</td>
            <td className="mobileUser" style={{ width: "40%", textAlign: "right", paddingTop: "6px", paddingRight: "8px" }}>
              <FontAwesomeIcon style={{ height: "16px", width: "16px", position: "relative", marginRight: "4px" }} icon={faUser} />
              {this.isMobile() ? null : <b>Felhasználó: </b>}
              {this.props.ParkingReservationStore.RootStore.CurrentUser ? this.props.ParkingReservationStore.RootStore.CurrentUser.Name : null}
              <FontAwesomeIcon onClick={() => this.props.ParkingReservationStore.RootStore.logOut()} style={{ height: "16px", marginLeft: '3px', width: "16px", position: "relative", marginRight: "4px" }} icon={faSignOutAlt} />
              {/* &&  this.props.ParkingReservationStore.RootStore.CurrentUser && !this.props.ParkingReservationStore.RootStore.CurrentUser.IsAdmin */}
              {this.isMobile()
                ? <div

                  onClick={() => this.props.ParkingReservationStore.RootStore.GoChangePassword()}

                  style={{
                    'marginTop': '10px',

                    cursor: 'pointer',
                    background: 'lightgray',
                    padding: '1px',
                    border: '1px solid lightgray',
                    'borderRadius': '1px',
                    'textAlign': 'center'
                  }}>Jelszó változtatás</div> : null}
            </td>
          </tr>
          <tr>
            {/* <td style={{ display: "flex" }}></td> */}
            <td colSpan={2}
              style={{
                cursor: "pointer",
                textAlign: "right",
                paddingRight: "8px",
                color: this.props.ParkingReservationStore.GetReservedByUserParkingPlaceNumber > 7 ? "red" : "black",
              }}
              title={"Csak azoknak a foglalásoknak a száma, melyet te foglaltál"}
            >
              <b>Egyedi foglalásaim száma az adott héten:</b> {this.props.ParkingReservationStore.GetReservedByUserParkingPlaceNumber}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  public renderCalendar() {
    const { ParkingReservationStore } = this.props;
    let color = ParkingReservationStore.SameDay(new Date(ParkingReservationStore.mondayOfActualWeek), new Date(ParkingReservationStore.monday))
      ? "lightgray"
      : "white";

    // console.log("MONDAY", new Date(ParkingReservationStore.monday));
    // console.log("VÁLTOZÓ MONDAY", new Date(ParkingReservationStore.mondayOfActualWeek));

    // console.log(
    //   "SAMEDAY",
    //   ParkingReservationStore.SameDay(new Date(ParkingReservationStore.mondayOfActualWeek), new Date(ParkingReservationStore.monday))
    // );

    return (
      <div style={{ display: "flex" }}>
        {!this.isMobile() && <div className="parkingString" style={{ width: "185px" }}></div>}
        <div className="dateDiv">
          <div className="prevIconDiv">
            <img src={this.iconPrev} alt="" onClick={() => ParkingReservationStore.GetPrevMonth()} className="prevIcon" />
          </div>
          <div
            className="calendarDiv"
            style={{
              background: color,
              fontSize: this.isMobile() ? 13 : 16
            }}
          >
            {ParkingReservationStore.getFormattedDate3(ParkingReservationStore.mondayOfActualWeek)} -{" "}
            {ParkingReservationStore.getFormattedDate3(ParkingReservationStore.mondayOfNextWeek)}
          </div>

          <div className="nextIconDiv">
            <img src={this.iconNext} alt="" onClick={() => ParkingReservationStore.GetNextWeek()} className="nextIcon" />
          </div>
        </div>
      </div>
    );
  }

  public renderMonthWithDates() {
    return (
      // <div style={{ display: "flex", width: "474px", marginLeft: "auto", marginRight: "auto" }}>
      <div style={{ display: "flex" }}>
        {!this.isMobile() && <div className="parkingString"></div>}
        {this.props.ParkingReservationStore.ParkingReservations &&
          this.props.ParkingReservationStore.ParkingReservations.length > 0 &&
          this.props.ParkingReservationStore.ParkingReservations[0].Reservations.map((r, index) => {
            return (
              <div key={`${index}`} className="mobileDayNames" style={{ height: "30px", minWidth: "55px", margin: "5px" }}>
                <b>{this.GetDate(new Date(r.ReservationDate))}</b>
                <div>{this.props.ParkingReservationStore.getFormattedDate2(new Date(r.ReservationDate))}</div>
              </div>
            );
          })}
      </div>
    );
  }

  public renderColorExplanation() {
    const colors = [
      { key: 6, style: "explanationRed", text: "Saját foglalás" },
      { key: ReservationTypes.Foglalva, style: "explanationRed", text: "Foglalva" },
      { key: ReservationTypes.Felszabaditva, style: "explanationGreen", text: "Felszabadítva" },
      { key: ReservationTypes.Tulajdonose, style: "explanationOrange", text: "Tulajdonosé" },
      { key: ReservationTypes.Senkie, style: "explanationLightGreen", text: "Szabad" },
      { key: 5, style: "explanationWithBorder", text: "Foglalható dátum intervallum" },
    ];
    return (
      <div className="mobileColorExp" style={{ display: "flex", margin: "12px" }}>
        {colors.map((c, i) => {
          return (
            <div style={{ display: "flex", margin: "12px" }} key={i}>
              <div className={c.style}>
                {c.key == ReservationTypes.Foglalva ? (
                  <FontAwesomeIcon style={{ height: "16px", width: "20px", position: "relative", top: "3px" }} icon={faCar} />
                ) : (
                  ""
                )}
                {c.key == 6 ? (
                  <FontAwesomeIcon style={{ height: "16px", width: "20px", position: "relative", top: "3px", color: "yellow" }} icon={faCar} />
                ) : (
                  ""
                )}
              </div>
              <div>{c.text}</div>
            </div>
          );
        })}
      </div>
    );
  }

  public GetColor(reservation: IParkingReservation) {
    if (
      this.props.ParkingReservationStore.SelectedParkingReservation &&
      this.props.ParkingReservationStore.SelectedParkingReservation == reservation
    ) {
      return "yellow";
    }
  }
  public getminWidth(reservation: IParkingReservation) {
    if (this.isMobile()) {
      return "22px";
    } else {
      if (
        this.props.ParkingReservationStore.IntervalendDate &&
        new Date(this.props.ParkingReservationStore.IntervalendDate).toLocaleDateString("hu-HU") >=
        new Date(reservation.ReservationDate).toLocaleDateString("hu-HU") &&
        this.props.ParkingReservationStore.Today &&
        new Date(this.props.ParkingReservationStore.Today).toLocaleDateString("hu-HU") <=
        new Date(reservation.ReservationDate).toLocaleDateString("hu-HU")
      ) {
        return "46px";
      } else {
        return "50px";
      }
    }
  }
  public getWidth(reservation: IParkingReservation) {
    if (this.isMobile()) {
      return "22px";
    } else {
      if (
        this.props.ParkingReservationStore.IntervalendDate &&
        new Date(this.props.ParkingReservationStore.IntervalendDate).toLocaleDateString("hu-HU") >=
        new Date(reservation.ReservationDate).toLocaleDateString("hu-HU") &&
        this.props.ParkingReservationStore.Today &&
        new Date(this.props.ParkingReservationStore.Today).toLocaleDateString("hu-HU") <=
        new Date(reservation.ReservationDate).toLocaleDateString("hu-HU")
      ) {
        return "46px";
      } else {
        return "52px";
      }
    }
  }
  public getHeight(reservation: IParkingReservation) {
    if (this.isMobile()) {
      return "22px";
    } else {

      if (
        this.props.ParkingReservationStore.IntervalendDate &&
        new Date(this.props.ParkingReservationStore.IntervalendDate).toLocaleDateString("hu-HU") >=
        new Date(reservation.ReservationDate).toLocaleDateString("hu-HU") &&
        this.props.ParkingReservationStore.Today &&
        new Date(this.props.ParkingReservationStore.Today).toLocaleDateString("hu-HU") <=
        new Date(reservation.ReservationDate).toLocaleDateString("hu-HU")
      ) {

        return "46px";
      } else {
        return "52px";
      }
    }

  }
  //Ha kisebb mint az interval Date de nagyobb mint a today
  public GetBorderColor(reservation: IParkingReservation) {
    // if (
    //   this.props.ParkingReservationStore.IntervalendDate &&
    //   new Date(this.props.ParkingReservationStore.IntervalendDate).toLocaleDateString("hu-HU") >=
    //     new Date(reservation.ReservationDate).toLocaleDateString("hu-HU")
    // ) {
    //   console.log("new Date(this.props.ParkingReservationStore.IntervalendDate)", new Date(this.props.ParkingReservationStore.IntervalendDate));
    //   console.log(" new Date(reservation.ReservationDate)", new Date(reservation.ReservationDate));
    // }

    if (
      this.props.ParkingReservationStore.IntervalendDate &&
      new Date(this.props.ParkingReservationStore.IntervalendDate).toLocaleDateString("hu-HU") >=
      new Date(reservation.ReservationDate).toLocaleDateString("hu-HU") &&
      this.props.ParkingReservationStore.Today &&
      new Date(this.props.ParkingReservationStore.Today).toLocaleDateString("hu-HU") <=
      new Date(reservation.ReservationDate).toLocaleDateString("hu-HU")
    ) {
      return "3px solid #3e3939";
    } else {
      return "1px solid black";
    }
  }

  public renderReservationDialog() {
    //if (!this.props.ParkingReservationStore.openReservationDialog) return null;
    // if (!this.props.ParkingReservationStore.SelectedParkingReservation) return null;
    if (this.props.ParkingReservationStore.openReservationDialog || this.props.ParkingReservationStore.openParkingDialog) {
      return (
        <div style={{ margin: "12px" }}>
          <Dialog
            hidden={false}
            onDismiss={() => {
              this.props.ParkingReservationStore.OpenReservationDialog(null, null);
            }}
            dialogContentProps={{
              type: DialogType.normal,
              className: "dialogFont",
              title:
                this.props.ParkingReservationStore.openReservationDialog && this.props.ParkingReservationStore.SelectedParkingReservation && !this.props.ParkingReservationStore.openParkingDialog ?
                  (this.getTitleText(this.props.ParkingReservationStore.SelectedParkingReservation.ReservationTypeId) as any) :
                  this.props.ParkingReservationStore.openParkingDialog && this.props.ParkingReservationStore.SelectedParkingReservation ? <b>Parkolóhely</b> : ''
              ,
            }}
          >
            {this.props.ParkingReservationStore.parkingReservationErrorMessage ? (
              <MessageBar messageBarType={MessageBarType.error}>{this.props.ParkingReservationStore.parkingReservationErrorMessage}</MessageBar>
            ) : null}

            {this.props.ParkingReservationStore.SelectedParkingReservation && this.renderReservationDialogDatas()}
            {/* {this.props.ParkingReservationStore.noRelease && 
              <span>Foglalást csak 2023. 02. 01-től lehet rögzíteni.</span>
            } */}

            {this.props.ParkingReservationStore.SelectedParkingReservation && this.renderReservationDialogButtons()}
          </Dialog>
        </div>
      );
    } else {
      return null;
    }
  }

  public renderReservationDialogDatas() {
    return (
      <div>
        <div style={{ marginTop: "12px" }}>{this.renderParkingerror()}</div>
        <Label>
          {/* Parkoló: <div>{this.props.ParkingReservationStore.SelectedParkingReservation.ParkingName}</div> */}
          Parkoló: <div>{this.props.ParkingReservationStore.SelectedParkingReservation.ResourceName}</div>
        </Label>
        {this.isMobile() && <Label>
          {/* Parkoló: <div>{this.props.ParkingReservationStore.SelectedParkingReservation.ParkingName}</div> */}
          Tulajdonos: <div>{this.props.ParkingReservationStore.SelectedParkingReservation.OwnerName}</div>
        </Label>}
        {this.isMobile() &&
          <Label>
            {/* Parkoló: <div>{this.props.ParkingReservationStore.SelectedParkingReservation.ParkingName}</div> */}
            Rendszám: <div>{this.props.ParkingReservationStore.SelectedParkingReservation.LicensePlateNumber}</div>
          </Label>
        }
        {!this.props.ParkingReservationStore.openParkingDialog &&
          <div> <Label>
            Foglalható:
            {this.IsAvailabe(this.props.ParkingReservationStore.SelectedParkingReservation)}
          </Label>

            <Label>
              {this.GetReservationDateText(this.props.ParkingReservationStore.SelectedParkingReservation.ReservationTypeId)}
              <div>{new Date(this.props.ParkingReservationStore.SelectedParkingReservation.ReservationDate).toLocaleDateString("hu-HU")}</div>
            </Label>
            {this.props.ParkingReservationStore.SelectedParkingReservation.ADUserName &&
              (this.props.ParkingReservationStore.SelectedParkingReservation.ReservationTypeId === ReservationTypes.Tulajdonose ||
                this.props.ParkingReservationStore.SelectedParkingReservation.ReservationTypeId === ReservationTypes.Foglalva) ? (
              <Label>
                Foglaló személy: <div>{this.props.ParkingReservationStore.SelectedParkingReservation.ADUserName}</div>
              </Label>
            ) : null}
            {this.props.ParkingReservationStore.IsVisibleLemondasiDatumPicker || this.props.ParkingReservationStore.IsVisibleVisszaFoglalasButton ? (
              <DatePicker
                label={
                  this.props.ParkingReservationStore.IsVisibleVisszaFoglalasButton
                    ? "Lemondás visszavonása kiválasztott időpontig"
                    : "Lemondás kiválasztott időpontig"
                }
                formatDate={this.props.ParkingReservationStore.getFormattedDate}
                value={
                  this.props.ParkingReservationStore.SelectedParkingReservation.EndOfIntervalDate
                    ? new Date(this.props.ParkingReservationStore.SelectedParkingReservation.EndOfIntervalDate)
                    : new Date(this.props.ParkingReservationStore.SelectedParkingReservation.ReservationDate)
                }
                onSelectDate={(date) => {
                  this.props.ParkingReservationStore.UpdateDate(date);
                }}
                strings={Utils.DayPickerStrings}
                firstDayOfWeek={DayOfWeek.Monday}
                minDate={new Date(this.props.ParkingReservationStore.SelectedParkingReservation.ReservationDate)}
              />
            ) : null}
          </div>}
        <div style={{ marginTop: "12px" }}>{this.renderDateError()}</div>
      </div>
    );
  }

  public GetReservationDateText(reservationTypeId) {
    if (reservationTypeId === ReservationTypes.Foglalva) {
      return "Lemondás erre a napra:";
    } else if (reservationTypeId === ReservationTypes.Tulajdonose) {
      return "Felszabadítás ettől a naptól:";
    } else {
      return "Foglalás erre a napra:";
    }
  }

  public getTitleText(reservationTypeId) {
    const { ParkingReservationStore } = this.props;
    if (reservationTypeId === ReservationTypes.Foglalva) {
      return <b>Parkolóhely lemondás</b>;
    } else if (reservationTypeId === ReservationTypes.Tulajdonose) {
      return <b>Parkolóhely felszabadítása</b>;
    } else if (
      reservationTypeId === ReservationTypes.Felszabaditva &&
      ParkingReservationStore.SelectedParkingReservation.ADUserId == ParkingReservationStore.RootStore.CurrentUser.Id
    ) {
      return <b>Parkolóhely visszavételezése</b>;
    } else {
      return <b>Parkolóhely foglalás</b>;
    }
  }

  public IsAvailabe(reservation: IParkingReservation) {
    if (reservation) {
      if (reservation.Id != 0) {
        if (reservation.ReservationTypeId == ReservationTypes.Felszabaditva && !this.props.ParkingReservationStore.parkingReservationErrorMessage) {
          return <div style={{ color: "#52ae30" }}>Igen</div>;
        } else {
          return <div style={{ color: "#ff0000" }}>Nem</div>;
        }
      } else {
        if (reservation.ReservationTypeId == ReservationTypes.Senkie && !this.props.ParkingReservationStore.parkingReservationErrorMessage) {
          return <div style={{ color: "#52ae30" }}>Igen</div>;
        } else {
          return <div style={{ color: "#ff0000" }}>Nem</div>;
        }
      }
    }
  }

  public renderReservationDialogButtons() {
    return (
      <DialogFooter>
        <div style={{ display: "flex" }}>
          {!this.props.ParkingReservationStore.openParkingDialog && this.renderButtons()}
          <DefaultButton
            style={{ marginRight: 10 }}
            onClick={() => {
              if (!this.props.ParkingReservationStore.openParkingDialog) {
                this.props.ParkingReservationStore.OpenReservationDialog(null, null);
              } else {
                this.props.ParkingReservationStore.CloseParkingInfoDialog();

              }
            }}
            text="Mégse"
          />
        </div>
      </DialogFooter>
    );
  }

  public renderButtons() {

    if (this.props.ParkingReservationStore.IsVisibleFoglalasButton) {
      return (
        <PrimaryButton
          style={{ marginRight: 10 }}
          onClick={() => {
            this.props.ParkingReservationStore.SaveParkingReservation(ReservationTypes.Foglalva);
          }}
          text="Foglalás"
        />
      );
    }

    if (this.props.ParkingReservationStore.IsVisibleVisszaFoglalasButton) {
      return (
        <PrimaryButton
          style={{ marginRight: 10 }}
          onClick={() => {
            this.props.ParkingReservationStore.SaveParkingReservation(ReservationTypes.Tulajdonose);
          }}
          text="Felszabadítás visszavonása"
          disabled={this.props.ParkingReservationStore.errorMessage || this.props.ParkingReservationStore.badDateErrorMessage}
        />
      );
    }

    if (this.props.ParkingReservationStore.IsAbleToDeleteReservation) {
      if (this.GetCancelButtonText() != null) {
        return (
          <PrimaryButton
            style={{ marginRight: 10 }}
            onClick={() => {
              if (this.props.ParkingReservationStore.SelectedParkingReservation.Id == 0) {
                this.props.ParkingReservationStore.SaveParkingReservation(ReservationTypes.Felszabaditva);
              } else {
                //4-el küldöm el, backenden megnézem, hogy 4 és van resourceId akkor felszabadítva állapotba teszem, ha nincs Senkié állapotba
                this.props.ParkingReservationStore.SaveParkingReservation(ReservationTypes.Senkie);
              }
            }}
            text={this.GetCancelButtonText()}
            disabled={this.props.ParkingReservationStore.errorMessage || this.props.ParkingReservationStore.badDateErrorMessage}
          />
        );
      }
    }

  }

  public GetCancelButtonText() {
    if (
      this.props.ParkingReservationStore.SelectedParkingReservation.Id == 0 &&
      this.props.ParkingReservationStore.SelectedParkingReservation.ReservationTypeId == ReservationTypes.Tulajdonose &&
      this.props.ParkingReservationStore.SelectedParkingReservation.ADUserId === this.props.ParkingReservationStore.RootStore.CurrentUser.Id
    ) {
      return "Felszabadítás";
    } else if (
      this.props.ParkingReservationStore.SelectedParkingReservation.Id &&
      this.props.ParkingReservationStore.SelectedParkingReservation.ReservationTypeId == ReservationTypes.Felszabaditva &&
      this.props.ParkingReservationStore.GetReservedByUserParkingPlaceNumberOfSelectedDay === 0
    ) {
      return "Foglalás";
    } else if (
      this.props.ParkingReservationStore.SelectedParkingReservation.Id &&
      this.props.ParkingReservationStore.SelectedParkingReservation.ReservationTypeId == ReservationTypes.Felszabaditva &&
      this.props.ParkingReservationStore.GetReservedByUserParkingPlaceNumberOfSelectedDay >= 1
    ) {
      return null;
    } else {
      return "Lemondás";
    }
  }

  public renderParkingerror() {
    if (this.props.ParkingReservationStore.parkingReservationErrorMessage) return null;
    if (
      this.props.ParkingReservationStore.SelectedParkingReservation.ReservationTypeId === ReservationTypes.Tulajdonose ||
      this.props.ParkingReservationStore.SelectedParkingReservation.ReservationTypeId === ReservationTypes.Foglalva
    )
      return null;
    if (!this.props.ParkingReservationStore.errorOfTooMuchParkingPlace) return null;
    return <MessageBar messageBarType={MessageBarType.error}>{this.props.ParkingReservationStore.errorOfTooMuchParkingPlace}</MessageBar>;
  }

  public renderDateError() {
    if (!this.props.ParkingReservationStore.badDateErrorMessage && !this.props.ParkingReservationStore.errorMessage) return null;
    return (
      <MessageBar messageBarType={MessageBarType.error}>
        {this.props.ParkingReservationStore.badDateErrorMessage
          ? this.props.ParkingReservationStore.badDateErrorMessage
          : this.props.ParkingReservationStore.errorMessage}
      </MessageBar>
    );
  }
}

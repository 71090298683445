import { inject, observer } from "mobx-react";
import React = require("react");
import { Route, Switch, HashRouter } from "react-router-dom";
import { RootStore } from "../../core/stores/RootStore";
import TopMenu from "./TopMenu";

import "./Layout.scss";

interface IProps {
  RootStore?: RootStore;
}

@inject("RootStore")
@observer
export class Layout extends React.Component<IProps> {
  componentDidMount() {
    const { RootStore } = this.props;

    RootStore.Init();
  }

  public render() {
    const { RootStore } = this.props;

    if (RootStore.IsLoading) return <div>Betöltés...</div>;

    return (
      <div className="app">
        <HashRouter>
          <TopMenu />

          <div id="pageContent" className="pageContent">
            <div className="sl-content">
              <div className="sl-fullwidth-box sl-shadow">
                <Switch>
                  {RootStore.RouterStore.getFlatRoutes
                    .filter((r) => r.component)
                    .map((route) => {
                      let routeComponent = route.component;

                      /*  if (RootStore.CurrentUserStore.GetPermissions.Wiew[route.id] === false) {
                    routeComponent = HomePage;
                    } */
                      return (
                        <Route
                          key={route.id + RootStore.RouterStore.locationPathname}
                          exact={route.exact}
                          path={route.path}
                          component={routeComponent}
                        />
                      );
                    })}
                </Switch>
              </div>
            </div>
          </div>
        </HashRouter>
      </div>
    );
  }
}

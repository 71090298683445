import { observable, action, computed, flow, toJS } from "mobx";

import "isomorphic-fetch";
import "url-search-params-polyfill";
import "url-polyfill";
import { ParkingReservationService } from "../services/ParkingReservationService";
import { IParkingResourceWithDayLimit, IResource } from "../../../../core/models/IResource";
import { IParkingReservation } from "../models/IParkingReservation";
import { ISaveResponse } from "../../../../core/models/ISaveResponse";
import { ReservationTypes } from "../enums/ReservationTypes";
import { ReservationResponse } from "../enums/ReservationResponse";
import { RootStore } from "../../../../core/stores/RootStore";
import { createImmediatelyInvokedArrowFunction } from "typescript";

export class ParkingReservationStore {
  public ParkingReservationService: ParkingReservationService = null;

  @observable isLoading: boolean = false;
  @observable isLoadingOfGetMonth: boolean = false;

  //1 csak az enyém és szabad/ 2-csak az enyém és a foglalt /3mind
  @observable showOnlyMyParkingPlacesOrFreeParkingPlaces: number = 0;

  @observable ParkingReservations: IResource[] = [];
  @observable ReservableDaysNumber: number = 0;
  @observable isAbleToFreeUpPlaces: number = 1;

  @observable IntervalendDate: Date = null;
  @observable Today: any = null;

  @observable SelectedParkingReservation: IParkingReservation = null;

  //Resource DropDownData
  @observable ResourceTypes = [];

  @observable DateOfPicker: Date = new Date();
  //Dialógok
  @observable openReservationDialog: boolean = false;
  @observable openParkingDialog: boolean = false;

  //Messages
  @observable parkingReservationErrorMessage: string = null;
  @observable errorOfTooMuchParkingPlace: string = null;
  @observable resourceErrorMessage: any[] = [];
  @observable badDateErrorMessage: string = null;

  //Később van , mint ma
  @observable isLaterThenGivenDays: boolean = false;
  @observable isEarlierThanToday: boolean = false;

  @observable monday: any = null;
  @observable mondayOfActualWeek: any = null;
  @observable mondayOfNextWeek: any = null;

  RootStore: RootStore = null;
  @observable errorMessage: any = null;
  @observable noRelease: boolean = false;

  constructor(rootStore: RootStore) {
    this.ParkingReservationService = new ParkingReservationService();
    this.RootStore = rootStore;
   
  }

  Init = flow(function* (this: ParkingReservationStore) {
    this.monday = this.getMonday(new Date());
    this.mondayOfActualWeek = this.getMonday(new Date());

    if (this.mondayOfActualWeek) {
      this.mondayOfNextWeek = new Date(this.mondayOfActualWeek.getTime() + 6 * 24 * 60 * 60 * 1000);
    }

    yield this.LoadParkingReservations();
  });

  @action GetNextWeek() {
    this.mondayOfNextWeek = new Date(this.mondayOfNextWeek.getTime() + 7 * 24 * 60 * 60 * 1000);
    this.mondayOfActualWeek = new Date(this.mondayOfActualWeek.getTime() + 7 * 24 * 60 * 60 * 1000);

    this.isLoading = true;
    this.LoadParkingReservations();
    this.isLoading = false;
  }

  @action GetPrevMonth() {
    this.mondayOfNextWeek = new Date(this.mondayOfNextWeek.getTime() - 7 * 24 * 60 * 60 * 1000);
    this.mondayOfActualWeek = new Date(this.mondayOfActualWeek.getTime() - 7 * 24 * 60 * 60 * 1000);

    this.isLoading = true;
    this.LoadParkingReservations();
    this.isLoading = false;
  }

  public LoadIntervalendDate() {
    // this.IntervalendDate =
    //   new Date().getFullYear() + ". " + (new Date().getMonth() + 1) + ". " + (new Date().getDate() + this.ReservableDaysNumber) + ".";

    // this.Today = new Date().getFullYear() + ". " + (new Date().getMonth() + 1) + ". " + new Date().getDate() + ".";
    this.IntervalendDate = new Date();
    this.IntervalendDate.setDate(this.IntervalendDate.getDate() + this.ReservableDaysNumber);
    // this.IntervalendDate.setHours(0, 0, 0);
    // console.log(" this.IntervalendDate", toJS(this.IntervalendDate));
    // console.log(" this.IntervalendDate", toJS(new Date(this.IntervalendDate)));
  }

  public LoadToday() {
    this.Today = new Date();
    this.Today.setHours(0, 0, 0);// this.Today = new Date().getFullYear() + ". " + (new Date().getMonth() + 1) + ". " + new Date().getDate() + ".";
    // console.log("this.Today", this.Today);
    // console.log("this.Today", new Date(this.Today));
  }

  public getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  /**
   * Parkoló foglalások lekérése. Egész hónapot lekérjük, ahol nincs foglalás, ott üres a vm, csak az időpont van kitöltve
   */
  public LoadParkingReservations = flow(function* (this: ParkingReservationStore) {
    let _actYear = this.DateOfPicker.getFullYear();
    let mondayOfWeek = this.mondayOfActualWeek;

    this.isLoading = true;
    // const request2: IResource[] = yield this.ParkingReservationService.GetParkingReservations(_actYear, mondayOfWeek);
    const request: IParkingResourceWithDayLimit = yield this.ParkingReservationService.GetParkingReservations(_actYear, mondayOfWeek);

    if (request) {
      this.ParkingReservations = request.Resources;
      this.ReservableDaysNumber = request.ParkingReservationWindowLengthDays;
      yield this.LoadToday();
      yield this.LoadIntervalendDate();
    }

    // console.log("ReservableDaysNumber", this.ReservableDaysNumber);

    this.isLoading = false;
  });

  @computed get GetFilteredReservations() {
    // alert(this.IntervalendDate)
    // alert("today: "+ this.Today)
    // alert(this.showOnlyMyParkingPlacesOrFreeParkingPlaces);
    if (this.showOnlyMyParkingPlacesOrFreeParkingPlaces === 0 && this.RootStore.CurrentUser) {
      return this.ParkingReservations.filter((parkingPlace) => {
        return (
          parkingPlace.OwnerADUserId === this.RootStore.CurrentUser.Id ||
          parkingPlace.Reservations.some(
            (res) =>
              (res.ReservationTypeId === ReservationTypes.Foglalva && res.ADUserId === this.RootStore.CurrentUser.Id) ||
              res.ReservationTypeId === ReservationTypes.Senkie ||
              (res.ReservationTypeId === ReservationTypes.Felszabaditva
                &&
                this.IntervalendDate &&
                this.IntervalendDate >= new Date(res.ReservationDate) &&
                this.Today <= new Date(res.ReservationDate)
              )
          )
        );
      });
    } else if (this.showOnlyMyParkingPlacesOrFreeParkingPlaces === 1 && this.RootStore.CurrentUser) {
      return this.ParkingReservations.filter((parkingPlace) => {
        return (
          parkingPlace.OwnerADUserId === this.RootStore.CurrentUser.Id ||
          parkingPlace.Reservations.some(
            (res) => res.ReservationTypeId === ReservationTypes.Foglalva && res.ADUserId === this.RootStore.CurrentUser.Id
          )
        );
      });
    } else {
      return this.ParkingReservations;
    }
  }

  // (this.SameDay(new Date(res.ReservationDate), new Date(this.Today)) ||
  //   this.SameDay(new Date(res.ReservationDate), new Date(this.IntervalendDate))

  public GetReservationsOdResourceByEndDate = flow(function* (this: ParkingReservationStore) {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    const request = yield fetch(
      `${process.env.SERVER_URL}/api/reservation/GetReservationsByResourceIdAndDates?resourceId=${this.SelectedParkingReservation.ResourceId
      }&startDate=${new Date(this.SelectedParkingReservation.ReservationDate).toISOString()}&endDate=${new Date(
        this.SelectedParkingReservation.EndOfIntervalDate
      ).toISOString()}`,
      options
    );

    request.json().then((data) => {
      this.isAbleToFreeUpPlaces = data;
      if (this.isAbleToFreeUpPlaces == ReservationResponse.Lemondhato) {
        // console.log("LE LEHET MONDANI");
      } else {
        // console.log("NEM LEMONDHATÓ");
      }
    });
  });

  public SaveParkingReservation = flow(function* (this: ParkingReservationStore, reservationTypeId: number) {
    const oldType = this.SelectedParkingReservation.ReservationTypeId;
    const oldAd = this.SelectedParkingReservation.ADUserId;
    this.openReservationDialog = false;

    if (reservationTypeId === ReservationTypes.Foglalva) {
      this.SelectedParkingReservation.ReservationTypeId = reservationTypeId;
    } else {
      this.ResetDatas(reservationTypeId);
    }
    this.isLoading = true;
    const response: ISaveResponse = yield this.ParkingReservationService.SaveReservations(this.SelectedParkingReservation);
    console.log("SaveParkingReservation", toJS(response));
    this.isLoading = false;

    if (response.ErrorCode == -1) {
      this.SelectedParkingReservation.ReservationTypeId = oldType;
      this.SelectedParkingReservation.ADUserId = oldAd;
      this.errorMessage = response.ErrorMessage;
      this.openReservationDialog = true;
    } else {
      this.openReservationDialog = false;
      this.SelectedParkingReservation = null;
      // this.OpenReservationDialog(null, null);
    }

    yield this.LoadParkingReservations();
  });

  @action ResetDatas(reservationTypeId: number) {
    this.SelectedParkingReservation.ReservationTypeId = reservationTypeId;
    this.SelectedParkingReservation.ADUserName = "";
    this.SelectedParkingReservation.ADUserId = "00000000-0000-0000-0000-000000000000";
  }

  /**
   * Parkolóhelyre kattinta feljön a dialógus a parkolóhely adataival
   * @param reservation
   * @param parkingName
   */
  @action OpenReservationDialog(reservation: IParkingReservation, parking: IResource) {
    // console.log("reservation", toJS(reservation));

    this.openReservationDialog = !this.openReservationDialog;
    this.openParkingDialog = false;
    if (reservation != null) {
      this.SelectedParkingReservation = reservation;
      this.SelectedParkingReservation.EndOfIntervalDate = reservation.ReservationDate;
      if (parking != null) {
        // this.SelectedParkingReservation.ParkingName = parking.Name;
        this.SelectedParkingReservation.ResourceName = parking.Name;
        this.SelectedParkingReservation.ResourceId = parking.Id;
        this.SelectedParkingReservation.OwnerName = parking.OwnerName;
        this.SelectedParkingReservation.LicensePlateNumber = parking.LicensePlateNumber;
        this.SetParkingPlaceAvailableError();
      }
    } else {
      this.SelectedParkingReservation = null;
      this.errorMessage = null;
      this.badDateErrorMessage = null;
    }
  }
  @action CloseParkingInfoDialog() {
    this.openReservationDialog = !this.openReservationDialog;
    this.openParkingDialog = !this.openParkingDialog;

    this.SelectedParkingReservation = null;
  }
  @action OpenParkingInfoDialog(parking: IResource) {
    this.openReservationDialog = !this.openReservationDialog;
    this.openParkingDialog = !this.openParkingDialog;

    if (parking != null) {
      // this.SelectedParkingReservation.ParkingName = parking.Name;
      this.SelectedParkingReservation = {} as IParkingReservation;
      this.SelectedParkingReservation.ResourceName = parking.Name;
      this.SelectedParkingReservation.ResourceId = parking.Id;
      this.SelectedParkingReservation.OwnerName = parking.OwnerName;
      this.SelectedParkingReservation.LicensePlateNumber = parking.LicensePlateNumber;

    }
  }
  /**
   * Akkor elérhető a parkolóhely, ha 1 nappal későbbit nyitunk meg, mint a mai nap.
   * A múltban nem lehet foglalni, de megtekinthető kéne legyen a foglalás
   */
  @action SetParkingPlaceAvailableError() {
    this.parkingReservationErrorMessage = null;
    this.errorOfTooMuchParkingPlace = null;
    this.isLaterThenGivenDays = false;
    this.isLaterThenGivenDays = false;
    this.isEarlierThanToday = false;

    //A mai dátum
    const today = new Date();
    // today.setHours(0, 0, 0);// today.getFullYear() + ". " + (today.getMonth() + 1) + ". " + today.getDate() + ".";
    const date = new Date(today.getFullYear(), (today.getMonth() ), today.getDate())
    //Kiválasztott parkolóhely dátuma
    const selectedParkingPlatzDate = this.SelectedParkingReservation ? new Date(this.SelectedParkingReservation.ReservationDate) : null;
    let x = null;

    if (selectedParkingPlatzDate) {
      // x = selectedParkingPlatzDate.getFullYear() + ". " + (selectedParkingPlatzDate.getMonth() + 1) + ". " + selectedParkingPlatzDate.getDate() + ".";
      x = new Date(selectedParkingPlatzDate.getFullYear(), (selectedParkingPlatzDate.getMonth() ), selectedParkingPlatzDate.getDate());
    }

    //megadott  nappal későbbi nap, mint a ma
    const todayPlusOneDay = new Date(today.getFullYear(), (today.getMonth() ), (today.getDate() + this.ReservableDaysNumber));
    // console.log("megadott  nappal későbbi nap, mint a ma", todayPlusOneDay);

    // if (x && new Date(x) < new Date(date)) {
    // alert(x)
    // alert(date)
    // alert(x && x < date)
    if (x && x < date) {
      this.parkingReservationErrorMessage = `A választott dátum (${x}) kisebb, mint a mai dátum (${date})`;
      this.isEarlierThanToday = true;
    }

    // if (x && new Date(x) > new Date(todayPlusOneDay)) {
    if (x && x > todayPlusOneDay) {
      if (this.SelectedParkingReservation.ADUserId !== this.RootStore.CurrentUser.Id) {
        
        this.parkingReservationErrorMessage = `A válsztott nap (${x.toLocaleDateString()}) nem lehet később, mint ${todayPlusOneDay.toLocaleDateString()}`;
      }

      this.isLaterThenGivenDays = true;
    }
    let releaseDate = new Date(2023, 1, 1);
    if(x<releaseDate){
      this.parkingReservationErrorMessage = `Foglalást csak 2023. 02. 01-től lehet rögzíteni.`;

      this.noRelease=true;
    }

    if (this.GetReservedByUserParkingPlaceNumberOfSelectedDay >= 1) {
      this.errorOfTooMuchParkingPlace = "Van már foglalt parkolóhelyed a kiválasztott napon";
    }
  }

  /**
   * Akkor kellene, ha a kiválasztott helynek
   * 1 - Én vagyok a tulaja
   * 2 - 3mas reservationTypeId van
   * 3 - Nincs Id
   * 4 -
   */
  @computed get IsAbleToDeleteReservation() {
    if (
      this.SelectedParkingReservation.Id == 0 &&
      this.RootStore.CurrentUser.Id === this.SelectedParkingReservation.ADUserId &&
      !this.isEarlierThanToday &&
      this.SelectedParkingReservation.ReservationTypeId === ReservationTypes.Tulajdonose
    )
      return true;
    return (
      this.SelectedParkingReservation.Id != 0 &&
      !this.isEarlierThanToday &&
      this.RootStore.CurrentUser.Id === this.SelectedParkingReservation.ADUserId
    );
  }

  @computed get IsVisibleFoglalasButton() {


    if (!this.noRelease) {

      if (this.GetReservedByUserParkingPlaceNumberOfSelectedDay >= 1) return false;
      return (
        this.SelectedParkingReservation &&
        ((this.SelectedParkingReservation.Id == 0 && this.SelectedParkingReservation.ReservationTypeId == ReservationTypes.Senkie) ||
          (this.SelectedParkingReservation.Id > 0 && this.SelectedParkingReservation.ReservationTypeId == ReservationTypes.Felszabaditva)) &&
        !this.isEarlierThanToday &&
        !this.isLaterThenGivenDays &&
        this.SelectedParkingReservation.ADUserId !== this.RootStore.CurrentUser.Id
      );
    } else {

      return false;
    }
  }

  @computed get IsVisibleVisszaFoglalasButton() {
    if (this.GetReservedByUserParkingPlaceNumberOfSelectedDay >= 1) return false;
    return (
      this.SelectedParkingReservation &&
      this.SelectedParkingReservation.Id > 0 &&
      this.SelectedParkingReservation.ReservationTypeId == ReservationTypes.Felszabaditva &&
      !this.isEarlierThanToday &&
      this.SelectedParkingReservation.ADUserId === this.RootStore.CurrentUser.Id
    );
  }

  @computed get IsVisibleLemondasiDatumPicker() {
    return (
      this.SelectedParkingReservation.Id == 0 &&
      !this.isEarlierThanToday &&
      this.RootStore.CurrentUser.Id === this.SelectedParkingReservation.ADUserId &&
      this.SelectedParkingReservation.ReservationTypeId === ReservationTypes.Tulajdonose
    );
  }

  //Ellenőrizni kell majd azt is, hogy lemondható-e az intervallum
  @action UpdateDate(date) {
    this.badDateErrorMessage = null;

    const selectedParkingPlatzDate = new Date(date);
    const x = selectedParkingPlatzDate.getFullYear() + ". " + (selectedParkingPlatzDate.getMonth() + 1) + ". " + selectedParkingPlatzDate.getDate();

    if (new Date(x) < new Date(this.Today)) {
      this.badDateErrorMessage = `A kiválasztott dátum (${x}) nem lehet korábban a mai dátumnál (${this.Today})`;
    } else {
      this.SelectedParkingReservation.EndOfIntervalDate = date;

      this.isLoading = true;
      this.GetReservationsOdResourceByEndDate();
      this.isLoading = false;
    }
  }

  @action getFormattedDate(d: Date): string {
    const dateString = d.toLocaleDateString("hu-HU", { year: "numeric", month: "numeric", day: "numeric" }).replace(/ /g, "");
    const datetimeString = `${dateString}`;

    return datetimeString;
  }

  @action getFormattedDate2(d: Date): string {
    const dateString = d.toLocaleDateString("hu-HU", { day: "numeric" }).replace(/ /g, "");
    const datetimeString = `${dateString}`;

    return datetimeString;
  }

  @action getFormattedDate3(d: Date): string {
    // let x = this.getWeek(d);

    if (!d) return null;

    let x = d.toLocaleDateString("hu-HU", { day: "numeric" }).replace(/ /g, "");
    const dateString = d.toLocaleDateString("hu-HU", { year: "numeric", month: "long" }).replace(/ /g, "");
    const datetimeString = `${dateString.replace(".", ". ")} ${x}.`;

    return datetimeString;
  }

  @action getWeek(d) {
    if (d) {
      var oneJan: any = new Date(d.getFullYear(), 0, 1);
      var numberOfDays = Math.floor((d - oneJan) / (24 * 60 * 60 * 1000));
      return Math.ceil((d.getDay() + 1 + numberOfDays) / 7);
    }
  }

  @action OnChangeSelectedKey(value) {
    this.showOnlyMyParkingPlacesOrFreeParkingPlaces = +value.value;
  }

  @computed get GetReservedByUserParkingPlaceNumber() {
    let sum = 0;
    if (this.ParkingReservations.length > 0) {
      this.ParkingReservations.forEach((parkingPlace, index) => {
        parkingPlace.Reservations.forEach((reservation, index) => {
          if (reservation.ADUserId === this.RootStore.CurrentUser.Id && reservation.ReservationTypeId === ReservationTypes.Foglalva) {
            sum = sum + 1;
          }
        });
      });
    }
    return sum;
  }

  SameDay(date1: Date, date2: Date) {
    if (!date1 || !date2) return null;
    return date1.getFullYear() == date2.getFullYear() && date1.getMonth() == date2.getMonth() && date1.getDate() == date2.getDate();
  }

  @computed get GetReservedByUserParkingPlaceNumberOfSelectedDay() {
    let sum = 0;
    //szuperadmin foglalhasson
    if (this.RootStore.CurrentUser.IsSuperAdmin) return 0;
    if (this.ParkingReservations && this.SelectedParkingReservation) {
      this.ParkingReservations.forEach((parkingPlace) => {
        parkingPlace.Reservations.forEach((reservation) => {
          //ha a foglalási dátum ugyankkor van mint
          if (
            this.SameDay(new Date(reservation.ReservationDate), new Date(this.SelectedParkingReservation.ReservationDate)) &&

            this.IntervalendDate &&
            this.IntervalendDate >= new Date(reservation.ReservationDate) &&
            this.Today <= new Date(reservation.ReservationDate)
            // this.IntervalendDate &&
            // new Date(this.IntervalendDate).toLocaleDateString("hu-HU") >= new Date(reservation.ReservationDate).toLocaleDateString("hu-HU") &&
            // this.Today &&
            // new Date(this.Today).toLocaleDateString("hu-HU") <= new Date(reservation.ReservationDate).toLocaleDateString("hu-HU")
          ) {
            if (reservation.ADUserId === this.RootStore.CurrentUser.Id && reservation.ReservationTypeId == ReservationTypes.Foglalva) {
              sum += 1;
            } else if (reservation.ReservationTypeId == ReservationTypes.Tulajdonose && reservation.ADUserId === this.RootStore.CurrentUser.Id) {
              sum += 1;
            }
          }
        });
      });
    }
    return sum;
  }
}

import { observable } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import React = require("react");
import { RootStore } from "../../core/stores/RootStore";
import { ParkingReservation } from "./components/ParkingReservation";
import { ParkingReservationStore } from "./core/stores/ParkingReservationStore";
import { RouteComponentProps } from "react-router-dom";

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  RootStore?: RootStore;
}

interface Stores {
  ParkingReservationStore: ParkingReservationStore;
}

@inject("RootStore")
@observer
export class ParkingReservationPage extends React.Component<IProps> {
  private stores: Stores;

  constructor(props: IProps) {
    super(props);
    console.log("this.props.match.params.id", this.props.match.params);

    this.stores = {
      ParkingReservationStore: new ParkingReservationStore(props.RootStore),
    };
  }

  async componentDidMount() {
    this.stores.ParkingReservationStore.Init();
  }

  public render() {
    return (
      <Provider {...this.stores}>
        <ParkingReservation />
      </Provider>
    );
  }
}

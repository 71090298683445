import { action, computed, flow, observable, toJS } from "mobx";
import { RootStore } from "../../../../../core/stores/RootStore";
import { UsersService } from "../services/UsersService";

interface IConstructorParams { }

export class UsersStore {
  public UsersService: UsersService = null;
  @observable isLoading: boolean = false;
  @observable UsersByRole: any[] = [];
  @observable Users: any[] = [];
  @observable openNewUserDialog: boolean = false;

  @observable SelectedRole: number = 1;
  @observable SelectedUser: any = {};

  @observable warningMessageOfDelete: string = null;
  @observable idOfUserForDelete: number = 0;
  RootStore = new RootStore();
  @observable user: any = {};
  @observable ShowNewPassword: boolean = false;
  @observable showNotSamePasswordMessage: boolean = false;
  @observable showNewPassword: boolean = false;
  @observable showNewPasswordConfirm: boolean = false;
  @observable resourceErrorMessage: any[] = [];



  // @observable opennewDialog: boolean = false;
  constructor(rootStore: RootStore) {
    this.UsersService = new UsersService();
    this.RootStore = rootStore;
  }

  Init = flow(function* (this: UsersStore) {
    let changePassword = this.getQueryStringParam('changePassword');
    if (changePassword == 'true') {
      this.SelectedUser = this.RootStore.CurrentUser;
      this.ShowNewPassword = true;
    } else {
      yield this.LoadUsersByRoleId();

    }
    // yield this.LoadUsers();
  });
  public getQueryStringParam(field: string, url?: string): string {
    const href = url ? url : window.location.href;
    const reg = new RegExp('[?&#]' + field + '=([^&#]*)', 'i');
    const qs = reg.exec(href);
    return qs ? qs[1] : null;
  }
  get checkCorrectFormNewPassword() {
    if (!this.SelectedUser?.NewPassword) {
      return true;
    }

    var containsLowerCase = false;
    var containsUpperCase = false;
    var containsNumber = false;
    var containsSpecialCharacter = false;

    for (let i = 0; i < this.SelectedUser.NewPassword.length; i++) {
      var character = this.SelectedUser.NewPassword[i];

      if (character == character.toLowerCase() && (character != "1" && character != "2" && character != "3" && character != "4" && character != "5" &&
        character != "6" && character != "7" && character != "8" && character != "9" && character != "10")) {
        containsLowerCase = true;
      }
      if (character == character.toUpperCase() && (character != "1" && character != "2" && character != "3" && character != "4" && character != "5" &&
        character != "6" && character != "7" && character != "8" && character != "9" && character != "10")) {
        containsUpperCase = true;
      }
      if (character == "1" || character == "2" || character == "3" || character == "4" || character == "5" ||
        character == "6" || character == "7" || character == "8" || character == "9" || character == "10") {
        containsNumber = true;
      }
      // if (character == "@" || character == "\"" || character == "!" || character == "£" || character == "$" ||
      //   character == "%" || character == "^" || character == "&" || character == "*" || character == "(" ||
      //   character == ")" || character == "#" || character == "€") {
      //   containsSpecialCharacter = true;
      // }
    }

    if (containsLowerCase && containsUpperCase && containsNumber && this.SelectedUser.NewPassword.length >7) {
      return true;
    }
    return false;
  }
  get getNotTheSamePassword() {
    if (this.SelectedUser?.NewPassword && this.SelectedUser?.NewPassword != this.SelectedUser.ConfirmPassword) {
      return true;
    }
    return false;
  }
  setShowNewPassword = () => {
    this.showNewPassword = !this.showNewPassword;
  }

  setShowNewPasswordConfirm = () => {
    this.showNewPasswordConfirm = !this.showNewPasswordConfirm;
  }
  public LoadUsersByRoleId = flow(function* (this: UsersStore) {
    this.isLoading = true;

    const response: any = yield this.UsersService.GetUsersByRoleId(null);

    if (response) {
      this.Users = response;
      console.log("this.UsersByRole", toJS(this.UsersByRole));
    }

    this.isLoading = false;
  });

  public LoadUsers = flow(function* (this: UsersStore) {
    this.isLoading = true;

    const response: any = yield this.UsersService.GetUsersFromUserInRolesTable();

    if (response) {
      this.Users = response;
    }

    this.isLoading = false;
  });


  public DisableUser = flow(function* (this: UsersStore) {
    if (confirm('Biztosan le akard tiltani ezt a felhasználót?')) {
      this.isLoading = true;
      const id = yield this.UsersService.DisableUser(this.SelectedUser.LoginName);
      console.log("resp", id);

      yield this.LoadUsersByRoleId();
      this.OpenNewUserDialog(null);

      this.isLoading = false;
    }
  });
  public EnableUser = flow(function* (this: UsersStore) {
    if (confirm('Biztosan fel akarod oldani ezt a felhasználót?')) {
      this.isLoading = true;
      const id = yield this.UsersService.EnableUser(this.SelectedUser.LoginName);
      console.log("resp", id);

      yield this.LoadUsersByRoleId();
      this.OpenNewUserDialog(null);

      this.isLoading = false;
    }
  });

  public RemoveUser = flow(function* (this: UsersStore) {
    if (confirm('Biztosan törölni szeretnéd ezt a felhasználót?')) {
      this.isLoading = true;

      let postData: any = {};
      postData = {
        UserName: this.SelectedUser.LoginName,
        DisplayName: this.SelectedUser.Name,
        Email: this.SelectedUser.Email,
        Id: this.SelectedUser.Id,

      }
      const id = yield this.UsersService.RemoveUser(postData);
      console.log("resp", id);

      yield this.LoadUsersByRoleId();
      this.OpenNewUserDialog(null);
      this.isLoading = false;
    }
  });
  public SaveUser = flow(function* (this: UsersStore) {
    this.resourceErrorMessage = [];
    if (!this.SelectedUser.Name) {
      this.resourceErrorMessage.push({ key: "Name", text: "'Név' mező kitöltése kötelező" });
    }
    if (!this.SelectedUser.Email) {
      this.resourceErrorMessage.push({ key: "Name", text: "'Email' mező kitöltése kötelező" });
    }
    if (!this.SelectedUser.LoginName) {
      this.resourceErrorMessage.push({ key: "Name", text: "'Felhasználó név' mező kitöltése kötelező" });
    }
    if (!this.SelectedUser.Password && !this.SelectedUser.Id) {
      this.resourceErrorMessage.push({ key: "Name", text: "'Jelszó' mező kitöltése kötelező" });
    }
    if (this.resourceErrorMessage.length != 0) {
      return;
    } else {
      this.isLoading = true;

      let postData: any = {};
      postData = {
        UserName: this.SelectedUser.LoginName,
        DisplayName: this.SelectedUser.Name,
        Email: this.SelectedUser.Email,
        Id: this.SelectedUser.Id,

      }
      const id = yield this.UsersService.SaveUser(postData);
      console.log("resp", id);

      yield this.LoadUsersByRoleId();
      this.OpenNewUserDialog(null);
      this.isLoading = false;
    }
  });
  public CreateUser = flow(function* (this: UsersStore) {
    this.resourceErrorMessage = [];
    if (!this.SelectedUser.Name) {
      this.resourceErrorMessage.push({ key: "Name", text: "'Név' mező kitöltése kötelező" });
    }
    if (!this.SelectedUser.Email) {
      this.resourceErrorMessage.push({ key: "Name", text: "'Email' mező kitöltése kötelező" });
    }
    if (!this.SelectedUser.LoginName) {
      this.resourceErrorMessage.push({ key: "Name", text: "'Felhasználó név' mező kitöltése kötelező" });
    }
    if (!this.SelectedUser.NewPassword) {
      this.resourceErrorMessage.push({ key: "Name", text: "'Jelszó' mező kitöltése kötelező" });
    }
    if (this.resourceErrorMessage.length != 0) {
      return;
    } else {
      this.isLoading = true;
      // this.SelectedUsers = this.SelectedUsers.map((u) => {
      //   return {
      //     ...u,
      //     Id: null,
      //     RoleId: this.SelectedRole,
      //   };
      // });
      let postData: any = {};
      postData = {
        UserName: this.SelectedUser.LoginName,
        DisplayName: this.SelectedUser.Name,
        Email: this.SelectedUser.Email,
        Password: this.SelectedUser.NewPassword

      }
      const id = yield this.UsersService.CreateUser(postData);
      if (id == "success") {
        console.log("resp", id);

        yield this.LoadUsersByRoleId();
        this.OpenNewUserDialog(null);
      } else if (id == "UserNameError") {
        alert("A felhasználónév már foglalt!")
      } else if (id == "EmailError") {
        alert("Az emailcím már foglalt!")
      }

      this.isLoading = false;
    }
  });

  public DeleteUser = flow(function* (this: UsersStore) {
    this.isLoading = true;

    const response: number = yield this.UsersService.DeleteUser(this.idOfUserForDelete);
    if (response) {
      yield this.LoadUsersByRoleId();
      yield this.LoadUsers();
    }
    this.CancelDeleteUser();

    this.isLoading = false;
  });

  @action CancelDeleteUser() {
    this.warningMessageOfDelete = null;
    this.idOfUserForDelete = 0;
  }

  @action BeforeDeleteUser(userId: number) {
    console.log("userId", userId);

    const user = this.Users.find((u) => u.Id === userId);
    console.log("user", toJS(user));

    let userName = null;
    if (user) {
      userName = user.Name;
    }
    this.idOfUserForDelete = userId;
    if (userName) {
      this.warningMessageOfDelete = `Biztos, hogy törlöd ${userName} felhasználót?`;
    }
  }
  public SaveNewPassword = flow(function* (this: UsersStore) {

    if (this.SelectedUser.NewPassword != this.SelectedUser.ConfirmPassword) {
      this.showNotSamePasswordMessage = true;
    } else if (this.checkCorrectFormNewPassword) {
      this.isLoading = true;


      let postData: any = {};
      postData = {
        UserName: this.SelectedUser.LoginName,
        Password: this.SelectedUser.NewPassword

      }
      const id = yield this.UsersService.SetNewPassword(postData);
      console.log("resp", id);
      let changePassword = this.getQueryStringParam('changePassword');

      if (changePassword == "true") {
        this.RootStore.GoParkingReservation();
      }
      else {
        yield this.LoadUsersByRoleId();
        this.OpenNewUserDialog(null);
      }


      this.isLoading = false;
    }

  });

  @action OpenNewUserDialog(id) {
    this.ShowNewPassword = false;
    this.openNewUserDialog = !this.openNewUserDialog;
    if (id) {
      const item = this.Users.find((r) => r.Id === id);
      console.log("item", item);

      if (item) {
        this.SelectedUser = item;
      }
    } else {
      this.SelectedUser = {

      };
    }
    // if (this.openNewUserDialog) {
    //   this.SelectedUsers = [];
    // }
  }

  @action NewPassword() {
    this.ShowNewPassword = true;
    // if (this.resourceErrorMessage.length != 0) {
    //   let index = this.resourceErrorMessage.findIndex((msg) => msg.key === key);
    //   if (index > -1) {
    //     this.resourceErrorMessage.splice(index, 1);
    //   }
    // }
  }
  @action UpdateUserFieldByKey(key: any, newValue: any) {
    this.SelectedUser = { ...this.SelectedUser, [key]: newValue };
    if (this.resourceErrorMessage.length != 0) {
      let index = this.resourceErrorMessage.findIndex((msg) => msg.key === key);
      if (index > -1) {
        this.resourceErrorMessage.splice(index, 1);
      }
    }
  }
  @action UpdateSelectedRoleField(newValue: any) {
    this.SelectedRole = newValue;
  }

  @computed get GetRoles() {
    if (this.RootStore.CurrentUser.IsSuperAdmin) {
      return [
        { key: 1, text: "Admin" },
        { key: 4, text: "Szuperadmin" },
      ];
    } else if (this.RootStore.CurrentUser.IsAdmin) {
      return [{ key: 1, text: "Admin" }];
    } else {
      return [];
    }

    // return this.Users.map((u) => {
    //   return { key: u.RoleId, text: u.RoleName };
    // });
  }

  @action OnChangeNewUsers(items) {
    console.log("items", items);

    // this.SelectedUsers = items.map((i) => {
    //   return {
    //     ...i,
    //     ADUserId: i.ADUserId,
    //     RoleId: this.SelectedRole,
    //   };
    // });
  }
}

import { inject, observer } from "mobx-react";
import { CommandBar } from "office-ui-fabric-react";
import React = require("react");
import { Route, Switch, HashRouter, withRouter, RouteComponentProps } from "react-router-dom";
import { RootStore } from "../../core/stores/RootStore";

interface IProps extends RouteComponentProps<unknown> {
  RootStore?: RootStore;
}

@inject("RootStore")
@observer
class TopMenu extends React.Component<IProps> {
  private logo: string = String(require("../../assets/mm-logo.svg"));

  constructor(props: IProps) {
    super(props);
    this.props.RootStore.RouterStore.onLocationUpdated(this.props.location);
    this.props.RootStore.RouterStore.updateHistory(this.props.history);
  }

  componentWillReceiveProps(nextProps: IProps) {
    this.props.RootStore.RouterStore.updateHistory(this.props.history);

    if (nextProps.location !== this.props.location) {
      this.props.RootStore.RouterStore.onLocationUpdated(nextProps.location, this.props.location);
    }
  }

  public render() {
    const { RootStore } = this.props;

    return (
      <div id="menu" style={{ display: "flex" }}>
       {this.props.RootStore.isUserAuthed && <img src={this.logo} id="logo" style={{ height: "30px", position: "relative", top: 5 }} />}
        {this.props.RootStore.isUserAuthed && !this.props.RootStore.isMobile() &&
          <CommandBar items={RootStore.RouterStore.getNavLinks} />
        } 
      </div>
    );

    return (
      <div id="menu" style={{ marginBottom: 20, display: "flex", background: "white", color: "black", padding: "12px", fontWeight: "bold" }}>
        {/* <div
          style={{ marginRight: 10, cursor: "pointer" }}
          onClick={() => {
            RootStore.GoHome();
          }}
        >
          Home
        </div> */}

        <div
          style={{ marginRight: 10, cursor: "pointer" }}
          onClick={() => {
            RootStore.GoParkingReservation();
          }}
        >
          Parkolóhely foglaló
        </div>

        <div
          style={{ marginRight: 10, cursor: "pointer" }}
          onClick={() => {
            RootStore.GoParkingResource();
          }}
        >
          Parkoló rögzítés
        </div>

        <div
          style={{ marginRight: 10, cursor: "pointer" }}
          onClick={() => {
            RootStore.GoToTableReservation();
          }}
        >
          Asztal foglaló
        </div>

        <div
          style={{ marginRight: 10, cursor: "pointer" }}
          onClick={() => {
            RootStore.GoToDepartments();
          }}
        >
          Osztály felvétele
        </div>

        <div
          style={{ marginRight: 10, cursor: "pointer" }}
          onClick={() => {
            RootStore.GoToUserInRoles();
          }}
        >
          Admin beállítása
        </div>

        <div
          style={{ marginRight: 10, cursor: "pointer" }}
          onClick={() => {
            RootStore.GoToAdminPage();
          }}
        >
          Admin
        </div>
      </div>
    );
  }
}

export default withRouter(TopMenu);

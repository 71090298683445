import * as React from "react";
import { observer, inject } from "mobx-react";
import "./Departments.scss";
import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogType,
  Dropdown,
  IDropdownOption,
  Label,
  MessageBar,
  MessageBarType,
  NormalPeoplePicker,
  PrimaryButton,
  TextField,
} from "office-ui-fabric-react";
import { DepartmentStore } from "../core/stores/DepartmentStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import NumberInput from "../../../../components/controls/NumberInput/NumberInput";

interface IDepartmentsProps {
  DepartmentStore?: DepartmentStore;
}

@inject("DepartmentStore")
@observer
export class Departments extends React.Component<IDepartmentsProps, {}> {
  private imageIcon: string = String(require("../assets/images/add-image.png"));

  constructor(props: IDepartmentsProps) {
    super(props);
  }

  public render() {
    // if (this.props.departmentStore.isLoading) return <Spinner size={SpinnerSize.medium} label="Betöltés..."></Spinner>;
    return (
      <div className="commonComponent sl-shadow">
        <div className="mainHeader">Foglalási egységek kezelése</div>

        {this.renderDepartmentDialog()}
        {this.renderLeaderDialog()}

        {this.props.DepartmentStore.isAdminMode ? (
          <div className="inputContainer">
            <PrimaryButton
              className="ui button"
              text="Új foglalási egység rögzítése"
              onClick={() => this.props.DepartmentStore.OpenDepartmentDialog(null)}
            />
          </div>
        ) : null}

        <div>{this.renderDepartments()}</div>
      </div>
    );
  }

  public renderDepartments() {
    const iconEdit = <FontAwesomeIcon style={{ height: "16px", width: "16px", position: "relative", cursor: "pointer" }} icon={faPen} />;
    const { DepartmentStore } = this.props;
    let height = 25;

    if (DepartmentStore.Departments && DepartmentStore.Departments.length > 0) {
      height = 25 + DepartmentStore.Departments.length * 25;
      if (height > 300) height = 300;

      return (
        <div className="tableFixHead" style={{ height: height }}>
          {" "}
          <table className="userTable">
            <thead>
              <tr>
                <td>Név</td>
                {DepartmentStore.isAdminMode ? <td>Szerkesztés (Admin)</td> : null}
                <td>Szerkesztés (Vezető)</td>
                {/* <td>Kép</td> */}
              </tr>
            </thead>
            <tbody>
              {DepartmentStore.Departments.map((department, index) => {
                return (
                  <tr key={index}>
                    <td>{department.Name}</td>
                    {DepartmentStore.isAdminMode ? <td onClick={() => DepartmentStore.OpenDepartmentDialog(department.Id)}>{iconEdit}</td> : null}

                    <td>
                      {DepartmentStore.Rootstore.CurrentUser.IsAdmin || department.IsCurrentUserDepartmentLeader ? (
                        <span onClick={() => DepartmentStore.OpenLeaderDialog(department.Id)}>{iconEdit}</span>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
  }

  public renderDepartmentDialog() {
    const { DepartmentStore } = this.props;

    if (!DepartmentStore.openDepartmentDialog) return null;
    let imgSrc = "";
    if (DepartmentStore.Department.TempImage) {
      imgSrc = DepartmentStore.Department.TempImage;
    } else {
      imgSrc = DepartmentStore.Department.Image ? `data:image/png;base64, ${DepartmentStore.Department.Image}` : this.imageIcon;
    }

    return (
      <Dialog
        hidden={false}
        onDismiss={() => {
          DepartmentStore.OpenDepartmentDialog(null);
        }}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Foglalási egység rögzítése",
        }}
      >
        <div className="inputContainer">
          <TextField
            label="Foglalási egység neve"
            value={DepartmentStore.Department.Name || ""}
            onChanged={(newText) => {
              DepartmentStore.OnChangeFiled("Name", newText);
            }}
          />
        </div>
        <div className="inputContainer">{DepartmentStore.showImage ? <img src={imgSrc} style={{ height: "50%", width: "50%" }} /> : "nincs"}</div>
        {DepartmentStore.isAdminMode ? (
          <div style={{ marginTop: "12px" }}>
            <div>
              <input
                id="docPanelInput2"
                type="file"
                onChange={() => {
                  DepartmentStore.SelectFile();
                }}
              />
            </div>
          </div>
        ) : null}

        <div style={{ margin: "12px" }}>
          <PrimaryButton className="ui button" text="Mentés" onClick={() => DepartmentStore.SaveDepartment()} />
          <DefaultButton
            className="ui button"
            text="Mégse"
            style={{ marginLeft: "4px" }}
            onClick={() => {
              DepartmentStore.OpenDepartmentDialog(null);
            }}
          />
        </div>
      </Dialog>
    );
  }

  public renderLeaderDialog() {
    const { DepartmentStore } = this.props;
    let height = 120;
    if (DepartmentStore.Department?.Users?.length > 1) {
      height = 120 + DepartmentStore.Department.Users.length * 50;
    }
    if (height > 300) height = 300;
    console.log("HEIGHT", height);

    if (!DepartmentStore.openLeaderDialog) return null;
    if (DepartmentStore.Department) {
      return (
        <Dialog
          hidden={false}
          onDismiss={() => {
            DepartmentStore.OpenLeaderDialog(null);
          }}
          className="departmentDialog"
          dialogContentProps={{
            type: DialogType.normal,
            title: `Vezetők/jogosult felhasználók módosítása - ${DepartmentStore.Department.Name}`,
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ display: "inline-block", width: "40%" }}>
              <img src={`data:image/png;base64, ${DepartmentStore.Department.Image}`} style={{ width: "30%" }} />
            </div>
            <div style={{ display: "inline-block", width: "60%", verticalAlign: "top" }}>
              <Checkbox
                label={" A foglalási jogosultak saját maguk számára foglalnak"}
                checked={DepartmentStore.Department.ReservableByUsers}
                onChange={(ev, checked) => {
                  DepartmentStore.SetReservedByUsers(checked);
                }}
              />
            </div>
          </div>

          <div>
            <Label>Vezetők</Label>
            <NormalPeoplePicker
              selectedItems={DepartmentStore.LoadDataForPeoplePicker(DepartmentStore.Department.Leaders as any)}
              resolveDelay={300}
              onChange={(items: any) => {
                DepartmentStore.OnChangePeoplePicker("Leaders", items);
              }}
              onResolveSuggestions={async (text) => {
                let searchResponse = await this.props.DepartmentStore.Rootstore.GetUserByNameOrEmail(text);
                searchResponse = searchResponse.filter((item) => {
                  console.log("item", item);

                  return (
                    !DepartmentStore.Department.Leaders.some((u) => u.ADUserId === item.ADUserId) &&
                    !DepartmentStore.Department.Users.some((u) => u && u.ADUserId === item.ADUserId)
                  );
                }) as any;
                return searchResponse;
              }}
              pickerSuggestionsProps={{
                noResultsFoundText: "Nincs a keresésnek megfelelő találat.",
                loadingText: "Keresés...",
                showRemoveButtons: true,
              }}
            />
          </div>
          <div>
            <button
              style={{ marginTop: "20px", background: "rgb(0, 120, 212)", color: "white", cursor: "pointer", padding: "8px", border: "none" }}
              onClick={() => DepartmentStore.AddNewDepartmentUser()}
            >
              Új személy felvétele
            </button>

            <div className="tableFixHead" style={{ height: height }}>
              <table className="userTable">
                <thead>
                  <tr>
                    <td>Foglalásra jogosult személy</td>
                    <td>Foglalható minimum hely/hét</td>
                    <td>Foglalható maximum hely/hét</td>
                  </tr>
                </thead>
                <tbody>
                  {DepartmentStore.Department.Users.length > 0 &&
                    DepartmentStore.Department.Users.map((user, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <NormalPeoplePicker
                              selectedItems={DepartmentStore.LoadDataForPeoplePicker(user ? ([user] as any) : [])}
                              resolveDelay={300}
                              onChange={(items: any) => {
                                DepartmentStore.OnChangePeoplePicker("Users", items, index);
                              }}
                              onResolveSuggestions={async (text) => {
                                let searchResponse = await this.props.DepartmentStore.Rootstore.GetUserByNameOrEmail(text);
                                // searchResponse = searchResponse.filter((item) => {
                                //   return !DepartmentStore.Department.Users.some((u) => u.ADUserId === item.ADUserId);
                                // }) as any;
                                return searchResponse;
                              }}
                              pickerSuggestionsProps={{
                                noResultsFoundText: "Nincs a keresésnek megfelelő találat.",
                                loadingText: "Keresés...",
                                showRemoveButtons: true,
                              }}
                              itemLimit={1}
                            />
                          </td>
                          <td>
                            <NumberInput
                              value={user && user.ReservationPerWeekMin ? user.ReservationPerWeekMin : null}
                              onValueChange={(newText) => {
                                DepartmentStore.OnChnageUserField("ReservationPerWeekMin", newText, index);
                              }}
                              allowNegative={false}
                              allowLeadingZeros={false}
                              disabled={!user}
                            />
                            {/* <TextField
                              value={user && user.ReservationPerWeekMin ? user.ReservationPerWeekMin.toString() : ""}
                              onChanged={(newText) => {
                                DepartmentStore.OnChnageUserField("ReservationPerWeekMin", newText, index);
                              }}
                            /> */}
                          </td>
                          <td>
                            <NumberInput
                              value={user && user.ReservationPerWeekMax ? user.ReservationPerWeekMax : null}
                              onValueChange={(newText) => {
                                DepartmentStore.OnChnageUserField("ReservationPerWeekMax", newText, index);
                              }}
                              allowNegative={false}
                              allowLeadingZeros={false}
                              disabled={!user}
                            />
                            {/* <TextField
                              value={user && user.ReservationPerWeekMax ? user.ReservationPerWeekMax.toString() : ""}
                              onChanged={(newText) => {
                                DepartmentStore.OnChnageUserField("ReservationPerWeekMax", newText, index);
                              }}
                            /> */}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>

          {this.renderError()}

          <div style={{ margin: "12px" }}>
            <PrimaryButton className="ui button" text="Mentés" onClick={() => DepartmentStore.SaveDepartment()} />
            <DefaultButton
              className="ui button"
              text="Mégse"
              style={{ marginLeft: "4px" }}
              onClick={() => {
                DepartmentStore.OpenLeaderDialog(null);
              }}
            />
          </div>
        </Dialog>
      );
    }
  }

  public renderError() {
    if (!this.props.DepartmentStore.errorMessage) {
      return null;
    } else {
      return <MessageBar messageBarType={MessageBarType.error}>{this.props.DepartmentStore.errorMessage}</MessageBar>;
    }
  }
}

import * as React from "react";
import { observer, inject } from "mobx-react";
import "./Resource.scss";
import { ResourceStore } from "../core/store/ResourceStore";
import {
  DefaultButton,
  Dialog,
  DialogType,
  Label,
  MessageBar,
  MessageBarType,
  NormalPeoplePicker,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TextField,
} from "office-ui-fabric-react";
import { faPencilAlt,faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IResourceProps {
  ResourceStore?: ResourceStore;
}

@inject("ResourceStore")
@observer
export class Resource extends React.Component<IResourceProps, {}> {
  constructor(props: IResourceProps) {
    super(props);
  }

  public render() {
    if (this.props.ResourceStore.isLoading) return <Spinner size={SpinnerSize.medium} label="Betöltés..."></Spinner>;
    return (
      <div className="commonComponent sl-shadow">
        <div className="mainHeader">Parkolóhelyek kezelése</div>

        <div className="inputContainer">
          <table style={{ marginLeft: "auto" }}>
            <tr>
              <td>
                <PrimaryButton
                  // className="sl-content-right"
                  text="Parkoló Excel letöltése"
                  onClick={() => this.props.ResourceStore.GenerateReport()}
                />
              </td>
              <td>
                <PrimaryButton
                  // className="sl-content-right"
                  text="Foglalási napló letöltése"
                  onClick={() => this.props.ResourceStore.GenerateEventLogReport()}
                />
              </td>
              <td>
                <PrimaryButton
                  // className="sl-content-right"
                  text="Parkolótörzsnapló letöltése"
                  onClick={() => this.props.ResourceStore.GenerateResourceLogReport()}
                />
              </td>
            </tr>
          </table>
        </div>
        <div className="inputContainer">
          <PrimaryButton className="ui button" text="Új parkolóhely rögzítése" onClick={() => this.props.ResourceStore.OpenResourceDialog(null)} />
        </div>
        {this.props.ResourceStore.oprenNewResourceDialog ? this.renderNewResourceDialog() : null}
        <div className="inputContainer">{this.props.ResourceStore.Resources && this.renderResources()}</div>
      </div>
    );
  }

  public renderNewResourceDialog() {
    return (
      <Dialog
        hidden={false}
        onDismiss={() => {
          this.props.ResourceStore.OpenResourceDialog(null);
        }}
        className="carReservationDialog"
        dialogContentProps={{
          type: DialogType.normal,
          title: "Parkolóhely rögzítés",
        }}
      >
        {this.renderError()}
        {this.renderSaveError()}
        <div className="inputContainer ">
          <TextField
            label="Megnevezés"
            value={this.props.ResourceStore.Resource.Name || ""}
            onChanged={(newText) => {
              this.props.ResourceStore.UpdateResourceFieldByKey("Name", newText);
            }}
          />
        </div>
        <div className="inputContainer">
          <Label>Felhasználó, akinek céges autójához a parkolóhely tartozik</Label>
          <NormalPeoplePicker
            selectedItems={this.props.ResourceStore.Resource.Users as any}
            resolveDelay={300}
            onChange={(items: any) => {
              this.props.ResourceStore.OnChangeParkingOwner(items);
            }}
            onResolveSuggestions={async (text) => {
              return await this.props.ResourceStore.GetUserByNameOrEmail(text);
            }}
            pickerSuggestionsProps={{
              noResultsFoundText: "Nincs a keresésnek megfelelő találat.",
              loadingText: "Keresés...",
              showRemoveButtons: true,
            }}
            itemLimit={1}
          />
        </div>
        <div className="inputContainer ">
          <div style={{ width: "120px" }}>
            <TextField
              label="Rendszám"
              maxLength={12}
              value={this.props.ResourceStore.Resource.LicensePlateNumber || ""}
              onChanged={(newText) => {
                this.props.ResourceStore.UpdateResourceFieldByKey("LicensePlateNumber", newText);
              }}
            />
          </div>
          <span style={{ fontSize: "11px" }}>
            Az alábbi formátumban add meg a rendszámot: <span style={{ color: "orange" }}>XYZ123</span>
          </span>
        </div>

        <div style={{ margin: "24px", textAlign: "center" }}>
          <PrimaryButton style={{ marginRight: "4px" }} className="ui button" text="Mentés" onClick={() => this.props.ResourceStore.SaveResource()} />
          <DefaultButton
            style={{ marginLeft: "4px" }}
            className="ui button"
            text="Mégse"
            onClick={() => {
              this.props.ResourceStore.CancelResource();
              this.props.ResourceStore.OpenResourceDialog(null);
            }}
          />
        </div>
      </Dialog>
    );
  }

  public renderError() {
    if (this.props.ResourceStore.resourceErrorMessage.length != 0) {
      return (
        <MessageBar messageBarType={MessageBarType.error}>
          <ul>
            {this.props.ResourceStore.resourceErrorMessage.map((e, i) => {
              return (
                <li key={i} style={{ textAlign: "left" }}>
                  {e.text}
                </li>
              );
            })}
          </ul>
        </MessageBar>
      );
    } else {
      return null;
    }
  }

  public renderSaveError() {
    if (!this.props.ResourceStore.saveErrorMessage) {
      return null;
    } else {
      return <MessageBar messageBarType={MessageBarType.error}>{this.props.ResourceStore.saveErrorMessage}</MessageBar>;
    }
  }

  public renderResources() {
    const editIcon = <FontAwesomeIcon style={{ height: "15px", width: "15px", position: "relative", cursor: "pointer" }} icon={faPencilAlt} />;
    const iconBin = <FontAwesomeIcon style={{ height: "16px", width: "16px", position: "relative", cursor: "pointer" }} icon={faTrashAlt} />;
    return (
      <div className="tableFixHead">
        <table className="userTable">
          <thead>
            <tr>
              <td>Parkoló neve</td>
              <td>Tulajdonos</td>
              <td>Rendszám</td>
              <td>Szerkesztés</td>
            </tr>
          </thead>
          <tbody>
            {this.props.ResourceStore.Resources.map((r, index) => {
              return (
                <tr key={index}>
                  <td>{r.Name}</td>
                  <td>{r.OwnerName ? r.OwnerName : ""}</td>
                  <td>{r.OwnerName && r.LicensePlateNumber ? r.LicensePlateNumber : ""}</td>
                  <td>
                    <span onClick={() => this.props.ResourceStore.OpenResourceDialog(r.Id)}>{editIcon}</span>
                    <span style={{'marginLeft':'10px'}} onClick={() => this.props.ResourceStore.removeResource(r.Id)}>{iconBin}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

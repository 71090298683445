import { observable } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import React = require("react");
import { RouteComponentProps } from "react-router-dom";
import { ResourceStore } from "./core/store/ResourceStore";
import { Resource } from "./components/Resource";
import { RootStore } from "../../../core/stores/RootStore";

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  RootStore?: RootStore;
}

interface Stores {
  ResourceStore: ResourceStore;
}

@inject("RootStore")
@observer
export class ResourcePage extends React.Component<IProps> {
  private stores: Stores;

  constructor(props: IProps) {
    super(props);
    console.log("this.props.match.params.id", this.props.match.params);

    this.stores = {
      ResourceStore: new ResourceStore(props.RootStore),
    };
  }

  async componentDidMount() {
    this.stores.ResourceStore.Init();
  }

  public render() {
    return (
      <Provider {...this.stores}>
        <Resource />
      </Provider>
    );
  }
}

import * as React from "react";
import { observer, inject } from "mobx-react";
import "./TableReservation.scss";
import { every, map } from "lodash";
import * as d3 from "d3";
import {
  Checkbox,
  DatePicker,
  DayOfWeek,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDropdownOption,
  Label,
  MessageBar,
  MessageBarType,
  NormalPeoplePicker,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TextField,
} from "office-ui-fabric-react";
import { TableReservationStore } from "../core/stores/TableReservationStore";
import { ReservationTypes } from "../../ParkingReservation/core/enums/ReservationTypes";
import { ITable } from "../../../core/models/ITable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import Utils from "../../../core/Utils/Utils";

interface IDepartmentFormProps {
  TableReservationStore?: TableReservationStore;
}

@inject("TableReservationStore")
@observer
export class TableReservation extends React.Component<IDepartmentFormProps, {}> {
  private iconNext: string = String(require("../../ParkingReservation/assets/images/next.svg"));
  private iconPrev: string = String(require("../../ParkingReservation/assets/images/back.svg"));

  constructor(props: IDepartmentFormProps) {
    super(props);
  }

  public render() {
    // if (this.props.TableReservationStore.isLoading) return <Spinner size={SpinnerSize.medium} label="Betöltés..."></Spinner>;
    return (
      <div className="commonComponent sl-shadow">
        <div className="mainHeader">{this.props.TableReservationStore.isAdminMode ? "Ülőhelyek beállításai" : "Ülőhely foglalása"}</div>

        <div className="center"></div>

        <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
          {this.renderHeader()}
          {!this.props.TableReservationStore.isAdminMode ? this.renderMinMaxDays() : null}

          {this.renderReservations()}

          {!this.props.TableReservationStore.isAdminMode ? (
            <div style={{ display: "inline-block", marginLeft: "auto" }}>
              <div>{this.renderCalendar()}</div>
            </div>
          ) : null}

          {this.props.TableReservationStore.Today && this.props.TableReservationStore.Today.getDay() == 0 ? (
            <div style={{ color: "red", background: "#f7c5c5", padding: "4px", width: "471px", marginLeft: "auto", marginRight: "auto" }}>
              Vasárnap
            </div>
          ) : null}

          {this.props.TableReservationStore.Today && this.props.TableReservationStore.is_holiday(this.props.TableReservationStore.Today) ? (
            <div
              style={{
                color: "red",
                background: "#f7c5c5",
                padding: "4px",
                width: "471px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Ünnepnap
            </div>
          ) : null}
        </div>

        {this.renderTextIfNotReservableByUser()}

        <div className="input"> {this.renderDepartment()}</div>
        {this.renderTableDialog()}
        {!this.props.TableReservationStore.isAdminMode ? this.renderColorExplanation() : null}
      </div>
    );
  }

  public renderTextIfNotReservableByUser() {
    const { TableReservationStore } = this.props;
    if (!TableReservationStore.Department.ReservableByUsers) {
      return (
        <div
          style={{
            color: "black",
            background: "#fbce7a",
            padding: "4px",
            width: "471px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "8px",
          }}
        >
          Az egység vezetője foglalhat csak széket
        </div>
      );
    } else {
      return null;
    }
  }

  public renderMinMaxDays() {
    const { TableReservationStore } = this.props;
    const minimum =
      TableReservationStore.TablesWithUser && TableReservationStore.TablesWithUser.DepartmentUser
        ? TableReservationStore.TablesWithUser.DepartmentUser.ReservationPerWeekMin
        : 0;
    const maximum =
      TableReservationStore.TablesWithUser && TableReservationStore.TablesWithUser.DepartmentUser
        ? TableReservationStore.TablesWithUser.DepartmentUser.ReservationPerWeekMax
        : 0;
    console.log("minium", minimum);

    if (TableReservationStore.TablesWithUser && TableReservationStore.TablesWithUser.DepartmentUser && (minimum > 0 || maximum > 0)) {
      return (
        <div>
          {TableReservationStore.SelectedUser ? <span>{TableReservationStore.SelectedUser.ADUserName} - </span> : null}
          Minimum foglalás/hét:{" "}
          <span
            style={{
              color: minimum > TableReservationStore.getMyReservationsOfOneWeek ? "red" : "black",
            }}
          >
            {TableReservationStore.TablesWithUser.DepartmentUser.ReservationPerWeekMin
              ? TableReservationStore.TablesWithUser.DepartmentUser.ReservationPerWeekMin
              : "(nincs megadva)"}{" "}
          </span>
          - Maximum foglalás/hét:{" "}
          <span
            style={{
              color: maximum < TableReservationStore.getMyReservationsOfOneWeek ? "red" : "black",
            }}
          >
            {TableReservationStore.TablesWithUser.DepartmentUser.ReservationPerWeekMax
              ? TableReservationStore.TablesWithUser.DepartmentUser.ReservationPerWeekMax
              : "(nincs megadva)"}
          </span>{" "}
          - Tényleges foglalás a héten:{" "}
          <span
            style={{
              color:
                maximum < TableReservationStore.getMyReservationsOfOneWeek || minimum > TableReservationStore.getMyReservationsOfOneWeek
                  ? "red"
                  : "green",
            }}
          >
            {TableReservationStore.getMyReservationsOfOneWeek}
          </span>
        </div>
      );
    } else return null;
  }

  public renderHeader() {
    const { TableReservationStore } = this.props;

    return (
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td style={{ width: "20%", textAlign: "left" }}>Foglalási egység</td>
            <td style={{ width: "40%" }}>
              {" "}
              <Dropdown
                style={{ width: "100%" }}
                // className="calendarDiv2Copy"
                options={this.props.TableReservationStore.GetDepartmentsDropDownOptions}
                selectedKey={this.props.TableReservationStore.Department.Id || ""}
                onChanged={(option: IDropdownOption) => {
                  this.props.TableReservationStore.GetDepartmentById(+option.key);
                }}
              />
            </td>
            <td style={{ textAlign: "right", paddingTop: "6px", paddingRight: "8px" }}>
              <FontAwesomeIcon style={{ height: "16px", width: "16px", position: "relative", marginRight: "4px" }} icon={faUser} />
              <b>Felhasználó: </b>
              <span title={TableReservationStore.getRoles()} style={{ cursor: "pointer" }}>
                {TableReservationStore.getUser}
              </span>

            </td>
          </tr>
          {!this.props.TableReservationStore.isAdminMode ? (
            <tr>
              <td style={{ textAlign: "left" }}>
                {" "}
                {!this.props.TableReservationStore.Department.ReservableByUsers &&
                this.props.TableReservationStore.Department.IsCurrentUserDepartmentLeader &&
                this.props.TableReservationStore.UsersForSelect.length > 0 ? (
                  <span>Kinek a nevében foglalok</span>
                ) : null}
              </td>
              <td>
                {" "}
                {!this.props.TableReservationStore.Department.ReservableByUsers &&
                this.props.TableReservationStore.Department.IsCurrentUserDepartmentLeader &&
                this.props.TableReservationStore.UsersForSelect.length > 0 ? (
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "90%" }}>
                      <Dropdown
                        // className="calendarDiv2Copy"
                        options={this.props.TableReservationStore.GetUserSelectDropDownOptions}
                        selectedKey={this.props.TableReservationStore.SelectedUser ? this.props.TableReservationStore.SelectedUser.Id : ""}
                        onChanged={(option: IDropdownOption) => {
                          this.props.TableReservationStore.OnChangeUserSelect(+option.key);
                        }}
                      />
                    </div>
                    <div style={{ width: "10%", top: "8px", position: "relative" }}>
                      <span
                        title="Kiválasztott felhasználó eltávolítása"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => this.props.TableReservationStore.DeleteSelectedUser()}
                      >
                        X
                      </span>
                    </div>
                  </div>
                ) : null}
              </td>
              <td style={{ textAlign: "right", paddingTop: "6px", paddingRight: "8px" }}>
                <span
                  style={{ color: "green", textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    this.props.TableReservationStore.ShowMyReservations();
                  }}
                  title={"Csak azoknak a foglalásoknak a száma, melyeket te foglaltál"}
                >
                  {this.props.TableReservationStore.SelectedUser
                    ? `${this.props.TableReservationStore.SelectedUser.ADUserName} egyedi foglalásai a kiválasztott egységen (2 hét)`
                    : "Egyedi foglalásaim a kiválasztott egységen (2 hét)"}
                </span>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    );
  }

  public renderCalendar() {
    const { TableReservationStore } = this.props;

    return (
      <div className="dateDiv2">
        <div className="prevIconDiv2">
          <img src={this.iconPrev} alt="" onClick={() => TableReservationStore.GetPrevDay()} className="prevIcon2" />
        </div>
        <div
          className="calendarDiv2"
          style={{ background: TableReservationStore.RootStore.SameDay(new Date(), new Date(TableReservationStore.Today)) ? "lightgray" : "white" }}
        >
          {TableReservationStore.GetFormatedDate}
        </div>

        <div className="nextIconDiv2">
          <img src={this.iconNext} alt="" onClick={() => TableReservationStore.GetNextDay()} className="nextIcon2" />
        </div>
      </div>
    );
  }

  public renderAdminDialog() {
    const { TableReservationStore } = this.props;
    if (!TableReservationStore.selectedTable) return null;

    return (
      <Dialog
        hidden={false}
        onDismiss={() => {
          TableReservationStore.onSelectedTable(null);
        }}
        dialogContentProps={{
          type: DialogType.normal,
          title: TableReservationStore.selectedTable.Name,
          subText: "Admin",
        }}
      >
        <div className="input">
          <TextField
            label="Szék"
            value={TableReservationStore.selectedTable.Name || ""}
            onChanged={(newText) => {
              TableReservationStore.OnChnageTableField("Name", newText);
            }}
          />
        </div>
        <div className="input">
          <Checkbox
            label={"Rendelkezik tulajdonossal"}
            checked={TableReservationStore.selectedTable.ReservedByDefault}
            onChange={(ev, checked) => {
              TableReservationStore.OnChnageTableField("ReservedByDefault", checked);
            }}
          />
        </div>
        {TableReservationStore.selectedTable.ReservedByDefault ? (
          <div className="input">
            <div>Székhez tartozó tulajdonos:</div>
            <NormalPeoplePicker
              key={TableReservationStore.ppickerKey}
              selectedItems={TableReservationStore.LoadDataForPeoplePicker(TableReservationStore.selectedTable.Owner)}
              resolveDelay={300}
              itemLimit={1}
              onChange={(items: any) => {
                TableReservationStore.OnChangePeoplePicker(items);
              }}
              onResolveSuggestions={async (text) => {
                let searchResponse = await TableReservationStore.GetLeadersByQueryText(text);
                return searchResponse;
              }}
              pickerSuggestionsProps={{
                noResultsFoundText: "Nincs a keresésnek megfelelő találat.",
                loadingText: "Keresés...",
                showRemoveButtons: true,
              }}
            />
          </div>
        ) : null}

        <div className="input">{this.renderError()}</div>
        <DialogFooter>
          <PrimaryButton text="Mentés" onClick={() => TableReservationStore.SaveTable()} />
          <DefaultButton text="Mégse" onClick={() => TableReservationStore.onSelectedTable(null)} />
        </DialogFooter>
      </Dialog>
    );
  }

  public getDatePickerLabel(reservationTypeId) {
    if (reservationTypeId === ReservationTypes.Tulajdonose) {
      return "Felszabadítás kiválasztott időpontig";
    } else if (reservationTypeId === ReservationTypes.Felszabaditva) {
      return "Felszabadítás visszavonása kiválasztott időpontig";
    } else {
      return "";
    }
  }

  public getTitleText(reservationTypeId) {
    const { TableReservationStore } = this.props;
    if (reservationTypeId === ReservationTypes.Foglalva) {
      return <b>{TableReservationStore.selectedTable.Name} fogalás lemondása</b>;
    } else if (reservationTypeId === ReservationTypes.Tulajdonose) {
      return <b>{TableReservationStore.selectedTable.Name} felszabadítása</b>;
    } else if (
      reservationTypeId === ReservationTypes.Felszabaditva &&
      !TableReservationStore.SelectedUser &&
      TableReservationStore.selectedTable.TableReservation.ADUserId == TableReservationStore.RootStore.CurrentUser.Id
    ) {
      return <b>{TableReservationStore.selectedTable.Name} visszavételezése</b>;
    } else {
      return <b>{TableReservationStore.selectedTable.Name} foglalás</b>;
    }
  }
  public renderTableDialog() {
    const { TableReservationStore } = this.props;

    if (!TableReservationStore.selectedTable) return null;
    if (TableReservationStore.isAdminMode) return this.renderAdminDialog();

    return (
      <Dialog
        hidden={false}
        onDismiss={() => {
          TableReservationStore.onSelectedTable(null);
        }}
        className="reservationDialog"
        dialogContentProps={{
          type: DialogType.normal,
          title:
            TableReservationStore.selectedTable && (this.getTitleText(TableReservationStore.selectedTable.TableReservation.ReservationTypeId) as any),
          subText: "",
        }}
      >
        <div>
          <div style={{ margin: "12px" }}>{this.renderError()}</div>
          <div style={{ margin: "12px" }}>{this.renderWarning()}</div>
          <div style={{ margin: "12px" }}>{this.renderSucces()}</div>
          {this.renderReservationData(TableReservationStore.selectedTable)}
          <div style={{ margin: "12px" }}>{this.renderTwelveDay()}</div>
        </div>
        <DialogFooter>
          {this.GetSaveButton(TableReservationStore.selectedTable)}
          <DefaultButton text="Mégse" onClick={() => TableReservationStore.onSelectedTable(null)} />
        </DialogFooter>
      </Dialog>
    );
  }

  public renderTwelveDay() {
    const { TableReservationStore } = this.props;
    if (TableReservationStore.selectedTable.TableReservations && TableReservationStore.selectedTable.TableReservations.length > 0) {
      return (
        <div style={{ columns: "2 auto" }}>
          {TableReservationStore.selectedTable.TableReservations.map((table, i) => {
            return (
              <div key={i} style={{ display: "flex" }}>
                <div style={{ width: "150px" }}>
                  <b>Dátum: </b> {new Date(table.ReservationDate).toLocaleDateString("hu-HU")}
                </div>
                <div style={{ marginLeft: "4px", width: "170px" }}>
                  <b> Név:</b>{" "}
                  {table.ReservationTypeId === ReservationTypes.Senkie || table.ReservationTypeId === ReservationTypes.Felszabaditva
                    ? null
                    : table.ADUserName}
                </div>
                {this.getReservationType(table.ReservationTypeId, table.ADUserId)}
              </div>
            );
          })}
        </div>
      );
    }
  }

  public renderColorExplanation() {
    const colors = [
      { key: ReservationTypes.Foglalva, style: "explanationRedCircle", text: "Foglalva" },
      { key: ReservationTypes.Felszabaditva, style: "explanationGreenCircle", text: "Szabad" },
      { key: ReservationTypes.Tulajdonose, style: "explanationBlueCircle", text: "Tulajdonosé" },
      { key: ReservationTypes.Senkie, style: "explanationLightGreenCircle", text: "Felszabadítva" },
      { key: 6, style: "explanationOrangeCircle", text: "Saját foglalás" },
    ];
    return (
      <div style={{ display: "flex", margin: "12px" }}>
        {colors.map((c, i) => {
          return (
            <div style={{ display: "flex", margin: "12px" }} key={i}>
              <div className={c.style}></div>
              <div style={{ margin: "4px", marginTop: "6px" }}>{c.text}</div>
            </div>
          );
        })}
      </div>
    );
  }

  public getReservationType(reservationTypeId, aduserId) {
    const guid = this.props.TableReservationStore.SelectedUser
      ? this.props.TableReservationStore.SelectedUser.ADUserId
      : this.props.TableReservationStore.RootStore.CurrentUser.Id;
    if (reservationTypeId === ReservationTypes.Foglalva && aduserId === guid) {
      return (
        <div style={{ display: "flex" }}>
          <div style={{ background: "orange", height: "12px", width: "12px", borderRadius: "12px", padding: "2px", margin: "2px" }}></div> Saját
          foglalás
        </div>
      );
    } else if (reservationTypeId === ReservationTypes.Foglalva) {
      return (
        <div style={{ display: "flex" }}>
          <div style={{ background: "red", height: "12px", width: "12px", borderRadius: "12px", padding: "2px", margin: "2px" }}></div> Foglalt
        </div>
      );
    } else if (reservationTypeId === ReservationTypes.Tulajdonose) {
      return (
        <div style={{ display: "flex" }}>
          <div style={{ background: "blue", height: "12px", width: "12px", borderRadius: "12px", padding: "2px", margin: "2px" }}></div> Tulajdonosé
        </div>
      );
    } else if (reservationTypeId === ReservationTypes.Senkie) {
      return (
        <div style={{ display: "flex" }}>
          <div style={{ background: "green", height: "12px", width: "12px", borderRadius: "12px", padding: "2px", margin: "2px" }}></div> Szabad
        </div>
      );
    } else if (reservationTypeId === ReservationTypes.Felszabaditva) {
      return (
        <div style={{ display: "flex" }}>
          <div style={{ background: "lightgreen", height: "12px", width: "12px", borderRadius: "12px", padding: "2px", margin: "2px" }}></div>{" "}
          Felszabadítva
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   *  Ha van kiválasztva user, akkor csak Foglalni tudok/Lemondani ha az SelectedUser.ADUserId == TableReservation.ADUserId
   *  Ha nics kiválasztav:
   *  Lemondás: ha foglalva van
   *  Felszabadítás: Tulajdonosé és az owner megegyezik a reservationAduserid-val
   *  Felszab. visszavonás: Felszabadítva és owner megegyezik a reservationAduserid-val
   *  Foglalás: Senki vagy fel van szabadítva
   * @param selectedTable
   */
  public GetSaveButton(selectedTable: ITable) {
    const { TableReservationStore } = this.props;

    console.log("Nem foglalható userekkel", !TableReservationStore.Department.ReservableByUsers);
    console.log("Nem vagyok vezető", !TableReservationStore.Department.IsCurrentUserDepartmentLeader);

    //Ha vasárnap akkor nincs foglalás
    if (TableReservationStore.Today.getDay() == 0) return null;
    if (TableReservationStore.Today && TableReservationStore.is_holiday(TableReservationStore.Today)) return null;

    const canSAveReservationWhenDepartmentIsNotReservableByUser =
      !TableReservationStore.Department.ReservableByUsers && TableReservationStore.Department.IsCurrentUserDepartmentLeader;

    if (!TableReservationStore.Department.ReservableByUsers && !TableReservationStore.Department.IsCurrentUserDepartmentLeader) return null;

    if (
      TableReservationStore.Department.ReservableByUsers &&
      !TableReservationStore.Department.IsCurrentUserDepartmentUser &&
      !TableReservationStore.Department.IsCurrentUserDepartmentLeader
    )
      return null;

    if (TableReservationStore.SelectedUser) {
      if (
        selectedTable.TableReservation.ReservationTypeId === ReservationTypes.Senkie ||
        selectedTable.TableReservation.ReservationTypeId === ReservationTypes.Felszabaditva
      ) {
        return <PrimaryButton text="Foglalás" onClick={() => TableReservationStore.SaveReservation(1)} />;
      } else if (TableReservationStore.SelectedUser.ADUserId === selectedTable.TableReservation.ADUserId) {
        return <PrimaryButton text="Lemondás" onClick={() => TableReservationStore.SaveReservation(4)} />;
      } else {
        return null;
      }
    }

    if (
      (selectedTable.TableReservation.ADUserId === TableReservationStore.RootStore.CurrentUser.Id ||
        canSAveReservationWhenDepartmentIsNotReservableByUser) &&
      selectedTable.TableReservation.ReservationTypeId === ReservationTypes.Foglalva
    ) {
      return <PrimaryButton text="Lemondás" onClick={() => TableReservationStore.SaveReservation(4)} />;
    } else if (
      ((selectedTable.OwnerADUserId && selectedTable.OwnerADUserId === TableReservationStore.RootStore.CurrentUser.Id) ||
        canSAveReservationWhenDepartmentIsNotReservableByUser) &&
      selectedTable.TableReservation.ReservationTypeId === ReservationTypes.Tulajdonose
    ) {
      return <PrimaryButton text="Felszabadítás" onClick={() => TableReservationStore.SaveReservation(2)} />;
    } else if (
      ((selectedTable.OwnerADUserId && selectedTable.OwnerADUserId === TableReservationStore.RootStore.CurrentUser.Id) ||
        canSAveReservationWhenDepartmentIsNotReservableByUser) &&
      selectedTable.TableReservation.ReservationTypeId === ReservationTypes.Felszabaditva
    ) {
      return <PrimaryButton text="Felszabadítás visszavonása" onClick={() => TableReservationStore.SaveReservation(3)} />;
    } else if (
      selectedTable.TableReservation.ReservationTypeId === ReservationTypes.Senkie ||
      selectedTable.TableReservation.ReservationTypeId === ReservationTypes.Felszabaditva
    ) {
      return <PrimaryButton text="Foglalás" onClick={() => TableReservationStore.SaveReservation(1)} />;
    } else {
      return null;
    }
  }

  public GetFoglalhato(selectedTable: ITable) {
    if (
      selectedTable.TableReservation.ReservationTypeId === ReservationTypes.Felszabaditva ||
      selectedTable.TableReservation.ReservationTypeId === ReservationTypes.Senkie
    ) {
      return <div style={{ color: "green" }}>Igen</div>;
    } else {
      return <div style={{ color: "red" }}>Nem</div>;
    }
  }

  public renderReservationData(selectedTable: ITable) {
    const { TableReservationStore } = this.props;

    const canSAveReservationWhenDepartmentIsNotReservableByUser =
      !TableReservationStore.Department.ReservableByUsers && TableReservationStore.Department.IsCurrentUserDepartmentLeader;

    if (!selectedTable) return null;
    return (
      <div>
        {this.renderReservationsOfSelectedDay().length > 0 ? (
          <div style={{ margin: "12px" }}>
            <MessageBar messageBarType={MessageBarType.warning}>
              <div>
                {" "}
                {TableReservationStore.SelectedUser
                  ? TableReservationStore.SelectedUser.ADUserName
                  : TableReservationStore.RootStore.CurrentUser.Name}{" "}
                - eddigi foglalásai a kiválasztott egységen és napon:{" "}
              </div>{" "}
              {this.renderReservationsOfSelectedDay()}
            </MessageBar>
          </div>
        ) : null}

        <div className="input">
          Foglalható:
          <br />
          {this.GetFoglalhato(selectedTable)}
        </div>

        <div className="input">
          Dátum:
          <br />
          {new Date(selectedTable.TableReservation.ReservationDate).toLocaleDateString("hu-HU")}
        </div>

        {selectedTable.OwnerADUserName && selectedTable.TableReservation.ReservationTypeId === ReservationTypes.Tulajdonose ? (
          <div className="input">
            Itt ül:
            <br />
            {selectedTable.OwnerADUserName}
          </div>
        ) : selectedTable.TableReservation.ReservationTypeId === ReservationTypes.Senkie ||
          selectedTable.TableReservation.ReservationTypeId === ReservationTypes.Felszabaditva ? null : (
          <div className="input">
            Itt ül:
            <br />
            {selectedTable.TableReservation.ADUserName}
          </div>
        )}

        {((selectedTable.OwnerADUserId === TableReservationStore.RootStore.CurrentUser.Id && TableReservationStore.Department.ReservableByUsers) ||
          (canSAveReservationWhenDepartmentIsNotReservableByUser && !TableReservationStore.SelectedUser)) &&
        (selectedTable.TableReservation.ReservationTypeId === ReservationTypes.Tulajdonose ||
          selectedTable.TableReservation.ReservationTypeId === ReservationTypes.Felszabaditva) ? (
          <DatePicker
            label={selectedTable.OwnerADUserId && this.getDatePickerLabel(selectedTable.TableReservation.ReservationTypeId)}
            formatDate={TableReservationStore.RootStore.getFormattedDate}
            value={
              selectedTable.TableReservation.EndOfIntervalDate
                ? new Date(selectedTable.TableReservation.EndOfIntervalDate)
                : new Date(selectedTable.TableReservation.ReservationDate)
            }
            onSelectDate={(date) => {
              TableReservationStore.OnChnageReservationField("EndOfIntervalDate", date);
            }}
            strings={Utils.DayPickerStrings}
            firstDayOfWeek={DayOfWeek.Monday}
            minDate={TableReservationStore.Today}
          />
        ) : null}
      </div>
    );
  }

  public renderDepartment() {
    return (
      <div id="asztalfoglalo">
        {/* <img src={`data:image/png;base64, ${this.props.TableReservationStore.Department.ImageContent}`} id="table" /> */}
      </div>
    );
  }

  public renderError() {
    const { TableReservationStore } = this.props;

    if (!TableReservationStore.errorMessage) return null;
    return <MessageBar messageBarType={MessageBarType.error}>{TableReservationStore.errorMessage}</MessageBar>;
  }

  public renderWarning() {
    const { TableReservationStore } = this.props;
    //kell az is hogy selectedTable foglalható
    if (!TableReservationStore.SelectedUser && !TableReservationStore.Department.IsCurrentUserDepartmentLeader) return null;
    if (!TableReservationStore.Department.IsCurrentUserDepartmentLeader) return null;
    if (TableReservationStore.SelectedUser) return null;
    if (TableReservationStore.Department.ReservableByUsers) return null;
    return (
      <MessageBar messageBarType={MessageBarType.warning}>
        Ha nem választottál ki személyt, aki nevében foglalsz, akkor a saját nevedre fogod lefoglalni a széket
      </MessageBar>
    );
  }

  public renderSucces() {
    const { TableReservationStore } = this.props;

    if (!TableReservationStore.succesMessage) return null;
    return <MessageBar messageBarType={MessageBarType.success}>{TableReservationStore.succesMessage}</MessageBar>;
  }

  public renderReservations() {
    const { TableReservationStore } = this.props;
    if (!TableReservationStore.showMyReservations) return null;

    return (
      <Dialog
        hidden={false}
        onDismiss={() => {
          TableReservationStore.ShowMyReservations();
        }}
        className="reservationDialog"
        dialogContentProps={{
          type: DialogType.normal,
          title: TableReservationStore.SelectedUser ? ` ${TableReservationStore.SelectedUser.ADUserName} foglalásai` : "Saját foglalásaim",
          subText: "",
        }}
      >
        {TableReservationStore.getMyReservations.map((res, i) => {
          if (res.TableReservations.length > 0) {
            return (
              <div key={i}>
                <b>{res.Name}</b>
                <br />
                <div style={{ columns: "5 auto" }}>
                  {res.TableReservations.map((cic, idx) => {
                    return <div key={idx}>{new Date(cic.ReservationDate).toLocaleDateString("hu-HU")}</div>;
                  })}
                </div>
              </div>
            );
          }
        })}

        {TableReservationStore.getMyReservations.every((res) => res.TableReservations.length === 0)
          ? "Nincs foglalásom ezen a foglalási egységen"
          : null}

        <DialogFooter>
          <PrimaryButton text="Bezárás" onClick={() => TableReservationStore.ShowMyReservations()} />
        </DialogFooter>
      </Dialog>
    );
  }

  public renderReservationsOfSelectedDay() {
    let tables =
      this.props.TableReservationStore.getMyReservationsOnSelectedDay &&
      this.props.TableReservationStore.getMyReservationsOnSelectedDay.map((r, i) => {
        if (r.TableReservations.length > 0) {
          return (
            <div key={i} style={{ columns: "2 auto" }}>
              {<b>{r.Name}</b>}
              {r.TableReservations.map((res, idx) => {
                return (
                  <div key={idx}>
                    {res.ADUserName} - {new Date(res.ReservationDate).toLocaleDateString("hu-HU")}
                  </div>
                );
              })}
            </div>
          );
        }
      });

    return tables.filter((t) => t != undefined);
  }
}

import { IResource } from "../../../../core/models/IResource";
import { ISaveResponse } from "../../../../core/models/ISaveResponse";

export class ParkingReservationService {
  public async SaveReservations(selectedParkingReservation): Promise<ISaveResponse> {
    const options: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(selectedParkingReservation),
    };

    const response = await fetch(`${process.env.SERVER_URL}/api/reservation/SaveReservation`, options);
    return await response.json();
  }

  public async GetParkingReservations(_actYear: number, mondayOfWeek): Promise<IResource[]> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    const response = await fetch(
      `${process.env.SERVER_URL}/api/reservation/GetParkingReservations?_actYear=${_actYear}&mondayOfWeek=${mondayOfWeek.toISOString()}`,
      options
    );

    console.log("GetParkingReservations____________", response);

    return response.json();
  }
}

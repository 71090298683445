import { observable } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import React = require("react");
import { RouteComponentProps } from "react-router-dom";
import { RootStore } from "../../core/stores/RootStore";

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  RootStore?: RootStore;
}

interface Stores {
  // AdminStore: AdminStore;
}

@inject("RootStore")
@observer
export class AdminPage extends React.Component<IProps> {
  private stores: Stores;

  constructor(props: IProps) {
    super(props);
    console.log("this.props.match.params.id", this.props.match.params);

    this.stores = {
      // AdminStore: new AdminStore(props.RootStore),
    };
  }

  async componentDidMount() {
    // this.stores.AdminStore.Init();
  }

  public render() {
    return <Provider {...this.stores}></Provider>;
  }
}

import { action, computed, flow, observable, toJS } from "mobx";
import { RootStore } from "../../../../../core/stores/RootStore";
import { UserInRolesService } from "../services/UserInRolesService";

interface IConstructorParams {}

export class UserInRolesStore {
  public UserInRolesService: UserInRolesService = null;
  @observable isLoading: boolean = false;
  @observable UsersByRole: any[] = [];
  @observable Users: any[] = [];
  @observable openNewUserDialog: boolean = false;

  @observable SelectedRole: number = 1;
  @observable SelectedUsers: any[] = [];

  @observable warningMessageOfDelete: string = null;
  @observable idOfUserForDelete: number = 0;
  RootStore = new RootStore();
  constructor(rootStore: RootStore) {
    this.UserInRolesService = new UserInRolesService();
    this.RootStore = rootStore;
  }

  Init = flow(function* (this: UserInRolesStore) {
    yield this.LoadUsersByRoleId();
    yield this.LoadUsers();
  });

  public LoadUsersByRoleId = flow(function* (this: UserInRolesStore) {
    this.isLoading = true;

    const response: any = yield this.UserInRolesService.GetUsersByRoleId(1);

    if (response) {
      this.UsersByRole = response;
      console.log("this.UsersByRole", toJS(this.UsersByRole));
    }

    this.isLoading = false;
  });

  public LoadUsers = flow(function* (this: UserInRolesStore) {
    this.isLoading = true;

    const response: any = yield this.UserInRolesService.GetUsersFromUserInRolesTable();

    if (response) {
      this.Users = response;
    }

    this.isLoading = false;
  });

  public SaveUsers = flow(function* (this: UserInRolesStore) {
    this.isLoading = true;
    this.SelectedUsers = this.SelectedUsers.map((u) => {
      return {
        ...u,
        Id: null,
        RoleId: this.SelectedRole,
      };
    });

    const id = yield this.UserInRolesService.SaveUsers(this.SelectedUsers);
    console.log("resp", id);
    if (id > -1) {
      yield this.LoadUsersByRoleId();
      yield this.LoadUsers();
      this.OpenNewUserDialog();
    }

    this.isLoading = false;
  });

  public DeleteUser = flow(function* (this: UserInRolesStore) {
    this.isLoading = true;

    const response: number = yield this.UserInRolesService.DeleteUser(this.idOfUserForDelete);
    if (response) {
      yield this.LoadUsersByRoleId();
      yield this.LoadUsers();
    }
    this.CancelDeleteUser();

    this.isLoading = false;
  });

  @action CancelDeleteUser() {
    this.warningMessageOfDelete = null;
    this.idOfUserForDelete = 0;
  }

  @action BeforeDeleteUser(userId: number) {
    console.log("userId", userId);

    const user = this.Users.find((u) => u.Id === userId);
    console.log("user", toJS(user));

    let userName = null;
    if (user) {
      userName = user.Name;
    }
    this.idOfUserForDelete = userId;
    if (userName) {
      this.warningMessageOfDelete = `Biztos, hogy törlöd ${userName} felhasználót?`;
    }
  }

  @action OpenNewUserDialog() {
    this.openNewUserDialog = !this.openNewUserDialog;
    if (this.openNewUserDialog) {
      this.SelectedUsers = [];
    }
  }

  @action UpdateSelectedRoleField(newValue: any) {
    this.SelectedRole = newValue;
  }

  @computed get GetRoles() {
    if (this.RootStore.CurrentUser.IsSuperAdmin) {
      return [
        { key: 1, text: "Admin" },
        { key: 4, text: "Szuperadmin" },
      ];
    } else if (this.RootStore.CurrentUser.IsAdmin) {
      return [{ key: 1, text: "Admin" }];
    } else {
      return [];
    }

    // return this.Users.map((u) => {
    //   return { key: u.RoleId, text: u.RoleName };
    // });
  }

  @action OnChangeNewUsers(items) {
    console.log("items", items);

    this.SelectedUsers = items.map((i) => {
      return {
        ...i,
        ADUserId: i.ADUserId,
        RoleId: this.SelectedRole,
      };
    });
  }
}

import { mode } from "d3";
import { action, computed, flow, observable } from "mobx";
import { LoginService } from "../../modules/Admin/Login/core/LoginService";
import { RoutesObj } from "../contstants/Routes";
import { IConfiguration } from "../models/IConfiguration";
import { IUser } from "../models/IUser";
import { RouterStore } from "./RouterStore";

export class RootStore {
  RouterStore: RouterStore;
  @observable UserName: string = null;
  @observable Password: string = null;

  @observable IsLoading: boolean = true;
  @observable CurrentUser: IUser = null;
  @observable Configuration: IConfiguration = null;
  @observable isUserAuthed: boolean = false;

  LoginService: LoginService;
  @observable loginFailure: boolean= false;
  @observable loginFailMessage: string;


  constructor() {
    this.RouterStore = new RouterStore(this);
    this.LoginService = new LoginService();

  }

  Init = flow(function* (this: RootStore) {
    this.IsLoading = true;

    yield this.GetConfiguration();
    yield this.RouterStore.Init();

    yield this.GetCurrentUser();
    console.log(this.CurrentUser);
    if (this.Configuration.Mode == "Parking") {
      if (this.CurrentUser != null) {
        // this.RouterStore.gotToRoute(RoutesObj.Login.id, null, null, false);
        // let url = `${window.location.origin}${window.location.pathname}#\ParkingReservation`;
        // window.location.href = url;
        this.isUserAuthed = true;
      } else {
        let url = `${window.location.origin}${window.location.pathname}#\Login`;
        window.location.href = url;
      }
    }else{
      this.isUserAuthed = true;

    }

    this.IsLoading = false;
  });

  login = flow(function* (this: RootStore) {
    // this.addloading('login');
    var result: string = yield this.LoginService.Login(this.UserName, this.Password)



    if (result == "success") {
      this.isUserAuthed = true;

      const response = yield this.GetCurrentUser();
      // this.CurrentUser = response;

      this.GoParkingReservation(false);

      // 	this.selectedStore = this.currentUser.StoreCode;
      // 	this.navigate('/home')
      // 	console.log('User authorized:', this.isUserAuthed)
      // 	console.log(result)
      // 	console.log(this.selectedStore)
      // 	this.removeLoading('login');

      }
      else if (result == "failure") {
      	this.loginFailure = true;
      	this.loginFailMessage = "Hibás felhasználónév vagy jelszó!";
      	console.log('failed to login')
      }
      else if (result == "lockedOut") {
      	this.loginFailure = true;
      	this.loginFailMessage = "Felhasználó letiltva!";
      	console.log('failed to login')
      }
    
  });
  public isMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
  logOut = flow(function* (this: RootStore) {
    var result: string = yield this.LoginService.logOut()
    if (result) {
      // this.currentUser = null;
    }
    
    window.location.href = "/";
  });

  @action onChangeUserName = (event: any, data: any) => {
    this.UserName = data
    console.log(this.UserName)
  }
  @action onChangePassword = (event: any, data: any) => {
    this.Password = data;
    console.log(this.Password)
  }
  @action getFormattedDate(d: Date): string {
    const dateString = d.toLocaleDateString("hu-HU", { year: "numeric", month: "numeric", day: "numeric" }).replace(/ /g, "");
    const datetimeString = `${dateString}`;

    return datetimeString;
  }

  GoTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  CanGoBack() {
    return this.RouterStore.history.length > 1;
  }

  GoBack = () => {
    this.RouterStore.history.goBack();
    this.RouterStore.setCustomTitle(null);
  };

  // GoHome = (newTab: boolean = false, refresh: boolean = false) => {
  //   this.RouterStore.gotToRoute(RoutesObj.Home.id, null, null, newTab);

  //   if (newTab == false && refresh) {
  //     window.location.reload();
  //   }
  // };

  GoToAdminPage = (newTab: boolean = false) => {
    console.log(RoutesObj);
    this.RouterStore.gotToRoute(RoutesObj.Admin.id, null, null, newTab);
  };

  GoParkingReservation = (newTab: boolean = false) => {
    console.log(RoutesObj);
    this.RouterStore.gotToRoute(RoutesObj.ParkingReservation.id, null, null, newTab);
  };
  GoChangePassword = (newTab: boolean = false) => {
    console.log(RoutesObj);
    this.RouterStore.gotToRoute(RoutesObj.ChangePassword.id, null, null, newTab);
  };
  GoToTableReservation = (newTab: boolean = false) => {
    console.log(RoutesObj);
    this.RouterStore.gotToRoute(RoutesObj.TableReservation.id, null, null, newTab);
  };

  GoParkingResource = (newTab: boolean = false) => {
    console.log(RoutesObj);
    this.RouterStore.gotToRoute(RoutesObj.Admin.childrends.Resource.id, { id: 1 }, [{ field: "role", value: "teszt" }], newTab);
  };

  GoToDepartments = (newTab: boolean = false) => {
    console.log(RoutesObj);
    this.RouterStore.gotToRoute(RoutesObj.Admin.childrends.Departments.id, null, null, newTab);
  };

  GoToUserInRoles = (newTab: boolean = false) => {
    console.log(RoutesObj);
    this.RouterStore.gotToRoute(RoutesObj.Admin.childrends.UserInRoles.id, null, null, newTab);
  };


  GetConfiguration = flow(function* (this: RootStore) {
    try {
      const options: RequestInit = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      };

      const request = yield fetch(`${process.env.SERVER_URL}/api/common/GetConfiguration`, options);
      const data = yield request.json();
      this.Configuration = data;
    } catch (e) {
      console.error(e);
    }
  });

  GetCurrentUser = flow(function* (this: RootStore) {
    try {
      const options: RequestInit = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      };

      const request = yield fetch(`${process.env.SERVER_URL}/api/user/GetCurrentUser`, options);
      const data = yield request.json();

      this.CurrentUser = data;
    } catch (e) {
      console.error(e);
    }
  });

  public async GetUserByNameOrEmail(text) {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    const request = await fetch(`${process.env.SERVER_URL}/api/user/GetUserByQueryText?queryText=${encodeURI(text)}`, options);
    const result = await request.json();

    return result.map((user) => {
      return {
        ...user,
        primaryText: user.Name,
        secondaryText: user.LoginName,
      };
    });
  }

  SameDay(date1: Date, date2: Date) {
    if (!date1 || !date2) return null;
    return date1.getFullYear() == date2.getFullYear() && date1.getMonth() == date2.getMonth() && date1.getDate() == date2.getDate();
  }

  @computed get IsAdmin() {
    if (!this.CurrentUser) return false;

    return this.CurrentUser.IsAdmin;
  }

  @computed get IsLeader() {
    if (!this.CurrentUser) return false;

    return this.CurrentUser.IsLeader;
  }

  @computed get GetPermissions() {
    return {
      View: {
         [RoutesObj.ChangePassword.id]: !(this.IsAdmin || this.IsLeader),
        [RoutesObj.Admin.id]: this.IsAdmin || this.IsLeader,
        [RoutesObj.Admin.childrends.AdminTableReservation.id]: this.IsAdmin,
        [RoutesObj.Admin.childrends.Resource.id]: this.IsAdmin,
        [RoutesObj.Admin.childrends.UserInRoles.id]: this.IsAdmin,
        [RoutesObj.Admin.childrends.Departments.id]: this.IsAdmin || this.IsLeader,
        [RoutesObj.Admin.childrends.ChangeLog.id]: this.IsAdmin,
      },
      CreateNew: {},
      CanEdit: {},
    };
  }
}

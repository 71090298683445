import { IDepartment } from "../../../../../core/models/IDepartment";

export class DepartmentService {
  public async GetDepartments(): Promise<IDepartment[]> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    const request = await fetch(`${process.env.SERVER_URL}/api/department/GetDepartments`, options);
    return request.json();
  }

  public async GetDepartmentById(
    id: number,
    startDate: Date,
    endDate: Date,
    isTablesNecessary: boolean,
    isUsersNecessary: boolean,
    isReservationsNecessary: boolean
  ): Promise<IDepartment> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    const request = await fetch(
      `${
        process.env.SERVER_URL
      }/api/department/GetDepartmentById?id=${id}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&isTablesNecessary=${isTablesNecessary}&isUsersNecessary=${isUsersNecessary}&isReservationsNecessary=${isReservationsNecessary}`,
      options
    );
    return request.json();
  }

  public async SaveDepartment(department: IDepartment): Promise<number> {
    const options: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(department),
    };

    let returnValue = await fetch(`${process.env.SERVER_URL}/api/department/SaveDepartment`, options);
    return returnValue.json();
  }

  public async UploadAttachment(id: number, file: File): Promise<number> {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("departmentId", id.toString());

      const requestHeaders: Headers = new Headers();
      //  requestHeaders.append("Content-type", "multipart/form-data");

      const httpClientOptions: RequestInit = {
        headers: requestHeaders,
        credentials: "include",
        method: "POST",
        body: formData,
      };

      const requestURL = await fetch(`${process.env.SERVER_URL}/api/department/Upload`, httpClientOptions);

      return await requestURL.json();
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

import { AdminPage } from "../../modules/Admin/AdminPage";
import { ChangeLog } from "../../modules/Admin/ChangeLog/components/ChangeLog";
import { DepartmentPage } from "../../modules/Admin/Department/DepartmentPage";
import { LoginPage } from "../../modules/Admin/Login/LoginPage";
import { ResourcePage } from "../../modules/Admin/Resource/ResourcePage";
import { AdminTableReservationPage } from "../../modules/Admin/TableReservation/AdminTableReservationPage";
import { UserInRolesPage } from "../../modules/Admin/UserInRoles/UserInRolesPage";
import { UsersPage } from "../../modules/Admin/Users/UsersPage";
import { HomePage } from "../../modules/Home/HomePage";
import { ParkingReservationPage } from "../../modules/ParkingReservation/ParkingReservationPage";
import { TableReservationPage } from "../../modules/TableReservation/TableReservationPage";
import { IRoute, IRouteBase, IRouteObj } from "../models/IRoute";

export const RoutesObj = {
  // Home: {
  //   id: "home",
  //   path: "/",
  //   title: "Kezdőlap",
  //   exact: true,
  //   component: HomePage,
  // },
  Home: {
    id: "Home",
    path: "/",
    title: "Főoldal",
    exact: true,
    hiddenFromMenu: true,
    mode: "Both",
    component: ParkingReservationPage,
  },
  ParkingReservation: {
    id: "ParkingReservation",
    path: "/ParkingReservation",
    title: "Parkolóhely foglalása",
    exact: true,
    mode: "Parking",
    component: ParkingReservationPage,
  },
  TableReservation: {
    id: "TableReservation",
    path: "/TableReservation",
    title: "Ülőhely foglalása",
    exact: true,
    mode: "Table",
    component: TableReservationPage,
  },
  // ParkingReservationById: {
  //   id: "ParkingReservationById",
  //   path: "/ParkingReservation/:id",
  //   title: "Parkolóhelyek",
  //   exact: true,
  //   component: ParkingReservationPage,
  // },
  Login: {
    id: "Login",
    path: "/Login",
    title: "Login",
    exact: true,
    mode: "Parking",
    component:LoginPage,
    hiddenFromMenu: true,

  },
  ChangePassword: {
    id: "ChangePassword",
    path: "/Admin/Users?changePassword=true",
    title: "Jelszó változtatás",
    exact: true,
    mode: "Parking",
    component:UsersPage,
    

  },
  Admin: {
    id: "Admin",
    path: "/Admin",
    title: "Admin",
    exact: true,
    mode: "Both",
    component: AdminPage,
    childrends: {
      AdminTableReservation: {
        id: "AdminTableReservation",
        path: "/AdminTableReservation",
        title: "Ülőhelyek beállításai",
        exact: true,
        mode: "Table",
        component: AdminTableReservationPage,
      },
      Resource: {
        id: "Resource",
        path: "/Admin/Resource",
        title: "Parkolóhely felvétele",
        exact: true,
        mode: "Parking",
        component: ResourcePage,
      },
      Departments: {
        id: "Departments",
        path: "/Admin/Departments",
        title: "Foglalási egységek kezelése",
        exact: true,
        mode: "Table",
        component: DepartmentPage,
      },
      UserInRoles: {
        id: "UserInRoles",
        path: "/Admin/UserInRoles",
        title: "Admin szerepkör kezelése",
        exact: true,
        mode: "Both",
        component: UserInRolesPage,
      },
      Users: {
        id: "Users",
        path: "/Admin/Users",
        title: "Felhasználók kezelése",
        exact: true,
        mode: "Parking",
        component: UsersPage,
      },
      ChangeLog: {
        id: "ChangeLog",
        path: "/Admin/ChangeLog",
        title: "Változásnapló",
        exact: true,
        mode: "Both",
        component: ChangeLog,
      },
    },
  },
  NoMatch: {
    id: "noMatch",
    path: "*",
    title: "Foglaló",
    exact: false,
    hiddenFromMenu: true,
    component: ParkingReservationPage,
  },
};

const RouteObj2Route = (routeObj: Record<any, IRouteObj>): IRoute[] => {
  let routes: IRoute[] = [];
  for (let key in routeObj) {
    routes.push({ ...routeObj[key], childrends: RouteObj2Route(routeObj[key].childrends) });
  }
  return routes;
};

let Routes: IRoute[] = RouteObj2Route(RoutesObj);

export default Routes;

export class UserInRolesService {
  public async GetUsersByRoleId(id: number): Promise<any> {
    try {
      const options: RequestInit = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      };

      const request = await fetch(`${process.env.SERVER_URL}/api/user/GetUsersByRoleId?roleId=${id}`, options);

      return Promise.resolve(await request.json());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async GetUsersFromUserInRolesTable(): Promise<any> {
    try {
      const options: RequestInit = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      };

      const request = await fetch(`${process.env.SERVER_URL}/api/user/GetUsersFromUserInRolesTable?`, options);

      return Promise.resolve(await request.json());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async GetUserByNameOrEmail(text) {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    const request = await fetch(`${process.env.SERVER_URL}/api/user/GetUserByQueryText?queryText=${encodeURI(text)}`, options);
    const result = await request.json();

    // return Promise.resolve(await request.json());

    return result.map((user) => {
      return {
        ...user,
        primaryText: user.Name,
        secondaryText: user.LoginName,
      };
    });
  }

  public async SaveUsers(users): Promise<any> {
    try {
      const options: RequestInit = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(users),
      };

      const request = await fetch(`${process.env.SERVER_URL}/api/user/SaveUsers`, options);
      return Promise.resolve(await request.json());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async DeleteUser(userId: number): Promise<any> {
    try {
      const options: RequestInit = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      };

      const request = await fetch(`${process.env.SERVER_URL}/api/user/DeleteUser?userId=${userId}`, options);
      return Promise.resolve(await request.json());
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

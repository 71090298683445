import { inject, observer } from 'mobx-react';
import { ButtonType, PrimaryButton, TextField } from 'office-ui-fabric-react';
import React = require('react');
// import React from 'react';
// import { NavigateFunction } from 'react-router-dom';
import { Button, Grid, Image, Input } from 'semantic-ui-react';
import { RootStore } from '../../../core/stores/RootStore';
// import logo from '../../assets/logo.png';
// import withNavigation from '../../hocs/withNavigation';
// import { RootStore } from '../../stores/RootStore';

interface ILoginPageProps {
    RootStore?: RootStore;
}
@inject("RootStore")
@observer
export class LoginPage extends React.Component<ILoginPageProps> {
    private logo: string = String(require("../../../assets/mm-logo.svg"));
    constructor(props: any) {
        super(props);
    }
    
    
   
    public render() {
        const handleKeypress = e => {
            //it triggers by pressing the enter key
            console.log(e.key);
                if (e.key === 'Enter') {
                    this.props.RootStore.login()
                }
        };
        // React.useEffect(() => {
        //     const keyDownHandler = event => {
        //       console.log('User pressed: ', event.key);
        
        //       if (event.key === 'Enter') {
        //         event.preventDefault();
        
        //         this.props.RootStore.login()
        //       }
        //     };
        
        //     document.addEventListener('keydown', keyDownHandler);
        
        //     return () => {
        //       document.removeEventListener('keydown', keyDownHandler);
        //     };
        //   }, []);
        return (
            <div>
                <div style={{ marginTop: '100px' }}>
                    <table style={{ width: '50%', marginRight: 'auto', marginLeft: 'auto' }}>
                        <tr style={{ marginTop: 100 }}>
                            <td >
                                <div>
                                    <Image src={this.logo} size="medium"  />
                                </div>
                            </td>
                        </tr>
                        <tr >
                            <table style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                                <tr >
                                    <td style={{ textAlign: 'center' }}>
                                        {/* <div>
											<label>Felhasználónév</label>
										// </div> */}
                                        <div className="inputContainer " style={{ 'width': '200px' }}>
                                            <TextField
                                                label="Felhasználónév"
                                                value={this.props.RootStore.UserName || ""}
                                                
                                                onKeyPress={event => {
                                                    console.log(event.key);
                                                    if (event.key === "Enter") {
                                                        this.props.RootStore.login()
                                                    }
                                                  }}
                                                onChanged={(newText) => {
                                                    this.props.RootStore.onChangeUserName("Name", newText);
                                                }}
                                            />
                                        </div>
                                        {/* <Input value={this.props.RootStore.UserName} onChange={(event, data) => { this.props.RootStore.onChangeUserName(event, data) }}></Input> */}
                                    </td>
                                </tr>
                                <tr >
                                    <td style={{ textAlign: 'center' }}>
                                        {/* <div>
											<label>Jelszó</label>
										</div>
										<Input value={this.props.RootStore.Password} onChange={(event, data) => { this.props.RootStore.onChangePassword(event, data) }} type='password'></Input>
										 */}
                                        <div className="inputContainer " style={{ 'width': '200px' }}>
                                            <TextField
                                            type='password'
                                                label="Jelszó"
                                                value={this.props.RootStore.Password || ""}
                                                onKeyPress={event => {
                                                    console.log(event.key);
                                                    if (event.key === "Enter") {
                                                        this.props.RootStore.login()
                                                    }
                                                  }}
                                                onChanged={(newText) => {
                                                    this.props.RootStore.onChangePassword("Name", newText);
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr >
                                    <td style={{ textAlign: 'center' }}>

                                        {this.props.RootStore.loginFailure &&
											<div style={{ color: 'rgb(255,40,0)' }}>
												{this.props.RootStore.loginFailMessage}
											</div>
										}

                                    </td>
                                </tr>
                                <tr >
                                    <td style={{ textAlign: 'center' }}>

                                        <PrimaryButton style={{ marginRight: "4px" }} className="ui button"  text="Belépés"
                                        // onKeyDown={handleKeypress}
                                       autoFocus={true}
                                          
                                        onClick={() => this.props.RootStore.login()} />

                                    </td>
                                </tr>
                            </table>
                        </tr>
                    </table>
                </div>
            </div>
        );
    }
}



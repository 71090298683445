import { observable, action, flow, toJS } from "mobx";
import "isomorphic-fetch";
import "url-search-params-polyfill";
import "url-polyfill";
import { ResourceService } from "../services/ResourceService";
import { IResource } from "../../../../../core/models/IResource";
import { RootStore } from "../../../../../core/stores/RootStore";

export class ResourceStore {
  public ResourceService: ResourceService = null;
  @observable showMenuItemByNumber: number = null;
  @observable oprenNewResourceDialog: boolean = false;

  @observable Users: Array<any> = [];

  @observable Resource: IResource = {
    Id: null,
    ResourceTypeId: null,
    ResourceTypeName: null,
    Name: null,
    Reservations: [],
    Users: [],
    OwnerADUserId: null,
    OwnerName: null,
    LicensePlateNumber: null,
  };

  @observable EditedOwnerUser: any = null;

  @observable Resources: IResource[] = [];
  @observable isLoading: boolean = false;

  //Messages
  @observable resourceErrorMessage: any[] = [];
  @observable saveErrorMessage: string = null;
  RootStore: RootStore = null;

  constructor(rootStore: RootStore) {
    this.ResourceService = new ResourceService();
    this.RootStore = rootStore;
  }

  Init = flow(function* (this: ResourceStore) {
    yield this.GetResources();
  });

  public GetResources = flow(function* (this: ResourceStore) {
    this.isLoading = true;
    const request = yield this.ResourceService.GetResources();
    if (request) {
      this.Resources = request;
    }
    console.log("GetResources", toJS(request));
    this.isLoading = false;
  });

  public GenerateReport = flow(function* (this: ResourceStore) {
    this.isLoading = true;
    yield this.ResourceService.GenerateReport();

    this.isLoading = false;
  });

  public GenerateResourceLogReport = flow(function* (this: ResourceStore) {
    this.isLoading = true;
    yield this.ResourceService.GenerateResourceLogReport();

    this.isLoading = false;
  });

  public GenerateEventLogReport = flow(function* (this: ResourceStore) {
    this.isLoading = true;
    yield this.ResourceService.GenerateEventLogReport();

    this.isLoading = false;
  });

  public async GetUserByNameOrEmail(text) {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    const request = await fetch(`${process.env.SERVER_URL}/api/user/GetUserByQueryText?queryText=${encodeURI(text)}`, options);
    const result = await request.json();

    return result.map((user) => {
      return {
        ...user,
        primaryText: user.Name,
        secondaryText: user.LoginName,
      };
    });
  }

  @action OnChangeParkingOwner(items: any) {
    console.log("items", items);

    this.Resource.Users = items;
    if (items.length === 0) {
      this.Resource.OwnerADUserId = null;
    }

    console.log("this.Resource.Users ", toJS(this.Resource.Users));
  }

  /**
   * Asztal/Parkoló dropdown értékek lekérése
   */

  public removeResource= flow(function* (this: ResourceStore,resId:number) {
   
    const item = this.Resources.find((r) => r.Id === resId);
    if (confirm('Biztosan törölni szeretnéd ezt a parkolót?')) {
      
      console.log("RemoveResource", toJS(this.Resource));
      this.isLoading = true;
      const response = yield this.ResourceService.RemoveResource(item);
      if (response.ErrorCode != -1) {
        this.CancelResource();
        this.GetResources();
      } else {
        this.saveErrorMessage = response.ErrorMessage;
      }

      this.isLoading = false;
    } else {
     return;
    }
  });
  public SaveResource = flow(function* (this: ResourceStore) {
    this.resourceErrorMessage = [];
    this.saveErrorMessage = null;
    if (!this.Resource.Name) {
      this.resourceErrorMessage.push({ key: "Name", text: "'Megnevezés' mező kitöltése kötelező" });
    }
    console.log("SAVE", toJS(this.Resource));

    if (this.Resource.LicensePlateNumber && this.Resource.Users.length === 0) {
      this.resourceErrorMessage.push({ key: "LicensePlateNumber", text: "Rendszám csak olyan parkolóhoz adható meg, ahol van Tulajdonos" });
    }

    if (this.resourceErrorMessage.length != 0) {
      return;
    } else {
      this.Resource.LicensePlateNumber = this.Resource.LicensePlateNumber ? this.Resource.LicensePlateNumber.toUpperCase() : null;
      console.log("SaveResource", toJS(this.Resource));
      this.isLoading = true;
      const response = yield this.ResourceService.SaveResource(this.Resource);
      if (response.ErrorCode != -1) {
        this.OpenResourceDialog(null);
        this.CancelResource();
        this.GetResources();
      } else {
        this.saveErrorMessage = response.ErrorMessage;
      }

      this.isLoading = false;
    }
  });

  @action CancelResource() {
    if (!this.Resource.Id) {
      this.Resource.Name = null;
      this.resourceErrorMessage = [];
      this.Resource.Users = [];
    } else {
      if (this.Resource.Users.length == 0) {
        this.Resource.OwnerADUserId = this.EditedOwnerUser && this.EditedOwnerUser[0].OwnerADUserId ? this.EditedOwnerUser[0].OwnerADUserId : null;
        this.Resource.OwnerName = this.EditedOwnerUser && this.EditedOwnerUser[0].OwnerName ? this.EditedOwnerUser[0].OwnerName : null;
      }

      this.Resource.Users = [];
    }
  }

  /**
   * Új erőforrás mezőinek módosító függvény
   */
  @action UpdateResourceFieldByKey(key: any, newValue: any) {
    this.Resource = { ...this.Resource, [key]: newValue };
    if (this.resourceErrorMessage.length != 0) {
      let index = this.resourceErrorMessage.findIndex((msg) => msg.key === key);
      if (index > -1) {
        this.resourceErrorMessage.splice(index, 1);
      }
    }
  }

  @action OpenResourceDialog(resId: number) {
    this.oprenNewResourceDialog = !this.oprenNewResourceDialog;
    this.saveErrorMessage = null;

    if (resId) {
      const item = this.Resources.find((r) => r.Id === resId);
      console.log("item", item);

      if (item) {
        this.Resource = item;
        if (item.OwnerADUserId) {
          this.EditedOwnerUser = [
            {
              ADUserId: item.OwnerADUserId,
              Name: item.OwnerName,
              primaryText: item.OwnerName,
              secondaryText: item.OwnerName,
              OwnerADUserId: item.OwnerADUserId,
              OwnerName: item.OwnerName,
            } as any,
          ];
          this.Resource.Users = [
            {
              ADUserId: item.OwnerADUserId,
              Name: item.OwnerName,
              OwnerADUserId: item.OwnerADUserId,
              OwnerName: item.OwnerName,
              primaryText: item.OwnerName,
              secondaryText: item.OwnerName,
            } as any,
          ];
        }
      }
    } else {
      this.Resource = {
        Id: null,
        OwnerName: null,
        Name: null,
        OwnerADUserId: null,
        Reservations: [],
        ResourceTypeId: null,
        ResourceTypeName: null,
        Users: [],
        LicensePlateNumber: null,
      };
    }
    console.log("OpenResourceDialog", toJS(this.Resource));
  }
}

import * as React from "react";
import { observer, inject } from "mobx-react";
import "./Users.scss";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDropdownOption,
  Label,
  MessageBar,
  MessageBarType,
  NormalPeoplePicker,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TextField,
} from "office-ui-fabric-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import { UsersStore } from "../core/stores/UsersStore";
import { toJS } from "mobx";
import { Icon, Message } from "semantic-ui-react";

interface IUsersProps {
  UsersStore?: UsersStore;
}

@inject("UsersStore")
@observer
export class Users extends React.Component<IUsersProps, {}> {
  constructor(props: IUsersProps) {
    super(props);
  }

  public render() {
    if (this.props.UsersStore.isLoading) return <Spinner size={SpinnerSize.medium} label="Betöltés..."></Spinner>;
    const iconBin = <FontAwesomeIcon style={{ height: "16px", width: "16px", position: "relative", cursor: "pointer" }} icon={faTrashAlt} />;
    const editIcon = <FontAwesomeIcon style={{ height: "15px", width: "15px", position: "relative", cursor: "pointer" }} icon={faPencilAlt} />;

    return (
      <div className="commonComponent ">
        <div className="mainHeader">Felhasználók kezelése</div>
        <div style={{ margin: "12px" }}>
          <PrimaryButton className="ui button" text="Új felhasználó felvétele" onClick={() => this.props.UsersStore.OpenNewUserDialog(null)} />
        </div>
        <table className="userTable">
          <tbody>
            <tr>
              <td>Név</td>
              <td>E-mail</td>
              <td>Szerkesztés</td>
            </tr>

            {this.props.UsersStore.Users &&
              this.props.UsersStore.Users.map((u, i) => {
                return (
                  <tr key={i}>
                    <td>{u.Name}</td>
                    <td>{u.Email}</td>
                    <td>
                      <span onClick={() => this.props.UsersStore.OpenNewUserDialog(u.Id)}>{editIcon}</span>


                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {this.renderNewUserDialog()}
        {this.renderWarningDialog()}
      </div>
    );
  }

  public renderNewUserDialog() {
    const eyeIcon = <FontAwesomeIcon style={{ height: "15px", width: "15px", position: "relative", cursor: "pointer" }} icon={faEye} />;
    const eyeSlashIcon = <FontAwesomeIcon style={{ height: "15px", width: "15px", position: "relative", cursor: "pointer" }} icon={faEyeSlash} />;
    if (!this.props.UsersStore.openNewUserDialog) return null;
    return (

      <Dialog
        hidden={false}
        onDismiss={() => {
          this.props.UsersStore.OpenNewUserDialog(null);
        }}
        className="carReservationDialog"
        dialogContentProps={{
          type: DialogType.normal,
          title: "Felhasználó",
        }}
      >
       {this.renderError()}
        {/* {this.renderError()}
        {this.renderSaveError()} */}
        <div className="inputContainer ">
          <TextField
            disabled={this.props.UsersStore.ShowNewPassword}
            label="Név"
            value={this.props.UsersStore.SelectedUser.Name || ""}
            onChanged={(newText) => {
              this.props.UsersStore.UpdateUserFieldByKey("Name", newText);
            }}
          />
        </div>

        <div className="inputContainer ">
          <div >
            <TextField
              disabled={true}
              label="Email"
              // maxLength={12}

              value={this.props.UsersStore.SelectedUser.Email || ""}
              onChanged={(newText) => {
                this.props.UsersStore.UpdateUserFieldByKey("Email", newText);
              }}
            />
          </div>

        </div>
        <div className="inputContainer ">
          <div >
            <TextField
              disabled={this.props.UsersStore.SelectedUser.Id && !this.props.UsersStore.RootStore.CurrentUser.IsSuperAdmin}
              label="Felhasználó név"
              // maxLength={12}
              value={this.props.UsersStore.SelectedUser.LoginName}
              onChanged={(newText) => {
                this.props.UsersStore.UpdateUserFieldByKey("LoginName", newText);
                this.props.UsersStore.SelectedUser.Email=newText+"@media-saturn.com";
              }}
            />
          </div>
        </div>

        {(this.props.UsersStore.ShowNewPassword || !this.props.UsersStore.SelectedUser.Id) && <div>
          <table>
            <tr>
              <td>
                <div className="inputContainer ">
                  <div >
                    <table>
                      <tr>
                        <td>
                          <TextField
                            type={this.props.UsersStore.showNewPassword ? "text" : "password"}
                            label="Jelszó"
                            // maxLength={12}


                            value={this.props.UsersStore.SelectedUser.Password}
                            onChanged={(newText) => {
                              this.props.UsersStore.UpdateUserFieldByKey("NewPassword", newText);
                            }}
                          />
                        </td>
                        <td>
                          {!this.props.UsersStore.showNewPassword && (
                            <span style={{ position: 'relative',top: '15px', marginLeft: '8px', cursor: 'pointer' }} onClick={() => this.props.UsersStore.setShowNewPassword()}>{eyeIcon}</span>
                          )}
                          {this.props.UsersStore.showNewPassword && (
                            <span style={{ position: 'relative',top: '15px', marginLeft: '8px', cursor: 'pointer' }} onClick={() => this.props.UsersStore.setShowNewPassword()}>{eyeSlashIcon}</span>
                          )}
                        </td>
                      </tr>
                    </table>


                  </div>
                </div>
              </td>
              <td>
                <div className="inputContainer ">
                  <div >
                    <table>
                      <tr>
                        <td>
                          <TextField
                            type={this.props.UsersStore.showNewPasswordConfirm ? "text" : "password"}
                            label="Jelszó újra"
                            // maxLength={12}
                            value={this.props.UsersStore.SelectedUser.ConfirmPassword}
                            onChanged={(newText) => {
                              this.props.UsersStore.UpdateUserFieldByKey("ConfirmPassword", newText);
                            }}
                          />
                        </td>
                        <td>
                          {!this.props.UsersStore.showNewPasswordConfirm && (

                            <span style={{ position: 'relative',top: '15px', marginLeft: '8px', cursor: 'pointer' }} onClick={() => this.props.UsersStore.setShowNewPasswordConfirm()}>{eyeIcon}</span>

                          )}
                          {this.props.UsersStore.showNewPasswordConfirm && (
                            <span style={{ position: 'relative',top: '15px', marginLeft: '8px', cursor: 'pointer' }} onClick={() => this.props.UsersStore.setShowNewPasswordConfirm()}>{eyeSlashIcon}</span>

                          )}
                        </td>
                      </tr>
                    </table>


                  </div>

                </div>
              </td>
            </tr>
          </table>
          {(!this.props.UsersStore.checkCorrectFormNewPassword || this.props.UsersStore.showNotSamePasswordMessage) && (
            <Message negative={true}>
              <Message.Header>A jelszó nem megfelelő!</Message.Header>
              <div style={{ paddingLeft: '10px', paddingTop: '10px' }}>
                <ul>
                  {!this.props.UsersStore.checkCorrectFormNewPassword && <li>Minimum 8 karakter hosszúságú</li>}
                  {!this.props.UsersStore.checkCorrectFormNewPassword && <li>Tartalmaz kis- és nagybetűt is</li>}
                  {!this.props.UsersStore.checkCorrectFormNewPassword && <li>Tartalmaz számot</li>}
                  {/* {!this.props.UsersStore.checkCorrectFormNewPassword && <li>Tartalmaz legalább egy különleges karaktert</li>} */}
                  {this.props.UsersStore.showNotSamePasswordMessage && <li>A két jelszó nem egyezik meg</li>}
                </ul>
              </div>
            </Message>
          )}

        </div>
        }



        <div style={{ margin: "24px", textAlign: "center" }}>
          {!this.props.UsersStore.ShowNewPassword &&!this.props.UsersStore.SelectedUser.Id && <PrimaryButton style={{ marginRight: "4px" }} className="ui button" text="Mentés" onClick={() => this.props.UsersStore.CreateUser()} />}
          {!this.props.UsersStore.ShowNewPassword && this.props.UsersStore.SelectedUser.Id&& <PrimaryButton style={{ marginRight: "4px" }} className="ui button" text="Mentés" onClick={() => this.props.UsersStore.SaveUser()} />}
          {this.props.UsersStore.SelectedUser.Id && !this.props.UsersStore.ShowNewPassword && <PrimaryButton style={{ marginRight: "4px" }} className="ui button" text="Jelszó megváltoztatás" onClick={() => this.props.UsersStore.NewPassword()} />}
          {this.props.UsersStore.SelectedUser.Id && !this.props.UsersStore.ShowNewPassword && !this.props.UsersStore.SelectedUser.Disabled &&  <PrimaryButton style={{ marginRight: "4px" }} className="ui button" text="Letiltás" onClick={() => this.props.UsersStore.DisableUser()} />}
          {this.props.UsersStore.SelectedUser.Id && !this.props.UsersStore.ShowNewPassword && this.props.UsersStore.SelectedUser.Disabled && <PrimaryButton style={{ marginRight: "4px" }} className="ui button" text="Feloldás" onClick={() => this.props.UsersStore.EnableUser()} />}
          {this.props.UsersStore.SelectedUser.Id && !this.props.UsersStore.ShowNewPassword  && 
          
          <DefaultButton
          onClick={() => this.props.UsersStore.RemoveUser()}
													styles={{
														root: {
															backgroundColor: 'rgb(255,40,0)',
															color: '#fff',
														}
													}}
													text="Törlés"
												/>
          
          // <PrimaryButton style={{ marginRight: "4px" }} className="ui button" text="Törlés" styles={} onClick={() => this.props.UsersStore.RemoveUser()} />
          }

          
          
          
          
          {this.props.UsersStore.SelectedUser.Id && this.props.UsersStore.ShowNewPassword && <PrimaryButton style={{ marginRight: "4px" }} className="ui button" text="Jelszó mentése" onClick={() => this.props.UsersStore.SaveNewPassword()} />}
          <DefaultButton
            style={{ marginLeft: "4px" }}
            className="ui button"
            text="Mégse"
            onClick={() => {
              this.props.UsersStore.OpenNewUserDialog(null);
            }}
          />
          {/* onClick={() => this.props.ResourceStore.SaveResource()} */}
          {/* onClick={() => {
               this.props.UsersStore.OpenNewUserDialog(null);
              this.props.ResourceStore.OpenResourceDialog(null);
            }} */}
        </div>
      </Dialog>
    );
  }
  public GetRole(roleId: number) {
    if (roleId === 1) {
      return "Admin";
    } else if (roleId === 4) {
      return "Szuperadmin";
    } else {
      return "Ismeretlen";
    }
  }
  public renderError() {
    if (this.props.UsersStore.resourceErrorMessage.length != 0) {
      return (
        <MessageBar messageBarType={MessageBarType.error}>
          <ul>
            {this.props.UsersStore.resourceErrorMessage.map((e, i) => {
              return (
                <li key={i} style={{ textAlign: "left" }}>
                  {e.text}
                </li>
              );
            })}
          </ul>
        </MessageBar>
      );
    } else {
      return null;
    }
  }
  public renderWarningDialog() {
    if (!this.props.UsersStore.warningMessageOfDelete) return null;

    return (
      <div style={{ margin: "12px" }}>
        <Dialog
          hidden={false}
          onDismiss={() => {
            this.props.UsersStore.CancelDeleteUser();
          }}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Figyelmeztetés",
            subText: "",
          }}
        >
        

          <div>{this.props.UsersStore.warningMessageOfDelete}</div>
          <DialogFooter>
            <PrimaryButton text="Törlés" className="ui button" onClick={() => this.props.UsersStore.DeleteUser()} />
            <DefaultButton text="Mégse" className="ui button" onClick={() => this.props.UsersStore.CancelDeleteUser()} />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }

  // public renderNewUserDialog() {
  //   if (!this.props.UsersStore.openNewUserDialog) return null;

  //   return (
  //     <div style={{ margin: "12px" }}>
  //       <Dialog
  //         hidden={false}
  //         onDismiss={() => {
  //           this.props.UsersStore.OpenNewUserDialog();
  //         }}
  //         dialogContentProps={{
  //           type: DialogType.normal,
  //           title: " Új felhasználó felvétele",
  //           subText: "",
  //         }}
  //       >
  //         <div>
  //           <Label>Felhasználó(k)</Label>
  //           <NormalPeoplePicker
  //             selectedItems={this.props.UsersStore.SelectedUsers}
  //             resolveDelay={300}
  //             onChange={(items: any) => {
  //               this.props.UsersStore.OnChangeNewUsers(items);
  //             }}
  //             onResolveSuggestions={async (text) => {
  //               let searchResponse = await this.props.UsersStore.UsersService.GetUserByNameOrEmail(text);
  //               searchResponse = searchResponse.filter((item) => {
  //                 console.log(toJS(this.props.UsersStore.SelectedUsers));

  //                 return !this.props.UsersStore.SelectedUsers.some((u) => u.ADUserId === item.ADUserId);
  //               }) as any;
  //               return searchResponse;
  //             }}
  //             pickerSuggestionsProps={{
  //               noResultsFoundText: "Nincs a keresésnek megfelelő találat.",
  //               loadingText: "Keresés...",
  //               showRemoveButtons: true,
  //             }}
  //           />
  //         </div>
  //         <div>
  //           <Dropdown
  //             label="Szerepkör"
  //             options={this.props.UsersStore.GetRoles}
  //             selectedKey={this.props.UsersStore.SelectedRole || ""}
  //             onChanged={(option: IDropdownOption) => {
  //               this.props.UsersStore.UpdateSelectedRoleField(option.key);
  //             }}
  //           />
  //         </div>
  //         <DialogFooter>
  //           <PrimaryButton text="Mentés" className="ui button" onClick={() => this.props.UsersStore.SaveUsers()} />
  //         </DialogFooter>
  //       </Dialog>
  //     </div>
  //   );
  // }
}

import * as React from "react";
import { observer, inject } from "mobx-react";
import "./UserInRoles.scss";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDropdownOption,
  Label,
  MessageBar,
  NormalPeoplePicker,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { UserInRolesStore } from "../core/stores/UserInRolesStore";
import { toJS } from "mobx";

interface IUserInRolesProps {
  UserInRolesStore?: UserInRolesStore;
}

@inject("UserInRolesStore")
@observer
export class UserInRoles extends React.Component<IUserInRolesProps, {}> {
  constructor(props: IUserInRolesProps) {
    super(props);
  }

  public render() {
    if (this.props.UserInRolesStore.isLoading) return <Spinner size={SpinnerSize.medium} label="Betöltés..."></Spinner>;
    const iconBin = <FontAwesomeIcon style={{ height: "16px", width: "16px", position: "relative", cursor: "pointer" }} icon={faTrashAlt} />;
    return (
      <div className="commonComponent ">
        <div className="mainHeader">Adminok kezelése</div>
        <div style={{ margin: "12px" }}>
          <PrimaryButton className="ui button" text="Új admin felvétele" onClick={() => this.props.UserInRolesStore.OpenNewUserDialog()} />
        </div>
        <table className="userTable">
          <tbody>
            <tr>
              <td>Név</td>
              <td>Szerepkör</td>
              <td>Törlés</td>
            </tr>

            {this.props.UserInRolesStore.Users &&
              this.props.UserInRolesStore.Users.map((u, i) => {
                return (
                  <tr key={i}>
                    <td>{u.Name}</td>
                    <td>{this.GetRole(u.RoleId)}</td>
                    <td>
                      <span style={{ color: "red", marginRight: "6px" }}>{iconBin}</span>
                      <span className="deleteText" onClick={() => this.props.UserInRolesStore.BeforeDeleteUser(u.Id)}>
                        Törlés
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {this.renderNewUserDialog()}
        {this.renderWarningDialog()}
      </div>
    );
  }

  public GetRole(roleId: number) {
    if (roleId === 1) {
      return "Admin";
    } else if (roleId === 4) {
      return "Szuperadmin";
    } else {
      return "Ismeretlen";
    }
  }

  public renderWarningDialog() {
    if (!this.props.UserInRolesStore.warningMessageOfDelete) return null;

    return (
      <div style={{ margin: "12px" }}>
        <Dialog
          hidden={false}
          onDismiss={() => {
            this.props.UserInRolesStore.CancelDeleteUser();
          }}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Figyelmeztetés",
            subText: "",
          }}
        >
          <div>{this.props.UserInRolesStore.warningMessageOfDelete}</div>
          <DialogFooter>
            <PrimaryButton text="Törlés" className="ui button" onClick={() => this.props.UserInRolesStore.DeleteUser()} />
            <DefaultButton text="Mégse" className="ui button" onClick={() => this.props.UserInRolesStore.CancelDeleteUser()} />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }

  public renderNewUserDialog() {
    if (!this.props.UserInRolesStore.openNewUserDialog) return null;

    return (
      <div style={{ margin: "12px" }}>
        <Dialog
          hidden={false}
          onDismiss={() => {
            this.props.UserInRolesStore.OpenNewUserDialog();
          }}
          dialogContentProps={{
            type: DialogType.normal,
            title: " Új felhasználó felvétele",
            subText: "",
          }}
        >
          <div>
            <Label>Felhasználó(k)</Label>
            <NormalPeoplePicker
              selectedItems={this.props.UserInRolesStore.SelectedUsers}
              resolveDelay={300}
              onChange={(items: any) => {
                this.props.UserInRolesStore.OnChangeNewUsers(items);
              }}
              onResolveSuggestions={async (text) => {
                let searchResponse = await this.props.UserInRolesStore.UserInRolesService.GetUserByNameOrEmail(text);
                searchResponse = searchResponse.filter((item) => {
                  console.log(toJS(this.props.UserInRolesStore.SelectedUsers));

                  return !this.props.UserInRolesStore.SelectedUsers.some((u) => u.ADUserId === item.ADUserId);
                }) as any;
                return searchResponse;
              }}
              pickerSuggestionsProps={{
                noResultsFoundText: "Nincs a keresésnek megfelelő találat.",
                loadingText: "Keresés...",
                showRemoveButtons: true,
              }}
            />
          </div>
          <div>
            <Dropdown
              label="Szerepkör"
              options={this.props.UserInRolesStore.GetRoles}
              selectedKey={this.props.UserInRolesStore.SelectedRole || ""}
              onChanged={(option: IDropdownOption) => {
                this.props.UserInRolesStore.UpdateSelectedRoleField(option.key);
              }}
            />
          </div>
          <DialogFooter>
            <PrimaryButton text="Mentés" className="ui button" onClick={() => this.props.UserInRolesStore.SaveUsers()} />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}

import * as React from "react";
import "./LoaderOverlay.module.scss";

export type ILoaderOverlayProps = {};

export default class LoaderOverlay extends React.Component<ILoaderOverlayProps, {}> {
  private loaderImageSrc = String(require("./load.gif"));

  public render(): React.ReactElement<ILoaderOverlayProps> {
    return (
      <div className="modal">
        <section className="modalMain">
          <img src={this.loaderImageSrc} alt="" />
        </section>
      </div>
    );
  }
}

import * as React from "react";

import { action, computed, flow, observable, toJS } from "mobx";
import { IRoute } from "../models/IRoute";
import Routes, { RoutesObj } from "../contstants/Routes";

import * as H from "history";
import { generatePath, matchPath } from "react-router-dom";
import { RootStore } from "./RootStore";
import { UrlHelper } from "../Utils/UrlHelper";
import { IBreadcrumbItem, ICommandBarItemProps, IContextualMenuItem, INavLink, INavLinkGroup } from "office-ui-fabric-react";
import { IConfiguration } from "../models/IConfiguration";
import { TableReservationPage } from "../../modules/TableReservation/TableReservationPage";
import { ParkingReservationPage } from "../../modules/ParkingReservation/ParkingReservationPage";

interface ISaerch {
  field: string;
  value: string;
}
interface CustomHistory extends H.History<H.LocationState> { }

interface CustomTitle {
  Title: string;
  ParentId: string;
}

export class RouterStore {
  private RootStore: RootStore;
  public history: H.History<H.LocationState>;

  @observable locationPathname = "";
  @observable public routes: IRoute[] = Routes;
  @observable selectedRouteId: string;
  @observable routeFilter: string;
  @observable Configuration: IConfiguration = { Mode: "None", Auth: 'None' };

  @observable customTitle: CustomTitle = {
    Title: null,
    ParentId: null,
  };

  constructor(rootStore: RootStore) {
    this.updateBreadcrumbs();
    this.validateRoutes();

    this.RootStore = rootStore;
  }


  Init = flow(function* (this: RouterStore) {

    if (this.RootStore.Configuration == null) {
      yield this.RootStore.GetConfiguration();
    }

    this.Configuration = this.RootStore.Configuration;

    let filteredRoutes = [];

    for (let i = 0; i < this.routes.length; i++) {
      const route = this.routes[i];

      if (route.mode == "Both" || this.Configuration.Mode == route.mode) {
        filteredRoutes.push(route);

        if (route.id == "noMatch" || route.id == "Home") {
          route.component = this.Configuration.Mode == "Table" ? TableReservationPage : ParkingReservationPage
        }

        if (route.childrends) {
          let chields = [];
          for (let j = 0; j < route.childrends.length; j++) {
            const chield = route.childrends[j];
            if (chield.mode == "Both" || this.Configuration.Mode == chield.mode) {
              chields.push(chield);
            }
          }

          route.childrends = chields;
        }
      }
    }

    this.routes = filteredRoutes;

  });

  public Log(func: string, type: "INFO" | "WARNING" | "ERROR" = "INFO", message: string, ...objects: any[]) {
    console.log("RouterStore", func, type, "#750b1c", message, ...objects);
  }

  validateRoutes() {
    const routes = this.getFlatRoutes;

    let ids = {};
    let dups = [];

    routes.forEach((val) => {
      if (ids[val.id]) {
        dups.push(val);
      } else {
        ids[val.id] = true;
      }
    });

    if (dups && dups.length > 0) {
      alert(`Routing error! Duplicates: ${dups.length}`);
      console.error("Routing error! Duplicates:", dups);
    }
  }

  updateHistory(history: H.History<H.LocationState>) {
    this.history = history;
  }

  @action onChangeRouteFilter(filter: string) {
    this.routeFilter = filter;
  }

  @action onLocationUpdated(location: H.Location, oldLocation: H.Location = null) {
    const routes = this.getFlatRoutes;

    const selectedRoute = routes.find((route) => {
      const match = matchPath(location.pathname, {
        path: route.path,
        exact: route.exact,
      });

      return match ? true : false;
    });

    if (selectedRoute) {
      if (oldLocation && oldLocation.pathname != location.pathname) {
        const oldSelectedRoute = routes.find((route) => {
          const match = matchPath(oldLocation.pathname, {
            path: route.path,
            exact: route.exact,
          });

          return match ? true : false;
        });
        if (oldSelectedRoute && oldSelectedRoute.id == selectedRoute.id) {
          this.locationPathname = location.pathname;
        }
      }

      this.selectedRouteId = selectedRoute.id;
    }

    // set title
    this.setDocumentTitle();
  }

  @action setCustomTitle(customTitle: string, routeId: string = this.getSelectedRoute.id) {
    this.customTitle.Title = customTitle;
    this.customTitle.ParentId = routeId;

    // set title
    this.setDocumentTitle();
  }

  @action updateBreadcrumbs() {
    const updateBreadcrumbIds = (routes: IRoute[], parent: string) => {
      routes.forEach((route) => {
        route.breadcrumbIds = parent ? `${parent}-${route.id}` : route.id;

        if (route.childrends) {
          const newParent = route.breadcrumbIds;
          updateBreadcrumbIds(route.childrends, newParent);
        }
      });
    };

    updateBreadcrumbIds(this.routes, null);

    this.Log("updateBreadcrumbs", "INFO", "routes", toJS(this.routes));
  }

  @action addOrReplaceQueryStringParam(field: string, value: string) {
    const oldSearch = this.history.location.search;
    const newSearch = UrlHelper.addOrReplaceQueryStringParam(oldSearch, field, encodeURI(value));

    this.history.push({
      pathname: this.history.location.pathname,
      search: newSearch,
    });
  }

  @action removeQueryStringParam(field: string) {
    if (this.getQueryStringParam(field) !== null) {
      const oldSearch = this.history.location.search;
      const newSearch = UrlHelper.removeQueryStringParam(field, oldSearch);

      this.history.push({
        pathname: this.history.location.pathname,
        search: newSearch,
      });
    }
  }

  getQueryStringParam(field: string) {
    let retVal = null;
    if (this.history) {
      retVal = UrlHelper.getQueryStringParam(field, this.history.location.search);
    } else {
      retVal = UrlHelper.getQueryStringParam(field, window.location.href);
    }

    if (retVal) {
      return decodeURI(retVal);
    }

    return null;
  }

  @action goToUrl(url: string, params: any = null, search: ISaerch[] = null, newTab: boolean = false) {
    this.Log("goToUrl", "INFO", "", url, params, search, newTab);

    let newSearch = "";

    if (params) {
      url = generatePath(url, params);
    }

    if (search) {
      search.forEach((s) => {
        newSearch = UrlHelper.addOrReplaceQueryStringParam(newSearch, s.field, s.value);
      });
    }

    if (newTab) {
      //const newTabUrl = `${window.location.origin}${window.location.pathname}?env=WebViewList#${url}${newSearch ? `?${newSearch}` : ''}`;
      const newTabUrl = `${window.location.origin}${window.location.pathname}#${url}${newSearch ? `?${newSearch}` : ""}`;
      var win = window.open(newTabUrl, "_blank");
      win.focus();
    } else {
      this.history.push({
        pathname: url,
        search: newSearch,
      });
    }
  }

  @action gotToRoute(id: string, params: any = null, search: ISaerch[] = null, newTab: boolean = false) {
    console.groupEnd();
    console.group(`${id} - click to expand`);

    this.Log("gotToRoute", "INFO", "", id, params, search, newTab);

    const items = this.getFlatRoutes;
    const route = items.find((r) => r.id === id);

    let url = route.path;
    let newSearch = "";

    if (params) {
      url = generatePath(url, params);
    }

    if (search) {
      search.forEach((s) => {
        newSearch = UrlHelper.addOrReplaceQueryStringParam(newSearch, s.field, s.value);
      });
    }

    /*     if (this.RootStore.hasError) {
      this.RootStore.setError(null);
    }
 */
    if (this.customTitle.ParentId)
      this.customTitle = this.getFlatOfSingleRoute(this.getRouteWithChildrenById(this.customTitle.ParentId)).some((route) => route.id === id)
        ? this.customTitle
        : { Title: null, ParentId: null };

    if (newTab) {
      //	const newTabUrl = `${window.location.origin}${window.location.pathname}?env=WebViewList#${url}${newSearch ? `?${newSearch}` : ''}`;
      const newTabUrl = `${window.location.origin}${window.location.pathname}#${url}${newSearch ? `?${newSearch}` : ""}`;
      var win = window.open(newTabUrl, "_blank");
      win.focus();
    } else {
      if (this.history) {
        this.history.push({
          pathname: url,
          search: newSearch,
        });
      } else {
        this.history = [] as any;
        this.history.push({
          pathname: url,
          search: newSearch,
        });
      }

      // // set title
      // this.setDocumentTitle();

      // go to top
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  setDocumentTitle() {
    const route = this.getSelectedRoute;

    if (route) {
      document.title = `Foglaló - ${route.title}${this.customTitle?.Title ? ` (${this.customTitle.Title})` : ""}`;
    }
  }

  @computed get getBreadcrumbItems() {
    const firstItem = this.routes[0];
    const firstItemBreadcrumb = {
      text: firstItem.title,
      key: firstItem.path,
      isCurrentItem: this.selectedRouteId === firstItem.id,
      onClick: () => this.gotToRoute(firstItem.id),
    };

    if (this.selectedRouteId === firstItem.id) return [firstItemBreadcrumb] as IBreadcrumbItem[];

    const activeRoute = this.getSelectedRoute;

    if (activeRoute) {
      let params = null;

      const activeMatch = matchPath(location.hash.substring(1), {
        path: activeRoute.path,
        exact: activeRoute.exact,
      });

      if (activeMatch) {
        params = activeMatch.params;
      }

      const routes = this.getFlatRoutes;

      let moreBreadcrumbs: IBreadcrumbItem[] = [];
      const splittedBreadcrumbs = activeRoute.breadcrumbIds.split("-");

      splittedBreadcrumbs.forEach((splittedBreadcrumb) => {
        const route = routes.find((r) => r.id === splittedBreadcrumb);

        const isCurrentItem = this.selectedRouteId === route.id;
        let title = route.title;

        if (this.customTitle.ParentId && this.customTitle.ParentId === route.id && this.customTitle.Title) {
          title = `${route.title} (${this.customTitle.Title})`;
        }

        moreBreadcrumbs.push({
          text: title,
          key: route.path,
          isCurrentItem: isCurrentItem,
          onClick: () => {
            this.gotToRoute(route.id, params);
          },
        });
      });

      return [firstItemBreadcrumb, ...moreBreadcrumbs] as IBreadcrumbItem[];
    }

    return [firstItemBreadcrumb] as IBreadcrumbItem[];
  }

  @computed get getSelectedRoute() {
    const routes = this.getFlatRoutes;
    return routes.find((r) => r.id === this.selectedRouteId);
  }

  getRouteWithChildrenById(id: string, routes: IRoute[] = this.routes): IRoute {
    return routes.reduce((routes, route) => {
      if (routes) return routes;
      if (route.id === id) return route;
      if (route.childrends) return this.getRouteWithChildrenById(id, route.childrends);
    }, null);
  }

  /* 
  public isRouteUnderDevelopment(route: IRoute) {
    if (this.RootStore.CurrentUserStore.User?.IsSiteAdmin) return false;
    if (route.version && this.RootStore.routerVersion && route.version > this.RootStore.routerVersion) return true;
    return false;
  } 
  */

  @computed get getNavLinks() {
    const isChildrenActiveCalc = (route: IRoute) => {
      let isActive = this.selectedRouteId === route.id && !route.hiddenFromMenu && this.RootStore.GetPermissions.View[route.id] !== false;

      if (route.childrends) {
        isActive = route.childrends.some((chilren) => isChildrenActiveCalc(chilren)) || isActive;
      }

      return isActive;
    };

    const getLinks = (routes: IRoute[]) => {
      return routes
        .filter((route) => {
          if (route.hiddenFromMenu || this.RootStore.GetPermissions.View[route.id] === false) return false;
          //if (this.isRouteUnderDevelopment(route)) return false;
          return true;
        })
        .map((route) => {
          const isActive = this.selectedRouteId === route.id;
          const isChildrenActive = isChildrenActiveCalc(route);

          let item: IContextualMenuItem = {
            name: route.title,
            key: route.path,
            style: { fontSize: "16px" },
            //isExpanded: !isActive ? isChildrenActive : false,
            // isActive: isActive,
            id: route.id,
          };

          if (route.childrends && route.childrends.length > 0) {
            item.subMenuProps = {
              items: getLinks(route.childrends),
            };
          } else {
            item.onClick = () => this.gotToRoute(route.id);
          }

          return item;
        });
    };

    console.log("getLinks(this.routes)", getLinks(this.routes));

    return getLinks(this.routes) as IContextualMenuItem[];
  }

  @computed get getFlatRoutes(): IRoute[] {
    let flatRoutes: IRoute[] = [];

    const addRouteToArray = (route: IRoute) => {
      const { childrends, ...newRoute } = route;
      if (this.RootStore && !this.RootStore.IsAdmin && route.id === "adminLists") return;
      // if (
      // 	this.MainStore &&
      // 	!(
      // 		this.MainStore.CurrentUserStore.IsUserInGroup(Groups.Ugyvezeto) ||
      // 		this.MainStore.CurrentUserStore.IsUserInGroup(Groups.MegrendelokKezeloi) ||
      // 		this.MainStore.CurrentUserStore.IsAdmin
      // 	) &&
      // 	route.id === RoutesObj.orderList.id
      // ) {
      // 	return;
      // }

      flatRoutes.push(newRoute as IRoute);

      if (childrends) {
        childrends.forEach((r) => addRouteToArray(r));
      }

    };

    this.routes.forEach((r) => addRouteToArray(r));
    return flatRoutes;
  }

  getFlatOfSingleRoute(parentRoute: IRoute): IRoute[] {
    let flatRoutes: IRoute[] = [];

    const addRouteToArray = (route: IRoute) => {
      if (route) {
      } else {
        this.Log("getFlatOfSingleRoute", "INFO", "nullRoute");
      }
      const { childrends, ...newRoute } = route;

      flatRoutes.push(newRoute as IRoute);

      if (childrends) {
        childrends.forEach((r) => addRouteToArray(r));
      }
    };

    addRouteToArray(parentRoute);
    return flatRoutes;
  }
}

import { IDepartment } from "../../../../core/models/IDepartment";
import { IDepartmentUser } from "../../../../core/models/IDepartmentUser";
import { IResourceType } from "../../../../core/models/IResourceType";
import { ISaveResponse } from "../../../../core/models/ISaveResponse";
import { ITable } from "../../../../core/models/ITable";
import { ITableReservation } from "../models/ITableReservation";
import { ITablesAndUserData } from "../models/ITablesAndUserData";

export class TableReservationService {
  public async GetDepartments(): Promise<IDepartment[]> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    const request = await fetch(`${process.env.SERVER_URL}/api/department/GetDepartments`, options);
    return request.json();
  }

  public async SaveReservations(reservation: ITableReservation): Promise<ISaveResponse> {
    const options: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(reservation),
    };

    const response = await fetch(`${process.env.SERVER_URL}/api/tables/SaveTableReservation`, options);
    return await response.json();
  }

  public async SaveTable(table: ITable): Promise<ISaveResponse> {
    const options: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(table),
    };

    const response = await fetch(`${process.env.SERVER_URL}/api/tables/SaveTable`, options);
    return await response.json();
  }

  public async GetUsersForSelect(departmentId: number): Promise<IDepartmentUser[]> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    const request = await fetch(`${process.env.SERVER_URL}/api/department/GetUsersForDropDown?departmentId=${departmentId}`, options);
    return request.json();
  }

  public async GetTablesAndReservationsByDate(departmentId: number, startDate: Date, endDate: Date): Promise<IDepartment> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    const request = await fetch(
      `${
        process.env.SERVER_URL
      }/api/department/GetTablesAndReservationsByDate?departmentId=${departmentId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
      options
    );
    return request.json();
  }

  public async GetReservationsAndMinMaxDaysOfUsers(departmentId: number, startDate: Date, endDate: Date, guid: string): Promise<ITablesAndUserData> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    const request = await fetch(
      `${
        process.env.SERVER_URL
      }/api/department/GetReservationsAndMinMaxDaysOfUsers?departmentId=${departmentId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&userId=${guid}`,
      options
    );
    return request.json();
  }

  public async GetDepartmentById(
    id: number,
    startDate: Date,
    endDate: Date,
    isTablesNecessary: boolean,
    isUsersNecessary: boolean,
    isReservationsNecessary: boolean
  ): Promise<IDepartment> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    const request = await fetch(
      `${
        process.env.SERVER_URL
      }/api/department/GetDepartmentById?id=${id}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&isTablesNecessary=${isTablesNecessary}&isUsersNecessary=${isUsersNecessary}&isReservationsNecessary=${isReservationsNecessary}`,
      options
    );
    return request.json();
  }
}

import { observable } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import React = require("react");
import { RouteComponentProps } from "react-router-dom";
import { RootStore } from "../../../core/stores/RootStore";
import { UserInRoles } from "./components/UserInRoles";
import { UserInRolesStore } from "./core/stores/UserInRolesStore";

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  RootStore?: RootStore;
}

interface Stores {
  UserInRolesStore: UserInRolesStore;
}

@inject("RootStore")
@observer
export class UserInRolesPage extends React.Component<IProps> {
  private stores: Stores;

  constructor(props: IProps) {
    super(props);
    console.log("this.props.match.params.id", this.props.match.params);

    this.stores = {
      UserInRolesStore: new UserInRolesStore(props.RootStore),
    };
  }

  async componentDidMount() {
    this.stores.UserInRolesStore.Init();
  }

  public render() {
    return (
      <Provider {...this.stores}>
        <UserInRoles />
      </Provider>
    );
  }
}

import { faWindowMinimize } from "@fortawesome/free-solid-svg-icons";
import { IResourceType } from "../../../../../core/models/IResourceType";

export class ResourceService {
  public async GetResourceTypes(): Promise<IResourceType> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    const request = await fetch(`${process.env.SERVER_URL}/api/resource/GetResourceTypes`, options);
    return request.json();
  }

  public async GetResources(): Promise<IResourceType> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    const request = await fetch(`${process.env.SERVER_URL}/api/resource/GetResources`, options);
    return request.json();
  }

  public async GenerateReport() {
    try {
      const options: RequestInit = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      };

      window.location.assign(`${process.env.SERVER_URL}/api/resource/GenerateReport`);
    } catch (err) {
      return "";
    }
  }

  public async GenerateEventLogReport() {
    try {
      const options: RequestInit = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      };

      window.location.assign(`${process.env.SERVER_URL}/api/resource/GenerateEventLogReport`);
    } catch (err) {
      return "";
    }
  }

  public async GenerateResourceLogReport() {
    try {
      const options: RequestInit = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      };

      window.location.assign(`${process.env.SERVER_URL}/api/resource/GenerateResourceLogReport`);
    } catch (err) {
      return "";
    }
  }
  
  public async RemoveResource(resource): Promise<any> {
    const options: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(resource),
    };

    let returnValue = await fetch(`${process.env.SERVER_URL}/api/resource/RemoveResource`, options);
    return returnValue;
  }
  public async SaveResource(resource): Promise<any> {
    const options: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(resource),
    };

    let returnValue = await fetch(`${process.env.SERVER_URL}/api/resource/SaveResources`, options);
    return returnValue.json();
  }
}

import * as React from "react";
import { render } from "react-dom";
import { Provider } from "mobx-react";

import App from "./components/App/App";
import { AppStore } from "./core/AppStore";

import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import { RootStore } from "./core/stores/RootStore";
import { Layout } from "./components/Layout/Layout";
// import { IconName } from "react-icons/fa";

const rootEl = document.getElementById("root");

const stores = {
  RootStore: new RootStore(),
  /*   appStore: new AppStore(),
  userInRolesStore: new UserInRolesStore(),
  resourceStore: resourceStore,
  departmentStore: new DepartmentStore(), */
};

const element = (
  <Provider {...stores}>
    <Layout />
  </Provider>
);

initializeIcons();

render(element, rootEl);

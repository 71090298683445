import { IDatePickerStrings } from "office-ui-fabric-react";

export default class Utils {
  public static DayPickerStrings: IDatePickerStrings = {
    months: ["Január", "Február", "Március", "Április", "Május", "Június", "Július", "Augusztus", "Szeptember", "Október", "November", "December"],

    shortMonths: ["Jan", "Feb", "Már", "Ápr", "Máj", "Jún", "Júl", "Aug", "Szep", "Okt", "Nov", "Dec"],

    days: ["Vasárnap", "Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat"],

    shortDays: ["V", "H", "K", "Sz", "Cs", "P", "Szo"],
    goToToday: "Mai nap",
    prevMonthAriaLabel: "Előző hónap",
    nextMonthAriaLabel: "Következő hónap",
    prevYearAriaLabel: "Előző év",
    nextYearAriaLabel: "Következő év",

    isRequiredErrorMessage: "A dátum kötelező",
    invalidInputErrorMessage: "Hibás dátum",
  };
}

import { observable, action, flow, toJS, computed } from "mobx";
import "isomorphic-fetch";
import "url-search-params-polyfill";
import "url-polyfill";
import { IDepartment } from "../../../../core/models/IDepartment";
import { TableReservationService } from "../services/TableReservationService";
import { RootStore } from "../../../../core/stores/RootStore";
import { IOwnerUser, ITable } from "../../../../core/models/ITable";
import * as d3 from "d3";
import { IDepartmentUser } from "../../../../core/models/IDepartmentUser";
import { ISaveResponse } from "../../../../core/models/ISaveResponse";
import { ReservationTypes } from "../../../ParkingReservation/core/enums/ReservationTypes";
import { ITablesAndUserData } from "../models/ITablesAndUserData";

export class TableReservationStore {
  public TableReservationService: TableReservationService = null;
  public svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;
  public g: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  public table: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;

  @observable isLoading: boolean = false;
  @observable Department: IDepartment = {
    Id: null,
    Name: null,
    Image: null,
    Tables: [],
    Leaders: [],
    Users: [],
    TempImage: null,
    ReservableByUsers: null,
    ImageHeight: null,
    ImageWidth: null,
    IsCurrentUserDepartmentLeader: null,
    IsCurrentUserDepartmentUser: null,
    Selected: null,
  };

  @observable TablesWithUser: ITablesAndUserData = null;
  @observable UsersForSelect: IDepartmentUser[] = [];
  @observable SelectedUser: IDepartmentUser;

  @observable Departments: IDepartment[] = [];
  @observable SelectedDepartmentId: number = null;

  //Messages
  @observable openDepartmentDialog: boolean = false;
  RootStore: RootStore = null;

  @observable isAdminMode: boolean = false;
  @observable width = 800;
  @observable height = 600;
  @observable selectedTable: ITable;
  @observable showMyReservations: boolean = false;

  @observable Today: Date = null;
  @observable errorMessage: string = null;
  @observable succesMessage: string = null;
  @observable ppickerKey = 0;

  holidays = [
    new Date(2021, 0, 1),
    new Date(2021, 2, 15),
    new Date(2021, 4, 1),
    new Date(2021, 7, 20),
    new Date(2021, 9, 23),
    new Date(2021, 10, 1),
    new Date(2021, 11, 25),
    new Date(2021, 11, 26),
  ];

  constructor(rootStore: RootStore, isAdminMode: boolean = false) {
    this.TableReservationService = new TableReservationService();
    this.RootStore = rootStore;
    this.isAdminMode = isAdminMode;
  }

  Init = flow(function* (this: TableReservationStore) {
    this.Today = new Date();
    yield this.GetDepartments();
    yield this.GetUsersForSelect(this.Department.Id);
  });

  is_holiday(datum: Date) {
    let datum2 = new Date(2021, datum.getMonth(), datum.getDate());
    let isHol = false;
    this.holidays.forEach((holiday) => {
      if (this.RootStore.SameDay(new Date(holiday), new Date(datum2))) {
        isHol = true;
      }
    });

    return isHol;
  }

  public GetDepartments = flow(function* (this: TableReservationStore) {
    this.isLoading = true;

    const request = yield this.TableReservationService.GetDepartments();

    if (request) {
      this.Departments = request;
      console.log("GetDepartments", toJS(this.Departments));

      if (this.Departments.length > 0) {
        let selectedOnes = this.Departments.filter((d) => d.Selected);
        if (selectedOnes.length > 0) {
          yield this.GetDepartmentById(selectedOnes[0].Id);
        } else {
          yield this.GetDepartmentById(this.Departments[0].Id);
        }
      }
    }

    this.isLoading = false;
  });

  public SaveTable = flow(function* (this: TableReservationStore) {
    this.isLoading = true;

    if (this.ValidateTable().length > 0) {
      this.errorMessage = this.ValidateTable()[0];
    } else {
      const request: ISaveResponse = yield this.TableReservationService.SaveTable(this.selectedTable);
      if (request) {
        if (request.ErrorCode) {
          this.errorMessage = request.ErrorMessage;
        } else {
          this.onSelectedTable(null);
          yield this.LoadTablesAndReservationsByDate();
          this.initMap();
        }
      }
    }

    this.isLoading = false;
  });

  ValidateTable() {
    let errors = [];
    if (this.selectedTable.ReservedByDefault && !this.selectedTable.OwnerADUserId) {
      errors.push(`Köteleő megadni tulajdonost!`);
    }

    return errors;
  }

  public SaveReservation = flow(function* (this: TableReservationStore, reservationType: ReservationTypes) {
    this.isLoading = true;

    let oldReservationType = this.selectedTable.TableReservation.ReservationTypeId;

    this.selectedTable.TableReservation = {
      ...this.selectedTable.TableReservation,
      ReservationTypeId: reservationType,
      ADUserId: this.SelectedUser ? this.SelectedUser.ADUserId : this.selectedTable.TableReservation.ADUserId,
      IsLeaderSave: this.SelectedUser ? true : false,
      DepartmentId: this.Department.Id,
    };

    console.log("SAVE____", toJS(this.selectedTable.TableReservation));

    const request: ISaveResponse = yield this.TableReservationService.SaveReservations(this.selectedTable.TableReservation);

    if (request) {
      if (request.ErrorCode) {
        this.errorMessage = request.ErrorMessage;
        this.selectedTable.TableReservation.ReservationTypeId = oldReservationType;
        yield this.afterSave();
      } else {
        // this.succesMessage = "Sikeres mentés";
        this.onSelectedTable(null);
        this.errorMessage = null;
        yield this.afterSave();
      }
    }

    this.isLoading = false;
  });

  public afterSave = flow(function* (this: TableReservationStore) {
    yield this.LoadTablesAndReservationsByDate();
    yield this.LoadUserReservations();
    this.initMap();
  });

  //mai dátum, plusz 14 nap, enddate-et át kell adni, hol 14-et, hol 7-et
  public GetDepartmentById = flow(function* (this: TableReservationStore, departmentId: number) {
    this.isLoading = true;

    const startDate = this.Today;
    const endDate = new Date();
    let newDate = endDate.getDate() + 7;
    console.log("newDate", newDate);

    const request: IDepartment = yield this.TableReservationService.GetDepartmentById(departmentId, startDate, endDate, true, true, true);
    if (request) {
      this.Department = request;
      this.width = this.Department.ImageWidth;
      this.height = this.Department.ImageHeight;
      console.log("GetDepartmentById", toJS(this.Department));
      yield this.GetUsersForSelect(this.Department.Id);
      yield this.LoadUserReservations();
      this.initMap();
      this.Today = new Date();
      this.SelectedUser = null;
    }

    this.isLoading = false;
  });

  public LoadTablesAndReservationsByDate = flow(function* (this: TableReservationStore) {
    this.isLoading = true;
    let newDate = new Date();
    newDate.setDate(this.Today.getDate() + 14);
    const request: ITable[] = yield this.TableReservationService.GetTablesAndReservationsByDate(this.Department.Id, this.Today, newDate);

    if (request) {
      this.Department.Tables = request;
    }

    this.isLoading = false;
  });

  public getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  public LoadUserReservations = flow(function* (this: TableReservationStore, departmentId?: number) {
    this.isLoading = true;
    let newDate = new Date();
    let mondayOfWeek = this.getMonday(this.Today);
    newDate.setDate(mondayOfWeek.getDate() + 6);

    const guid = this.SelectedUser ? this.SelectedUser.ADUserId : this.RootStore.CurrentUser.Id;
    const request: ITablesAndUserData = yield this.TableReservationService.GetReservationsAndMinMaxDaysOfUsers(
      departmentId ? departmentId : this.Department.Id,
      mondayOfWeek,
      newDate,
      guid
    );

    if (request) {
      this.TablesWithUser = request;
    }

    this.isLoading = false;
  });

  public GetUsersForSelect = flow(function* (this: TableReservationStore, departmentId: number) {
    this.isLoading = true;

    const request = yield this.TableReservationService.GetUsersForSelect(departmentId);

    if (request) {
      this.UsersForSelect = request;
      console.log("GetUsersForSelect", toJS(this.UsersForSelect));
    }

    this.isLoading = false;
  });

  public async GetLeadersByQueryText(text) {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    const request = await fetch(
      `${process.env.SERVER_URL}/api/user/GetLeadersByQueryText?departmentId=${this.Department.Id}&queryText=${encodeURI(text)}`,
      options
    );
    const result = await request.json();

    return result.map((user) => {
      return {
        ...user,
        primaryText: user.Name,
        secondaryText: user.LoginName,
      };
    });
  }

  @computed get GetDepartmentsDropDownOptions() {
    return this.Departments.map((u) => {
      return { key: u.Id, text: u.Name };
    });
  }

  @computed get GetUserSelectDropDownOptions() {
    if (this.UsersForSelect.length === 0) return { key: null, text: null, ADUserId: null };
    return this.UsersForSelect.map((u) => {
      return { key: u.Id, text: u.ADUserName, ADUserId: u.ADUserId };
    });
  }

  @computed get IsTableReservableAndIsCurrentUserLeader() {
    return this.Department && this.Department.ReservableByUsers && this.Department.IsCurrentUserDepartmentLeader;
  }

  @computed get getMyReservations() {
    const guid = this.SelectedUser ? this.SelectedUser.ADUserId : this.RootStore.CurrentUser.Id;
    return this.Department.Tables.filter((chair) => {
      return chair.TableReservations.filter((reservation) => reservation.ADUserId === guid);
    }).map((chair) => {
      return {
        ...chair,
        TableReservations: chair.TableReservations.filter(
          (reservation) => reservation.ADUserId === guid && reservation.ReservationTypeId === ReservationTypes.Foglalva
        ),
      };
    });
  }

  @action DeleteSelectedUser() {
    this.SelectedUser = null;
    this.initMap();
  }

  @computed get getMyReservationsOfOneWeek() {
    let cic = this.TablesWithUser.Tables.filter((chair) => {
      return chair.TableReservations.filter((reservation) => reservation.ADUserId === this.RootStore.CurrentUser.Id);
    }).map((chair) => {
      return {
        ...chair,
        TableReservations: chair.TableReservations.filter(
          (reservation) => reservation.ADUserId === this.RootStore.CurrentUser.Id && reservation.ReservationTypeId === ReservationTypes.Foglalva
        ),
      };
    });
    let sum = 0;
    for (let index = 0; index < cic.length; index++) {
      if (cic[index].TableReservations.length > 0) {
        console.log("cic[index]", toJS(cic[index]));

        sum = sum + cic[index].TableReservations.length;
      }
    }

    console.log("CIC", toJS(cic));

    return sum;
  }

  @computed get getMyReservationsOnSelectedDay() {
    let guid = this.SelectedUser ? this.SelectedUser.ADUserId : this.RootStore.CurrentUser.Id;
    return this.Department.Tables.filter((chair) => {
      return chair.TableReservations.filter((reservation) => reservation.ADUserId === guid);
    }).map((chair) => {
      return {
        ...chair,
        TableReservations: chair.TableReservations.filter(
          (reservation) => reservation.ADUserId === guid && this.RootStore.SameDay(new Date(reservation.ReservationDate), new Date(this.Today))
        ),
      };
    });
  }

  @action ShowMyReservations() {
    this.showMyReservations = !this.showMyReservations;
  }

  @action LoadDataForPeoplePicker(data: any[]) {
    if (data) {
      const newData = data.map((user) => {
        return {
          ...user,
          text: user.Name,
        };
      });

      return newData;
    }
  }

  @action OnChangePeoplePicker(items) {
    this.selectedTable.Owner = items;
    if (items.length > 0) {
      this.selectedTable.OwnerADUserId = items[0].ADUserId;
      this.selectedTable.OwnerADUserName = items[0].Name;
    } else {
      this.selectedTable.OwnerADUserId = null;
      this.selectedTable.Owner = [];
      this.selectedTable.OwnerADUserName = null;
    }
    this.ppickerKey++;
  }

  public OnChangeUserSelect = flow(function* (this: TableReservationStore, userID: number) {
    let user = this.UsersForSelect.find((user) => user.Id == userID);
    if (user) {
      this.SelectedUser = user;
      yield this.LoadUserReservations();
      this.initMap();
    }
    console.log("this.SelectedUser ", toJS(this.SelectedUser));
  });

  public GetNextDay = flow(function* (this: TableReservationStore) {
    const newDate = new Date();

    newDate.setTime(this.Today.getTime());
    newDate.setDate(newDate.getDate() + 1);

    this.Today = newDate;

    yield this.LoadTablesAndReservationsByDate();
    yield this.LoadUserReservations();

    this.initMap();
  });

  public GetPrevDay = flow(function* (this: TableReservationStore) {
    const newDate = new Date();

    newDate.setTime(this.Today.getTime());
    newDate.setDate(newDate.getDate() - 1);

    this.Today = newDate;

    yield this.LoadTablesAndReservationsByDate();
    yield this.LoadUserReservations();
    this.initMap();
  });

  @computed get GetFormatedDate() {
    return this.getFormattedDate(this.Today);
  }

  getFormattedDate(d: Date): string {
    if (!d) return null;

    let x = d.toLocaleDateString("hu-HU", { day: "numeric" }).replace(/ /g, "");
    const dateString = d.toLocaleDateString("hu-HU", { year: "numeric", month: "long" }).replace(/ /g, "");
    const datetimeString = `${dateString.replace(".", ". ")} ${x}.`;

    return datetimeString;
  }

  @computed get getUser() {
    if (this.RootStore.CurrentUser) {
      return this.RootStore.CurrentUser.Name;
    } else {
      return "";
    }
  }

  getRoles() {
    let roles = [];
    let userRoles = "";

    if (this.RootStore.CurrentUser.IsAdmin) {
      roles.push("Admin");
    }
    if (this.RootStore.CurrentUser.IsSuperAdmin) {
      roles.push("SzuperAdmin");
    }
    if (this.Department.IsCurrentUserDepartmentLeader) {
      roles.push("Vezető");
    }
    if (this.Department.IsCurrentUserDepartmentUser) {
      roles.push("Foglalásra jogosult");
    }

    userRoles = "";
    roles.map((u, i) => {
      userRoles += `${u}${roles.length > 1 && i < roles.length - 1 ? ", " : ""}`;
    });

    return userRoles;
  }

  // TÉRKÉP
  initMap() {
    d3.select("#asztalfoglalo").html("");
    this.svg = d3.select("#asztalfoglalo").append("svg").attr("width", this.width).attr("height", this.height);

    this.g = this.svg.append("g");

    this.table = this.g
      .append("svg:image")
      .attr("xlink:href", `data:image/png;base64,${this.Department.Image}`)
      .attr("width", this.width)
      .attr("height", this.height)
      .attr("x", 0)
      .attr("y", 0);

    this.initTables();
  }

  calculateChairPos(size: number, chair: ITable) {
    return {
      X: chair.X - size / 2,
      Y: chair.Y - size / 2,
    };
  }

  @action onSelectedTable(table: ITable) {
    this.selectedTable = table;
    if (table) {
      if (this.selectedTable.OwnerADUserId) {
        this.selectedTable.Owner = [];
        let user: IOwnerUser = {
          Id: this.selectedTable.OwnerADUserId,
          Name: this.selectedTable.OwnerADUserName,
        };
        this.selectedTable.Owner = [...this.selectedTable.Owner, user];
      }
      console.log("onSelectedTable", toJS(table));
    } else {
      this.errorMessage = null;
      this.succesMessage = null;
    }
  }

  @action OnChnageTableField(key: string, newValue: any) {
    this.selectedTable = {
      ...this.selectedTable,
      [key]: newValue,
    };
    if (key === "ReservedByDefault" && newValue === false) {
      this.selectedTable.Owner = [];
    }
  }

  @action OnChnageReservationField(key: string, newValue: any) {
    this.selectedTable.TableReservation = {
      ...this.selectedTable.TableReservation,
      [key]: newValue,
    };
  }

  getChairColor(table: ITable) {
    const guid = this.SelectedUser ? this.SelectedUser.ADUserId : this.RootStore.CurrentUser.Id;
    if (table.TableReservation.ReservationTypeId === ReservationTypes.Felszabaditva) {
      return "lightgreen";
    } else if (table.TableReservation.ReservationTypeId === ReservationTypes.Senkie) {
      return "green";
    } else if (table.TableReservation.ReservationTypeId === ReservationTypes.Tulajdonose) {
      return "blue";
    } else if (table.TableReservation.ReservationTypeId === ReservationTypes.Foglalva && table.TableReservation.ADUserId === guid) {
      return "orange";
    } else {
      return "red";
    }
  }

  getOwnerName(table: ITable) {
    if (
      table.TableReservation.ReservationTypeId === ReservationTypes.Senkie ||
      table.TableReservation.ReservationTypeId === ReservationTypes.Felszabaditva
    ) {
      return "szabad";
    } else if (table.TableReservation.ReservationTypeId === ReservationTypes.Foglalva) {
      return table.TableReservation.ADUserName;
    } else {
      return table.OwnerADUserName;
    }
  }

  initTables() {
    const self = this;

    this.Department.Tables.forEach((table) => {
      const innerSize = 40;
      const outerSize = 46;
      const borderWidth = 4;

      const innerPos = this.calculateChairPos(innerSize, table);
      const outerPos = this.calculateChairPos(outerSize, table);
      const OwnerName = this.getOwnerName(table);

      const strokeColor = self.getChairColor(table);
      this.g
        .append("svg")
        .attr("width", outerSize + borderWidth * 2)
        .attr("height", outerSize + borderWidth * 2)
        .attr("x", outerPos.X - borderWidth)
        .attr("y", outerPos.Y - borderWidth)
        .append("circle")
        .attr("cx", (outerSize + borderWidth * 2) / 2)
        .attr("cy", (outerSize + borderWidth * 2) / 2)
        .attr("r", outerSize / 2)
        .style("stroke", strokeColor)
        .style("stroke-width", borderWidth)
        .style("fill", "none")
        .attr("cursor", "pointer");

      this.g
        .append("svg")
        .attr("width", innerSize)
        .attr("height", innerSize)
        .attr("x", innerPos.X)
        .attr("y", innerPos.Y)
        .append("circle")
        .attr("cx", innerSize / 2)
        .attr("cy", innerSize / 2)
        .attr("r", innerSize / 2)
        .style("fill", "rgba(1,1,1,0.3)")
        .attr("cursor", "pointer")
        .on("mouseover", function () {
          d3.select(this).style("fill", "gray");
        })
        .on("mouseout", function () {
          d3.select(this).style("fill", "rgba(1,1,1,0.3)");
        })
        .on("click", function () {
          self.onSelectedTable(table);
        })
        .append("svg:title")
        .text(function (d, i) {
          return "Itt ül: " + OwnerName;
        });
    });
  }
}
